import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PageTemplate from './PageTemplate';
import EmployeeDropdown from './Components/EmployeeDropdown';

import AssessmentScore from './Components/AssessmentScore';
import AssessmentScoreRTL from './Components/AssessmentScoreRTL';

import AssessmentDetails from './Components/AssessmentDetails';
import AssessmentDetailsRTL from './Components/AssessmentDetailsRTL';

import AssessmentTop5 from './Components/AssessmentTop5';
import AssessmentTop5RTL from './Components/AssessmentTop5RTL';

import AssessmentAllInOne from './Components/AssessmentAllInOne';
import AssessmentCategories from './Components/AssessmentCategories';
import AssessmentCategoriesRTL from './Components/AssessmentCategoriesRTL';



class AssessmentReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      crrSelectedEmp: ""
    }
  }

  componentDidMount() {

  }

  exportPDFWithComponent = () => {
    let { handleDownloadStatus } = this.props;
    // handleDownloadStatus("Detail Report");
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };




  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { onAssessmentByEmpID } = this.props;
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      onAssessmentByEmpID(emp_id)

    })
  }

  render() {

    let { downloadRTLReport, surveyLanguageCode, surveyLanguageRTL, isUser, loading, EditDetailsFn, EditButtonFn, createdAt, getAssessmenrEmployeeDetailsData, closeReportModal, getAssessmentReportByEmpIDData, getAnsweredEmployeesData } = this.props;


    // console.log('isUserisUserisUserisUserisUserisUserisUserisUser', isUser); 

    return (


      <div className={isUser ? "" : ""} >
        <div className="flex items-start justify-center mt-6 overflow-hidden">

          <div className={isUser ? "md:w-11/12 w-full mx-auto overflow-hidden overflow-y-auto bg-white rounded-md h-[calc(100vh-8rem)]  customscroll5" : "w-full mx-auto overflow-hidden overflow-y-auto bg-white rounded-md h-[calc(100vh-8rem)]  customscroll5  "}  >

            <div className="sticky top-0 z-20 flex flex-col items-center justify-between gap-4 p-4 px-6 bg-white border-b md:flex-row"  >
              <h1 className="font-semibold md:text-xl text-lg line-clamp-1 text-[#3D405B]">
                TalentPlusStrengths 22
              </h1>

              <div className='flex items-center space-x-4'>

                {/* <EmployeeDropdown
                    getAnsweredEmployeesData={getAnsweredEmployeesData}
                    crrSelectedEmp={this.state.crrSelectedEmp}
                    handleSelectEmployee={this.handleSelectEmployee}
                />
                 */}
                {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                  <>
                    {/* <div onClick={() => this.exportPDFWithComponent()} className="flex items-center p-2 px-4 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer " >
                        <span class="material-symbols-outlined mr-2 " >
                        file_download
                        </span> Download Report
                    </div> */}

                    <button onClick={() => {
                      surveyLanguageCode === 'ar' ?
                        downloadRTLReport(surveyLanguageCode) :
                        this.exportPDFWithComponent()
                    }} className="flex-1 w-full  uppercase text-sm font-medium rounded-md inline-flex items-center justify-center px-4 py-2 cursor-pointer bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden">
                      <span class="material-symbols-outlined mr-2 text-[1.5rem]">file_download</span>
                      <span className="transition-all duration-300 ease-out bg-white rounded-full opacity-10"></span>
                      {EditButtonFn('DownloadReport', "Download Report")}
                    </button>

                  </>
                  : null}


                {/* <div onClick={() => closeReportModal(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div> */}
              </div>

            </div>

            {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
              <div className=''>
                <PDFExport ref={this.pdfExportComponent}
                  pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} />}
                  scale={0.6}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                  margin={0}
                  fileName={'Assessment Report' + (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name ?
                    ' - ' + getAssessmenrEmployeeDetailsData.name : "")}
                  author="Happyplus Team"
                  landscape={false}
                >

                  {surveyLanguageRTL ?
                    <AssessmentScoreRTL
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                    :
                    <AssessmentScore
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                  }

                  {surveyLanguageRTL ?
                    <AssessmentTop5RTL
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                    : <AssessmentTop5
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />}


                  {surveyLanguageRTL ?
                    <AssessmentCategoriesRTL
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                    :
                    <AssessmentCategories
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />}


                  <AssessmentAllInOne
                    crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                    getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                    getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                    EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                    createdAt={createdAt}
                    surveyLanguageRTL={surveyLanguageRTL}
                  />

                  {surveyLanguageRTL ?
                    <AssessmentDetailsRTL
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                    :
                    <AssessmentDetails
                      crrSelectedEmp={getAssessmenrEmployeeDetailsData}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                  }

                </PDFExport>
              </div>
              :
              <>
                {loading ?
                  <div className='py-24 text-xl font-semibold text-center text-gray-500'>{"Please wait while report is generating..."}</div>
                  :
                  <div className='py-24 text-xl font-semibold text-center text-gray-500'>{"Report is not generated yet"}</div>
                }
              </>}
          </div>
        </div>
      </div>

    );
  }
}


function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AssessmentReport);