import React from 'react';
import ReactEcharts from "echarts-for-react";
import SpeedMeter from "../Chart/SpeedMeter";


export default function PersonalitySection(props) {

  const { crrSelectedEmp, createdAt, getXList, getDetails, listX } = props;

  let dataXlist = []
  if (listX && listX.length > 0) {
    listX.forEach((ele) => {
      let temp = {
        ...ele
      }
      dataXlist.push(temp);
    })
    dataXlist.sort((b, a) => getDetails(a, "score") - getDetails(b, "score"))

  }


  let chunkSize = 3;

  let ListMain = []
  for (let i = 0; i < dataXlist.length; i += chunkSize) {
    const chunk = dataXlist.slice(i, i + chunkSize);
    ListMain.push(chunk)
  }


  return (
    <>
      {ListMain && ListMain.length > 0 ?
        ListMain.map((ValueListInner, index) =>
          <>
          
            <main className="p-10 space-y-6 bg-white page-break">
              <div className="space-y-6 ">
                <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
                  <h1 className="text-lg font-bold">
                    Career Guidance Program
                  </h1>
                  <p className="text-lg font-bold">
                    {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
                  </p>
                </div>
                <div className="bg-[#2196F3]/10 p-6 font-medium">
                  <p className="text-3xl font-bold uppercase ">YOUR PERSONALITY PROFILE</p>
                </div>

                <div className="space-y-4">
                  {/* <div className="bg-[#2196F3] p-4 font-medium">
              <p className="text-2xl text-white uppercase">Learning Beliefs</p>
            </div>
            <p className="text-[#212121]">
              Focuses on teens' attitudes toward learning and education, impacting their openness to acquiring new skills and knowledge in their career journey.
            </p> */}

                  {ValueListInner && ValueListInner.length > 0 ?
                    ValueListInner.map((item) =>
                      <div className="flex items-center h-full py-2 space-x-6">
                        <div className="w-64 bg-[#f5f5f5] p-6 flex items-center justify-center flex-shrink-0 h-auto">

                          <div className="space-y-4 flex flex-col justify-center  ">
                            <p className="text-[#212121] text-lg font-bold text-center">{getDetails(item, "label")}</p>
                            <SpeedMeter score={getDetails(item, "score")} label={getDetails(item, "label")} />
                          </div>
                                  {/* <div className="space-y-2 ">
                              <p className="text-[#212121] text-4xl text-center">{getDetails(item, "score")}</p>
                              <p className="text-[#212121] text-lg font-bold">{getDetails(item, "label")}</p>
                            </div> */}
                        </div>
                        <div className="w-full space-y-10">
                          {item && item.dimension && item.dimension.length > 0 ?
                            getXList(item.dimension, "reorder").map((ele) =>
                             
                                <div className="flex items-center space-x-4 ">
                                  <div className="flex-shrink-0 w-36">
                                    <p>{getDetails(ele, "label")}</p>
                                  </div>
                                  <div className="w-full space-y-6">
                                    <div className="w-full h-2 rounded-full bg-[#f5f5f5] flex items-center">
                                      <div
                                        style={{ marginLeft: getDetails(ele, "scorePerc") + '%' }}
                                        className=
                                        {"w-12 h-12 bg-[#4CAF50] text-white rounded-full leading-0 flex-shrink-0 flex items-center justify-center shadow-lg shadow-black/30 "}><span>
                                          {getDetails(ele, "score")}</span></div>
                                    </div>
                                    <div className="w-full bg-[#f5f5f5] grid grid-cols-6 text-[#212121] divide-x-2 divide-white">
                                      <p className="p-2 text-center">1</p>
                                      <p className="p-2 text-center">2</p>
                                      <p className="p-2 text-center">3</p>
                                      <p className="p-2 text-center">4</p>
                                      <p className="p-2 text-center">5</p>
                                      <p className="p-2 text-center">6</p>
                                    </div>
                                  </div>
                                </div>
                            
                            ) : null}

                        </div>

                      </div>
                    ) : null}

                </div>

              </div>
            </main>
          </>
        ) : null}

    </>
  );
}

