import { userConstants } from '../_constants';
import CryptoJS from 'crypto-js';

let localData = localStorage.getItem('adminuser');
let cryptedText = localData?JSON.parse(localData):"";
let bytes = cryptedText?CryptoJS.AES.decrypt(cryptedText, "secretkey1345100237401"):"";

let user = "";

try {
  user = bytes?JSON.parse(bytes.toString(CryptoJS.enc.Utf8)):"";
} catch(error) {
  user = ""
}

// let user = JSON.parse(localStorage.getItem('adminuser'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}