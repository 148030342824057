import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assessmentActions } from "../../../../../_actions";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {

    }
  }

  componentDidMount() {


  }


  render() {

    let { } = this.props;
    let { loading, EditDetailsFn, EditButtonFn, createdAt, getAssessmenrEmployeeDetailsData, 
      getCareerFilamentScoreData, getPersonalityIndexData, 
      getWorkValueDimensionScoreData, getInterestDimensionScoreData
     } = this.props;


    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }


    let colorList = ["#BA68C8", "#7986CB", "#FFB74D"]
    let colorList2 = ["#64B5F6", "#BA68C8", "#7986CB", "#FFB74D", "#E57373"]




    function getDetails(item, field) {
      let value = "";
      if (field === "name") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
      } else if (field === "label") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
      } else if (field === "rank") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
      } else if (field === "score") {
        let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;
        value = score.toFixed(1);
      } else if (field === "scorePerc") {
        let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;
        value = (parseInt((parseFloat(score) * 85) / 6)).toString();
        console.log('score--///--value----------->', score, " - ", value)
      }
      return value
    }


    function getInterestDetails(item, field) {
      let value = "";
      if (field === "name") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
      } else if (field === "label") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
      } else if (field === "label2") {
        value = item && item["children"] && item["children"][0] && item["children"][0]["name"] ? item["children"][0]["name"] : "";
      } else if (field === "desc") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["desc"] ? item["parameter"][0]["desc"] : "";
      } else if (field === "rank") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
      } else if (field === "score") {
        let activeCount = item && item["dimension"] && item["dimension"][0] && item["dimension"][0]["activeCount"] && item["dimension"][0]["activeCount"][0] && item["dimension"][0]["activeCount"][0]["total"] ? item["dimension"][0]["activeCount"][0]["total"] : 0;
        let totalCount = item && item["dimension"] && item["dimension"][0] && item["dimension"][0]["totalCount"] && item["dimension"][0]["totalCount"][0] && item["dimension"][0]["totalCount"][0]["total"] ? item["dimension"][0]["totalCount"][0]["total"] : 0;

        // let score = (activeCount*100)/totalCount;
        let score = activeCount;

        value = parseFloat(score.toFixed(1));

      }
      return value
    }


    function getWorkValueDetails(item, field) {
      let value = "";
      if (field === "name") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
      } else if (field === "label") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
      } else if (field === "desc") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["desc"] ? item["parameter"][0]["desc"] : "";
      } else if (field === "rank") {
        value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
      } else if (field === "score") {
        let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;

        value = parseFloat(score.toFixed(1));

      }
      return value
    }





    function sortX(item, field) {
      let ele = item && item["children"] && item["children"][0] && item["children"][0]["children"] && item["children"][0]["children"][0] && item["children"][0]["children"][0][field] ? item["children"][0]["children"][0][field] : 0
      return ele
    }



    let dataXlist = []
    if (getPersonalityIndexData && getPersonalityIndexData.length > 0) {
      getPersonalityIndexData.forEach((ele) => {
        let temp = {
          ...ele
        }
        dataXlist.push(temp);
      })
      dataXlist.sort((b, a) => getDetails(a, "score") - getDetails(b, "score"))
    }


    let dataNew2 = []
    if (getInterestDimensionScoreData && getInterestDimensionScoreData.length > 0) {
      getInterestDimensionScoreData.forEach((ele) => {
        let temp = {
          ...ele
        }
        dataNew2.push(temp);

      })

      dataNew2.sort((b, a) => getInterestDetails(a, "score") - getInterestDetails(b, "score"))

    }


    let dataNew3 = []
    if (getWorkValueDimensionScoreData && getWorkValueDimensionScoreData.length > 0) {
      getWorkValueDimensionScoreData.forEach((ele) => {
        let temp = {
          ...ele
        }
        dataNew3.push(temp);

      })

      dataNew3.sort((b, a) => getWorkValueDetails(a, "score") - getWorkValueDetails(b, "score"))

    }



    let mainList = [
      {
        "title": "Personality",
        "list": dataXlist,
        "type": 1
      },
      {
        "title": "Interest",
        "list": dataNew2,
        "type": 2
      },
      {
        "title": "Work Value",
        "list": dataNew3,
        "type": 3
      }
    ]



    let careerFitment = []
    if (getCareerFilamentScoreData && getCareerFilamentScoreData.length > 0) {
      careerFitment = getCareerFilamentScoreData.map((item) => ({
        category: item.career,
        percentage: item.overallFitScore.toFixed(2) + "%",
        score: item.overallFitScore
      }))
      careerFitment.sort((b, a) => (a.score - b.score))
    }


    return (
      <>
        <div className="p-6 space-y-2 text-base font-normal text-left bg-white border rounded-lg md:space-y-6">

          <div className="flex items-start lg:space-x-4">

            <p className="text-base  text-[#212121]  " >
              This is your personalised Insights Card, which is unique to you. The results are based on the assessment of your interest, work style, work values, abilities, knowledge, and skill to provide a best career fitment identified in the assessment
              given by you on 01/05/2024.

            </p>
          </div>
        </div>


        <div className="grid grid-cols-2 gap-6 ">

          <div className="w-full p-4 space-y-6 bg-white border rounded-lg md:p-6">
            <div className="space-y-2">
              <h1 className="text-lg font-bold text-gray-800 md:font-semibold md:text-xl">
                Top Recommendations
              </h1>
              <p>Roles where you have high fitment score are indicated below - </p>
            </div>


            <div className="space-y-0.5 text-md ">


              {careerFitment && careerFitment.length > 0 ?
                careerFitment.map((ele, index) =>
                  <>

                    <div className={"flex items-stretch " + (index > 0 ? "ml-" + (index * 12).toString() : "")}>
                      <div
                        style={{
                          background: colorList2[index > 4 ? index % 4 : index]
                        }}
                        className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#64B5F6] flex-shrink-0 ">
                        {index + 1}
                      </div>
                      <div
                        style={{
                          background: hexToRgbA(colorList2[index > 4 ? index % 4 : index], "0.10")
                        }}
                        className="text-sm bg-[#64B5F6]/10 w-full h-12 px-4 flex items-center"
                      >
                        <p>{ele.category}</p>
                      </div>
                    </div>

                  </>

                ) : null}




              {/* <div className="flex items-stretch ">
                <div className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#64B5F6] flex-shrink-0 ">
                  1
                </div>
                <div
                  className="text-sm bg-[#64B5F6]/10 w-full h-12 px-4 flex items-center"
                >
                  <p>  Clinical Nurse Specialists</p>
                </div>
              </div>
              <div className="flex items-stretch  ml-12 ">
                <div className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#BA68C8] flex-shrink-0 ">
                  2
                </div>
                <div
                  className="text-sm bg-[#BA68C8]/10 w-full h-12 px-4 flex items-center"
                >
                  <p>Graduate Teaching Assistants</p>
                </div>
              </div>
              <div className="flex items-stretch  ml-24">
                <div className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#7986CB] flex-shrink-0 ">
                  3
                </div>
                <div
                  className="text-sm bg-[#7986CB]/10 w-full h-12 px-4 flex items-center"
                >
                  <p> Probation Officers and Correctional Treatment Specialists</p>
                </div>
              </div>
              <div className="flex items-stretch  ml-36">
                <div className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#FFB74D] flex-shrink-0 ">
                  4
                </div>
                <div
                  className="text-sm bg-[#FFB74D]/10 w-full h-12 px-4 flex items-center"
                >
                  <p> Informatics Nurse Specialists</p>
                </div>
              </div>   <div className="flex items-stretch  ml-48">
                <div className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#E57373] flex-shrink-0 ">
                  5
                </div>
                <div
                  className="text-sm bg-[#E57373]/10 w-full h-12 px-4 flex items-center"
                >
                  <p>  Special Education Teachers, Kindergarten and Elementary School</p>
                </div>
              </div> */}

            </div>


          </div>
          <div className="w-full p-4 space-y-6 bg-white border rounded-lg md:p-6">
            <div className="space-y-2 pb-4">
              <h1 className="text-lg font-bold text-gray-800 md:font-semibold md:text-xl">
                Fitment Score
              </h1>
            </div>


            <div className="space-y-6 text-md ">

              {careerFitment && careerFitment.length > 0 ?
                careerFitment.map((ele, index) =>
                  <div className="flex items-stretch space-x-4 ">
                    <p className="w-[40%] flex-shrink-0 text-sm">{ele.category}</p>

                    <div className="text-base  w-full  flex items-center space-x-2" >
                      <div className="w-[80%] h-6 flex items-center justify-center font-bold text-base bg-[#64B5F6] flex-shrink-0"
                        style={{
                          width: ele.percentage,
                          background: colorList2[index > 4 ? index % 4 : index]
                        }}>
                      </div>
                      <span className="text-sm">{ele.percentage}</span>
                    </div>
                  </div>
                ) : null}


              {/* <div className="flex items-stretch space-x-4 ">
                <p className="w-[40%] flex-shrink-0 text-sm ">Graduate Teaching Assistants</p>

                <div className="text-base  w-full  flex items-center space-x-2" >
                  <div className="w-[70%] h-6 flex items-center justify-center font-bold text-base bg-[#BA68C8] flex-shrink-0">
                  </div>
                  <span className="text-sm">80.34%</span>
                </div>
              </div>
              <div className="flex items-stretch space-x-4 ">
                <p className="w-[40%] flex-shrink-0 text-sm ">Probation Officers and Correctional
                  Treatment Specialists </p>

                <div className="text-base  w-full  flex items-center space-x-2" >
                  <div className="w-[60%] h-6 flex items-center justify-center font-bold text-base bg-[#7986CB] flex-shrink-0">
                  </div>
                  <span className="text-sm">80.34%</span>
                </div>
              </div>
              <div className="flex items-stretch space-x-4 ">
                <p className="w-[40%] flex-shrink-0 text-sm">Informatics Nurse Specialists</p>

                <div className="text-base  w-full  flex items-center space-x-2" >
                  <div className="w-[50%] h-6 flex items-center justify-center font-bold text-base bg-[#FFB74D] flex-shrink-0">
                  </div>
                  <span className="text-sm">80.34%</span>
                </div>
              </div>
              <div className="flex items-stretch space-x-4 ">
                <p className="w-[40%] flex-shrink-0  text-sm">Special Education Teachers,
                  Kindergarten and Elementary School</p>

                <div className="text-base  w-full  flex items-center space-x-2" >
                  <div className="w-[40%] h-6 flex items-center justify-center font-bold text-base bg-[#E57373] flex-shrink-0">
                  </div>
                  <span className="text-sm">80.34%</span>
                </div>
              </div> */}

            </div>


          </div>


        </div>
        <div className="w-full p-4 space-y-8 bg-white border rounded-lg md:p-6">
          <div className="space-y-2">
            <h1 className="text-lg font-bold text-gray-800 md:font-semibold md:text-xl">
              Psychometric Highlights
            </h1>
            <p>Your top 3 interest, work-related personality traits, and work values are as below,</p>
          </div>


          <div className="grid grid-cols-3 divide-x ">
            {mainList && mainList.length > 0 ?
              mainList.map((element) =>
                <div className="space-y-0.5 px-6  ">
                  <p className="text-center font-semibold text-base pb-4">{element.title}</p>
                  {element && element.list && element.list.length > 0 ?
                    element.list.map((item, index) =>
                      (index < 3) ?
                        <div className={"flex items-stretch " + (index === 2 ? "ml-24" : (index === 1 ? "ml-12" : ""))}>
                          <div
                            style={{ background: colorList[index] }}
                            className="w-12 h-12 flex items-center justify-center font-semibold text-base bg-[#BA68C8] flex-shrink-0 ">
                            {index + 1}
                          </div>
                          <div
                            style={{ background: hexToRgbA(colorList[index], "0.10") }}
                            className="text-sm bg-[#BA68C8]/10 w-full h-12 px-4 flex items-center"
                          >

                            <p>{getDetails(item, "label")}</p>

                          </div>
                        </div>
                        : null
                    ) : null}



                </div>
              ) : null}


          </div>
          <p className="text-base">To deep dive into your abilities and strengths buy our Career Fitment Report which includes your top 10 most suitable roles, fitment in different job families and an industry recommendation. To make systematic and informed career
            decisions connect with our Specialist Career Counsellors for one-on-one career counselling.</p>

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, assessment } = state;
  return {
    loggingIn,
    users,
    dashboard,
    assessment
  };
}
export default connect(mapStateToProps)(Summary);
