import React from 'react';

export default function Component(props) {

const { question, answersList,SurveyAttributes, ansTextBoxChange1 , surveyScreenData} = props;

return(
    <>
            <textarea 
            rows={6} cols={50} 
            value={(answersList && answersList.length > 0 && answersList
              .findIndex(item => item.questionId === question.id) !== -1) ?
              answersList[answersList.findIndex(item => item.questionId === question.id)].answer1
              : ''} 
            onChange={(event)=>ansTextBoxChange1(event,question)} 
            className="w-full bg-gray-100 rounded-md mt-4 p-3 text-sm  placeholder-gray-500 outline-none " 
            style={{
              borderWidth:'3px',
              borderColor:'#757575'
            }} 
            placeholder={SurveyAttributes("WriteHereText")?SurveyAttributes("WriteHereText")+"...":"Write here..."}  />
    </>
  );}