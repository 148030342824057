import {
  questionsConstants
} from '../_constants';

export function questions(state = {}, action) {
// console.log('state',state,action)
  switch (action.type) {


      case questionsConstants.QUESTIONS_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.QUESTIONS_GETALL_SUCCESS:
        return {
          ...state,
          questionsList: action.questions.questionsList,
          answersList: action.questions.answersList,
          surveyScreenData: action.questions.surveyScreenData,
          surveyStatus: action.questions.surveyStatus,
          langList: action.questions.langList,
          GroupQuestionOnlyList: action.questions.GroupQuestionOnlyList



        };
      case questionsConstants.QUESTIONS_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };



        case questionsConstants.QUESTIONS_LIST_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case questionsConstants.QUESTIONS_LIST_GETALL_SUCCESS:
          return {
            ...state,
            questionsList: action.questions.questionsList,
            answersList: action.questions.answersList,
            surveyScreenData: action.questions.surveyScreenData,
            surveyStatus: action.questions.surveyStatus,
            GroupQuestionOnlyList: action.questions.GroupQuestionOnlyList

  
          };
        case questionsConstants.QUESTIONS_LIST_GETALL_FAILURE:
          return {
            ...state,
            error: action.error
          };
  


      case questionsConstants.ANSWERS_SUBMIT_REQUEST:
        return {
          ...state
        };
      case questionsConstants.ANSWERS_SUBMIT_SUCCESS:
        return {
          ...state,
          answerSubmitSuccess: true,
          questionsList: action.questions.questionsList,
          answersList: action.questions.answersList,
          surveyScreenData: action.questions.surveyScreenData,
          surveyStatus: action.questions.surveyStatus,
          langList: action.questions.langList,
          GroupQuestionOnlyList: action.questions.GroupQuestionOnlyList
        };
      case questionsConstants.ANSWERS_SUBMIT_FAILURE:
        return {
          ...state,
          error: action.error,
          submitfail: true
        };
  



        
      case questionsConstants.ANSWERS_ASSESSMENT_SUBMIT_REQUEST:
        return {
          ...state
        };
      case questionsConstants.ANSWERS_ASSESSMENT_SUBMIT_SUCCESS:
        return {
          ...state,
          answerSubmitSuccess: true,
          questionsList: action.questions.questionsList,
          answersList: action.questions.answersList,
          surveyScreenData: action.questions.surveyScreenData,
          surveyStatus: action.questions.surveyStatus,
          langList: action.questions.langList,
          GroupQuestionOnlyList: action.questions.GroupQuestionOnlyList
        };
      case questionsConstants.ANSWERS_ASSESSMENT_SUBMIT_FAILURE:
        return {
          ...state,
          error: action.error,
          submitfail: true
        };





        case questionsConstants.EMPTY_REDUCER:
          return {
            ...state,
            questionsList: action.questions.questionsList,
            answersList: action.questions.answersList,
            surveyScreenData: action.questions.surveyScreenData,
            surveyStatus: action.questions.surveyStatus
          };






          case questionsConstants.GET_LANGUAGE_LABEL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case questionsConstants.GET_LANGUAGE_LABEL_SUCCESS:
            return {
              ...state,
              getLanguageLabelSuccess:true,
              getLanguageLabelData: action.questions.getLanguageLabelData
    
            };
          case questionsConstants.GET_LANGUAGE_LABEL_FAILURE:
            return {
              ...state,
              error: action.error
            };





            
          case questionsConstants.GET_SURVEY_TEMPLATE_CODE_REQUEST:
            return {
              ...state,
              loading: true
            };
          case questionsConstants.GET_SURVEY_TEMPLATE_CODE_SUCCESS:
            return {
              ...state,
              getSurveyTemplateByCodeDataSuccess:true,
              getSurveyTemplateByCodeData: action.questions.getSurveyTemplateByCodeData
    
            };
          case questionsConstants.GET_SURVEY_TEMPLATE_CODE_FAILURE:
            return {
              ...state,
              error: action.error
            };



            case questionsConstants.GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_SUCCESS:
              return {
                ...state,
                getSurveyTemplateByCodeDataSuccess:true,
                getSurveyTemplateByCodeData: action.questions.getSurveyTemplateByCodeData
      
              };
            case questionsConstants.GET_ASSESSMENT_SURVEY_TEMPLATE_CODE_FAILURE:
              return {
                ...state,
                error: action.error
              };
  



            case questionsConstants.GET_SURVEY_TEMPLATE_SURVEY_CODE_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.GET_SURVEY_TEMPLATE_SURVEY_CODE_SUCCESS:
              return {
                ...state,
                getSurveyDetailsBySurveyCodeSuccess:true,
                getSurveyDetailsBySurveyCodeData: action.questions.getSurveyDetailsBySurveyCodeData
      
              };
            case questionsConstants.GET_SURVEY_TEMPLATE_SURVEY_CODE_FAILURE:
              return {
                ...state,
                error: action.error
              };
  



            case questionsConstants.GET_EMPLOYEE_STATUS_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.GET_EMPLOYEE_STATUS_SUCCESS:
              return {
                ...state,
                getEmployeeStatusSuccess:true,
                getEmployeeStatusFailed:false,
                getEmployeeStatusData: action.questions.getEmployeeStatusData      
              };
            case questionsConstants.GET_EMPLOYEE_STATUS_FAILURE:
              return {
                ...state,
                error: action.error,
                employeeMessage: action.error,
                getEmployeeStatusFailed:true,
                getEmployeeStatusSuccess:false
              };



              
            case questionsConstants.GET_RESPONDANT_NAME_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.GET_RESPONDANT_NAME_SUCCESS:
              return {
                ...state,
                getRespondantNameSuccess:true,
                getRespondantNameData: action.questions.getRespondantNameData
      
              };
            case questionsConstants.GET_RESPONDANT_NAME_FAILURE:
              return {
                ...state,
                error: action.error
              };
  

              
              case questionsConstants.GET_ASSESSMENT_EMPLOYEE_DETAILS_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case questionsConstants.GET_ASSESSMENT_EMPLOYEE_DETAILS_SUCCESS:
                return {
                  ...state,
                  getAssessmenrEmployeeDetailsSuccess:true,
                  getAssessmenrEmployeeDetailsData: action.questions.getAssessmenrEmployeeDetailsData
        
                };
              case questionsConstants.GET_ASSESSMENT_EMPLOYEE_DETAILS_FAILURE:
                return {
                  ...state,
                  error: action.error
                };
    

              
                case questionsConstants.GET_ACCESSMENT_REPORT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case questionsConstants.GET_ACCESSMENT_REPORT_SUCCESS:
                  return {
                    ...state,
                    getAssessmentReportSuccess:true,
                    loading: false,
                    getAssessmentReportByEmpIDData: action.questions.getAssessmentReportData
          
                  };
                case questionsConstants.GET_ACCESSMENT_REPORT_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };



                  case questionsConstants.GET_ACCESSMENT_REPORT_RTL_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case questionsConstants.GET_ACCESSMENT_REPORT_RTL_SUCCESS:
                    return {
                      ...state,
                      getAssessmentReportRTLSuccess:true,
                      loading: false,
                      getAssessmentReportRTLData: action.questions.getAssessmentReportRTLData
            
                    };
                  case questionsConstants.GET_ACCESSMENT_REPORT_RTL_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
        


      

                  case questionsConstants.GET_ACCESSMENT_STATUS_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case questionsConstants.GET_ACCESSMENT_STATUS_SUCCESS:
                    return {
                      ...state,
                      getAssessmentStatusSuccess:true,
                      getAssessmentStatusData: action.questions.getAssessmentStatusData
            
                    };
                  case questionsConstants.GET_ACCESSMENT_STATUS_FAILURE:
                    return {
                      ...state,
                      error: action.error
                    };
      
                

              case questionsConstants.GET_TRANSLATE_ELEMENT_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case questionsConstants.GET_TRANSLATE_ELEMENT_SUCCESS:
                return {
                  ...state,
                  getTranslateElementAllSuccess:true,
                  getTranslateElementAllData: action.questions.getTranslateElementAllData
        
                };
              case questionsConstants.GET_TRANSLATE_ELEMENT_FAILURE:
                return {
                  ...state,
                  error: action.error
                };
              


                case questionsConstants.ASSESSMENT_EMP_SHORT_PRODUCT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case questionsConstants.ASSESSMENT_EMP_SHORT_PRODUCT_SUCCESS:
                  return {
                    ...state,
                    getAssessmenrEmployeeProductData: action.questions.getAssessmenrEmployeeProductData,
            
                  };
                case questionsConstants.ASSESSMENT_EMP_SHORT_PRODUCT_FAILURE:
                  return {
                    ...state,
                    error: action.error
                  };
        
        


                  
                  case questionsConstants.UPDATE_ASSESSMENT_DETAILS_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case questionsConstants.UPDATE_ASSESSMENT_DETAILS_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      updateAssessmentUserDetailsSuccess:true,
                      updateAssessmentUserDetailsData: action.assessment.updateAssessmentUserDetailsData
              
                    };
                  case questionsConstants.UPDATE_ASSESSMENT_DETAILS_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
          


                                      
                  case questionsConstants.GET_SURVEY_TAKEN_DATE_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case questionsConstants.GET_SURVEY_TAKEN_DATE_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      getSurveyTakenDateSuccess:true,
                      getSurveyTakenDateData: action.assessment.getSurveyTakenDateData
              
                    };
                  case questionsConstants.GET_SURVEY_TAKEN_DATE_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
          

                                      
                    case questionsConstants.GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case questionsConstants.GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                        getSurveyTakenDateSuccess:true,
                        getSurveyTakenDateData: action.assessment.getSurveyTakenDateData
                
                      };
                    case questionsConstants.GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };
            



    default:
      return state
  }
}