import React from 'react';

export default function Component(props) {

const { question, answersList,surveyLanguageCode, handleCheckInput , surveyScreenData} = props;

return(
    <>
          <div className="mt-4 items-center ">
            {question && question.optionsList && question.optionsList.length>0?
              question.optionsList.map((option, optionindex) =>
                <div className="flex items-center text-gray-700 font-medium mx-0 py-2"> 
                  <input type="checkbox" onClick={() => handleCheckInput(optionindex, question, option)}  
                    onChange={()=>{}}
                    checked={
                    (answersList && answersList.length > 0 && answersList
                      .findIndex(item => item.questionId === question.id) !== -1) ?
                      answersList[answersList.findIndex(item => item.questionId === question.id)].multiAnswer
                      .some(prev=>prev.optionId===option.id)?true:false:false
                  }
                  className="rounded-full w-4 h-5 rounded-full mx-4 cursor-pointer" /> 
                  <label>{(surveyLanguageCode!=='')?
                    (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id])?
                        question.transl[surveyLanguageCode].optionsList[option.id]:option.name
                    :option.name} 
                  </label>
                </div>
            ):null}
          </div>
    </>
  );}