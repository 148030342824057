// import { dataTool } from "echarts";
import React from "react";
export default function CompleteModal(props) {
  let { closeModal, question, handleFeedbackInput, answersList,submitFeedback, surveyScreenData, afterSubmitData,
    SurveyAttributes,chatIndex
  } = props;
  
  function matchFeedback(answersList1,question1){
    let value = "";
    if(answersList1 && answersList1.length>0){
      let getIndex1 = answersList1.findIndex(item => item.questionId === question1.id);
      if(getIndex1!==-1){
        value = answersList1 && answersList1[getIndex1] && answersList1[getIndex1].feedback?answersList1[getIndex1].feedback:"";
      }
    }
    return value
  }

  return (
   <>
     
      <p className="bg-gray-100 text-gray-700 px-4 py-2 text-sm ">{"Give Feedback"} 
          </p>
        <textarea 
            rows={4} cols={10} 
            value={matchFeedback(answersList,question)} 
            onChange={(event)=>handleFeedbackInput(event,question)} 
            className="p-4 w-full focus:outline-none text-[#3D405B] resize-none" 
         
            placeholder={"Write Feeback..."} />


          <div className="flex justify-start w-full pt-1 pb-2 px-4">
            <button onClick={()=>submitFeedback(question,"feedback",chatIndex)}  
            className="py-2 px-6 rounded-full bg-blue-500 text-white font-medium text-sm capitalize ">{"Submit Feedback"}</button>
          </div>


   </>
  );
}
