import React from 'react';

export default function ConfirmSubmitModal(props) {

  let { onClose, onSubmit } = props;

  return (
    <>
      <div className="fixed top-0 left-0 bg-black/60 h-screen w-full flex items-center justify-center z-40 lg:p-0 p-6">

        <div className="bg-white md:w-[40rem] w-full p-6 py-8 rounded-lg space-y-6   ">
          <div className="bg-[#FFC107]/15 rounded-full flex justify-center items-center w-fit p-2 mx-auto ">
            <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
              <g clip-path="url(#clip0_5538_1145)">
                <path d="M53.1967 12.5918L87.7327 72.4078C88.2593 73.3199 88.5365 74.3545 88.5365 75.4078C88.5366 76.461 88.2593 77.4956 87.7327 78.4077C87.2061 79.3198 86.4487 80.0773 85.5366 80.6039C84.6245 81.1305 83.5899 81.4078 82.5367 81.4078H13.4647C12.4115 81.4078 11.3768 81.1305 10.4647 80.6039C9.55265 80.0773 8.79524 79.3198 8.26865 78.4077C7.74206 77.4956 7.46484 76.461 7.46484 75.4078C7.46485 74.3545 7.74209 73.3199 8.26869 72.4078L42.8047 12.5918C45.1127 8.5918 50.8847 8.5918 53.1967 12.5918ZM48.0007 59.9998C46.9398 59.9998 45.9224 60.4212 45.1723 61.1714C44.4221 61.9215 44.0007 62.9389 44.0007 63.9998C44.0007 65.0607 44.4221 66.0781 45.1723 66.8282C45.9224 67.5784 46.9398 67.9998 48.0007 67.9998C49.0616 67.9998 50.079 67.5784 50.8291 66.8282C51.5793 66.0781 52.0007 65.0607 52.0007 63.9998C52.0007 62.9389 51.5793 61.9215 50.8291 61.1714C50.079 60.4212 49.0616 59.9998 48.0007 59.9998ZM48.0007 31.9998C47.021 31.9999 46.0753 32.3596 45.3432 33.0107C44.6111 33.6617 44.1433 34.5588 44.0287 35.5318L44.0007 35.9998V51.9998C44.0018 53.0193 44.3922 53.9999 45.0921 54.7413C45.792 55.4826 46.7485 55.9287 47.7663 55.9885C48.784 56.0482 49.7862 55.7171 50.568 55.0627C51.3498 54.4084 51.8523 53.4802 51.9727 52.4678L52.0007 51.9998V35.9998C52.0007 34.9389 51.5793 33.9215 50.8291 33.1714C50.079 32.4212 49.0616 31.9998 48.0007 31.9998Z" fill="#FFC107" />
              </g>
              <defs>
                <clipPath id="clip0_5538_1145">
                  <rect width="96" height="96" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="space-y-2 ">
            <h2 className="text-2xl text-[#212121] font-semibold text-center">Are you sure to submit this Test?</h2>
            <p className="text-base text-[#212121]/80 text-center">
              Click ‘Yes’ to proceed; Click ‘No’ to go back.<br/>
              Dear Candidate, Once the Test is submitted, you can not edit your responses.</p>
          </div>
          <div className='flex itmes-center justify-center space-x-4 '>
            <button onClick={() => onSubmit()} className="px-6 py-2 border border-[#212121] text-[#212121] font-medium w-20 rounded-md">Yes</button>
            <button onClick={() => onClose()} className="px-6 py-2 border border-[#212121] text-[#212121] font-medium w-20 rounded-md">No</button>

          </div>

        </div>

      </div>
    </>
  );
};

