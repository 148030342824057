import React from 'react';
export default function QuestionHeader(props) {

const { surveyScreenData, template } = props;

let [scrollPosition, HandlescrollPosition] = React.useState(0)

let lastKnownScrollPosition = 0
document.addEventListener('scroll', () =>{
    lastKnownScrollPosition = window.scrollY;
    HandlescrollPosition(lastKnownScrollPosition)
});

// console.log("surveyScreenData",surveyScreenData)
let headerColor = surveyScreenData&&surveyScreenData.headerColor?surveyScreenData.headerColor:"#91194e";

// console.log("headerColor---->",headerColor)

  return (
<>
{scrollPosition<50 && template==='v4' || true?
    <div
    style={{
      // background:'linear-gradient(to left, #146ab5, #14b562)',
      // background:'linear-gradient(to left, #91194e, #91194e)'
      background:headerColor?headerColor:"#91194e"

      // background:'linear-gradient(to left, #c3e6cf, #1c8742)'

    }}
    className="fixed top-0 left-0  w-full  z-10  mb-4">
        <div className="flex justify-between">
        <div className="">
                {(surveyScreenData && surveyScreenData.logo2) ? 
                <img alt="newlogo2" style={{height:"70px"}}
                src={surveyScreenData.logo2 }
                />:null}
                </div>

                <div/>
                
                <div className="">
                {(surveyScreenData && surveyScreenData.logo) ? 
                <img alt="newlogo" style={{height:"70px"}}
                src={surveyScreenData.logo }
                />:null}
                </div>
        </div>
    </div>
:null}
</>
  );}