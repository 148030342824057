import React from "react";
import AssessmentReportCareer from "../../Component/AssessmentReportCareer/AssessmentReportCareer";
import SpeedMeter from "../../../Assessment/MyOrders/Components/SpeedMeter"
import SummaryCareer from "../../../Assessment/MyOrders/Components/Summary/SummaryCareer";

export default function Component(props) {
  const {
    createdAt,
    EditButtonFn,
    EditDetailsFn,
    loading,

    crrEmpDetails,

    closeReportModal,


    getCareerSurveyListData,
    getPersonalityIndexData,
    getWorkValueDimensionScoreData,
    getInterestDimensionScoreData,
    getCareerFilamentScoreData,


  } = props;

  let [showMainMenu, handleMainMenu] = React.useState("Summary");

  let [showDashboard, handleDashboard] = React.useState("Personality");


  const personalityData = [
    { "name": "Learning Beliefs" },
    { "name": "Interpersonal Beliefs" },
    { "name": "Emotional Beliefs" },
    { "name": "Attitudinal Beliefs" },
    { "name": "Conscientious Beliefs" },
    { "name": "Learning Beliefs" },
  ]

  return (
    <>

      {/* /assessment/assessment-code */}
      <div className="flex items-start justify-center overflow-hidden overflow-y-auto customscroll5 h-[calc(100vh-2rem)]">

        <div className="mx-auto bg-[#FAFAFA] rounded-md w-full">
          <section className="w-full ">

            <div className="w-full mx-auto space-y-6">
              <div className="sticky top-0 z-10 flex items-center justify-between bg-white lg:space-y-0">


                <div className="flex xl:w-11/12 mx-auto w-full space-x-6 text-sm md:justify-start md:space-x-8 whitespace-nowrap lg:text-base">


                  <button onClick={() => handleMainMenu("Summary")} className={showMainMenu === "Summary" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <g clipPath="url(#clip0_4081_272)">
                        <path d="M35.556 6.66667H4.44488C3.85551 6.66667 3.29028 6.9008 2.87353 7.31755C2.45678 7.73429 2.22266 8.29952 2.22266 8.88889V31.1111C2.22266 31.7005 2.45678 32.2657 2.87353 32.6825C3.29028 33.0992 3.85551 33.3333 4.44488 33.3333H35.556C36.1454 33.3333 36.7106 33.0992 37.1273 32.6825C37.5441 32.2657 37.7782 31.7005 37.7782 31.1111V8.88889C37.7782 8.29952 37.5441 7.73429 37.1273 7.31755C36.7106 6.9008 36.1454 6.66667 35.556 6.66667ZM35.556 31.1111H4.44488V8.88889H35.556V31.1111Z" fill="#212121" />
                        <path d="M9.99978 15.5556H29.9998C30.2945 15.5556 30.5771 15.4385 30.7855 15.2301C30.9938 15.0217 31.1109 14.7391 31.1109 14.4444C31.1109 14.1498 30.9938 13.8671 30.7855 13.6588C30.5771 13.4504 30.2945 13.3333 29.9998 13.3333H9.99978C9.7051 13.3333 9.42248 13.4504 9.21411 13.6588C9.00574 13.8671 8.88867 14.1498 8.88867 14.4444C8.88867 14.7391 9.00574 15.0217 9.21411 15.2301C9.42248 15.4385 9.7051 15.5556 9.99978 15.5556Z" fill="#212121" />
                        <path d="M9.99978 20H29.9998C30.2945 20 30.5771 19.8829 30.7855 19.6746C30.9938 19.4662 31.1109 19.1836 31.1109 18.8889C31.1109 18.5942 30.9938 18.3116 30.7855 18.1032C30.5771 17.8948 30.2945 17.7778 29.9998 17.7778H9.99978C9.7051 17.7778 9.42248 17.8948 9.21411 18.1032C9.00574 18.3116 8.88867 18.5942 8.88867 18.8889C8.88867 19.1836 9.00574 19.4662 9.21411 19.6746C9.42248 19.8829 9.7051 20 9.99978 20Z" fill="#212121" />
                        <path d="M9.99978 24.4445H21.1109C21.4056 24.4445 21.6882 24.3274 21.8966 24.119C22.1049 23.9106 22.222 23.628 22.222 23.3333C22.222 23.0387 22.1049 22.756 21.8966 22.5477C21.6882 22.3393 21.4056 22.2222 21.1109 22.2222H9.99978C9.7051 22.2222 9.42248 22.3393 9.21411 22.5477C9.00574 22.756 8.88867 23.0387 8.88867 23.3333C8.88867 23.628 9.00574 23.9106 9.21411 24.119C9.42248 24.3274 9.7051 24.4445 9.99978 24.4445Z" fill="#212121" />
                      </g>
                      <defs>
                        <clipPath id="clip0_4081_272">
                          <rect width="40" height="40" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Summary</span>
                  </button>

                  <button onClick={() => handleMainMenu("Report")} className={showMainMenu === "Report" ? "flex md:flex-row flex-col gap-2 items-center md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                      <path d="M5 35V31.6667C5 29.8986 5.70238 28.2029 6.95262 26.9526C8.20286 25.7024 9.89856 25 11.6667 25H18.3333C20.1014 25 21.7971 25.7024 23.0474 26.9526C24.2976 28.2029 25 29.8986 25 31.6667V35M26.6667 5.21667C28.1007 5.58384 29.3717 6.41784 30.2794 7.58718C31.1871 8.75653 31.6797 10.1947 31.6797 11.675C31.6797 13.1553 31.1871 14.5935 30.2794 15.7628C29.3717 16.9322 28.1007 17.7662 26.6667 18.1333M35 35V31.6667C34.9915 30.1953 34.4965 28.768 33.5921 27.6073C32.6877 26.4467 31.4247 25.6178 30 25.25M8.33333 11.6667C8.33333 13.4348 9.03571 15.1305 10.286 16.3807C11.5362 17.631 13.2319 18.3333 15 18.3333C16.7681 18.3333 18.4638 17.631 19.714 16.3807C20.9643 15.1305 21.6667 13.4348 21.6667 11.6667C21.6667 9.89856 20.9643 8.20286 19.714 6.95262C18.4638 5.70238 16.7681 5 15 5C13.2319 5 11.5362 5.70238 10.286 6.95262C9.03571 8.20286 8.33333 9.89856 8.33333 11.6667Z" stroke="#212121" stroke-opacity="0.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>Report</span>
                  </button>

                </div>


                {/* <div className="flex flex-col justify-end gap-4 md:flex-row">
                  <span onClick={() => closeReportModal(false)} className="p-2 text-gray-500 duration-150 bg-gray-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-gray-200 trasition" style={{ fontSize: "28px" }}>close</span>
                </div> */}
              </div>

              <section className="px-4 space-y-6 xl:w-11/12 mx-auto w-full xl:px-0">

                {showMainMenu === "Report" ?
                  <>
                    <AssessmentReportCareer
                      closeReportModal={closeReportModal}
                      getAssessmenrEmployeeDetailsData={crrEmpDetails}
                      createdAt={createdAt}
                      EditButtonFn={EditButtonFn}
                      EditDetailsFn={EditDetailsFn}
                      handleDownloadStatus={() => { }}
                      loading={loading}
                      isUser={false}

                      getCareerFilamentScoreData={getCareerFilamentScoreData}
                      getCareerSurveyListData={getCareerSurveyListData}
                      getPersonalityIndexData={getPersonalityIndexData}
                      getWorkValueDimensionScoreData={getWorkValueDimensionScoreData}
                      getInterestDimensionScoreData={getInterestDimensionScoreData}

                    />
                  </> : null}

                {showMainMenu === "Summary" ?
                  <>
                    <SummaryCareer
                      getAssessmenrEmployeeDetailsData={crrEmpDetails}
                      createdAt={createdAt}
                      EditButtonFn={EditButtonFn}
                      EditDetailsFn={EditDetailsFn}
                      handleDownloadStatus={() => { }}
                      loading={loading}
                      isUser={false}

                      getCareerFilamentScoreData={getCareerFilamentScoreData}
                      getCareerSurveyListData={getCareerSurveyListData}
                      getPersonalityIndexData={getPersonalityIndexData}
                      getWorkValueDimensionScoreData={getWorkValueDimensionScoreData}
                      getInterestDimensionScoreData={getInterestDimensionScoreData}

                    />
                  </> : null}


                {showMainMenu === "Dashboard" ?
                  <>

                    <div className="items-center justify-between border-b md:flex">
                      <div class="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll">
                        <span onClick={() => handleDashboard("Personality")} className={showDashboard === "Personality" ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4] to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]" : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#212121]/70 rounded-t-md border"}>Personality Insights</span>
                        <span onClick={() => handleDashboard("InterestInsights")} className={showDashboard === "InterestInsights" ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4] to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]" : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#212121]/70 rounded-t-md border"}>Interest Insights</span>
                        <span onClick={() => handleDashboard("WorkValue")} className={showDashboard === "WorkValue" ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4] to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]" : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#212121]/70 rounded-t-md border"}>Work Value Insights</span>
                      </div>
                    </div>


                    {showDashboard === "Personality" ?
                      <>
                        <div className="grid grid-cols-1 gap-4 mt-6 md:grid-cols-2">
                          {personalityData.map((item, index) => (
                            <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                              <div className="flex items-center">
                                <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">{item.name}</h1>
                              </div>
                              <div>
                                <div className="flex items-center justify-center">
                                  <SpeedMeter />
                                </div>
                                <div className="w-full px-3">
                                  <div className='space-y-4'>
                                    <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                      <span className='text-sm text-right w-60'>Imaginative and Ex</span>
                                      <span>-</span>
                                      <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                        <div className='flex items-center justify-start h-8' style={{ width: "45%", backgroundColor: "#64B5F6" }}>
                                        </div>
                                        <span className='flex items-center text-sm text-center p-2 text-[#212121]'>45%</span>
                                      </div>
                                    </div>
                                    <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                      <span className='text-sm text-right w-60'>Practical and Real</span>
                                      <span>-</span>
                                      <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                        <div className='flex items-center justify-start h-8' style={{ width: "35%", backgroundColor: "#BA68C8" }}>
                                        </div>
                                        <span className='flex items-center text-sm text-center p-2 text-[#212121]'>35%</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </> : null}
                    {showDashboard === "InterestInsights" ?
                      <>
                        <div className="p-6 mt-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                          <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">Work Value Profile</h1>
                          {personalityData.map((item, index) => (
                            <div className="w-full px-3">
                              <div className='space-y-4'>
                                <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                  <span className='text-sm text-right w-60'>Imaginative and Ex</span>
                                  <span>-</span>
                                  <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                    <div className='flex items-center justify-start h-8' style={{ width: "45%", backgroundColor: "#64B5F6" }}>
                                    </div>
                                    <span className='flex items-center text-sm text-center p-2 text-[#212121]'>45%</span>
                                  </div>
                                </div>
                                <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                  <span className='text-sm text-right w-60'>Practical and Real</span>
                                  <span>-</span>
                                  <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                    <div className='flex items-center justify-start h-8' style={{ width: "35%", backgroundColor: "#BA68C8" }}>
                                    </div>
                                    <span className='flex items-center text-sm text-center p-2 text-[#212121]'>35%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </> : null}
                    {showDashboard === "WorkValue" ?
                      <>
                        <div className="p-6 mt-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                          <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">Work Value Profile</h1>
                          {personalityData.map((item, index) => (
                            <div className="w-full px-3">
                              <div className='space-y-4'>
                                <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                  <span className='text-sm text-right w-60'>Imaginative and Ex</span>
                                  <span>-</span>
                                  <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                    <div className='flex items-center justify-start h-8' style={{ width: "45%", backgroundColor: "#64B5F6" }}>
                                    </div>
                                    <span className='flex items-center text-sm text-center p-2 text-[#212121]'>45%</span>
                                  </div>
                                </div>
                                <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                  <span className='text-sm text-right w-60'>Practical and Real</span>
                                  <span>-</span>
                                  <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                    <div className='flex items-center justify-start h-8' style={{ width: "35%", backgroundColor: "#BA68C8" }}>
                                    </div>
                                    <span className='flex items-center text-sm text-center p-2 text-[#212121]'>35%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </> : null}



                  </> : null}

              </section>

            </div>

          </section>

          <div className="pt-4 ">
            <div className="flex justify-center w-full p-4 bg-white/10">
              <p className="text-sm text-[#212121]/70 ">
                © Powered by
                <b className="ml-1 font-medium text-[#212121]/70">HappyPlus</b>
              </p>
            </div>
          </div>


        </div>




      </div>
    </>
  );
}
