export const CONST = {
  // BACKEND_URL: 'http://localhost:5000/api/v1',
  // BACKEND_HOST: 'http://localhost:5000/',

  // SURVEY_URL: "http://localhost:5000//#/v1/",
  // SURVEY_LOGIN: "http://localhost:5000//#/login/",

  // SURVEY_ASSESSMENT: "http://localhost:5000//#/assessment/surveylogin",
  // SURVEY_ASSESSMENT_USER: "http://localhost:5000//#/assessment/sign-in",


  BACKEND_URL: 'https://www.happinessresearch.in/api/v1',
  BACKEND_HOST: 'https://www.happinessresearch.in/',

  SURVEY_URL: "https://www.happinessresearch.in:8080//#/v1/",
  SURVEY_LOGIN: "https://www.happinessresearch.in:8080//#/login/",

  SURVEY_ASSESSMENT: "https://www.happinessresearch.in//#/assessment/surveylogin",
  SURVEY_ASSESSMENT_USER: "https://www.happinessresearch.in//#/assessment/sign-in",


  
  MERCHANT_ID: "2484086", //Int or String

  CRYPTO_KEY: "secretkey1345100237401", //Int or String

  MANAGER_ID_TYPE: "Int", //Int or String

  APP_NAME: 'PLUS HR',
  FOOTER_TEXT: '©2023 Happyplus. All rights reserved.',

};
