import React from 'react';

export default function Component(props) {

const { question, answersList, ansTextBoxChange1 } = props;

return(
    <>
            <input 
            type="number"
            value={(answersList && answersList.length > 0 && answersList
              .findIndex(item => item.questionId === question.id) !== -1) ?
              answersList[answersList.findIndex(item => item.questionId === question.id)].answer1
              : ''} 
            onChange={(event)=>ansTextBoxChange1(event,question)} 
            className="w-full bg-gray-100 rounded-md mt-4 p-3 text-sm  placeholder-gray-500" 
            placeholder={"Number.."}  />
    </>
  );}