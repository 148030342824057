import React from 'react';

export default function Component(props) {

const { question, answersList, ansNPSInput, surveyLanguageCode, surveyScreenData } = props;
  let [showValue2, handleValue2 ] = React.useState(111)


let tempData = CustomLabels("Dissatisfied", "Dissatisfied",question);

console.log("---------->tempData----------->",tempData)

let showValue = false;
function handleValue(){}

function CustomLabels(optionID,optionName, question){
  let surveyLanguageCode = "customNPS";
  return  (surveyLanguageCode!=='')?
  (question && question.transl && 
    question.transl[surveyLanguageCode] && 
        question.transl[surveyLanguageCode][optionID])?
          question.transl[surveyLanguageCode][optionID]:optionName
  :optionName
}

function NPSName(optionID, optionName, surveyLanguageCode, question){
  return  (surveyLanguageCode!=='')?
  (question && question.transl && 
    question.transl[surveyLanguageCode] && 
      question.transl[surveyLanguageCode].optionsList && 
        question.transl[surveyLanguageCode].optionsList[optionID])?
          question.transl[surveyLanguageCode].optionsList[optionID]:optionName
  :optionName
}

function NPSColor(question){



  let Color = "#a3a3a3";
  let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
  let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
  if(showValue || showValue===0){
    answer = showValue;
  }

  
  if(answer === 0 || answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6 ){
    Color = "#2074e3";
    if(answer === 0 || answer === 1 || answer === 2){
      Name = NPSName("Extremelydissatisfied", CustomLabels("Extremelydissatisfied", "Extremely dissatisfied",question) , surveyLanguageCode, question)
    }
    else if(answer === 3 || answer === 4){
      Name = NPSName("Dissatisfied", CustomLabels("Dissatisfied", "Dissatisfied",question) , surveyLanguageCode, question)
      console.log('Dissatisfied Name: ',Name)
    }
    else if(answer === 5 || answer === 6){
      Name = NPSName("Neither", CustomLabels("Neither", "Neither",question), surveyLanguageCode, question)
    }
  }
  else if(answer === 7){
    Color = "#2074e3";
    Name = NPSName("Satisfied", CustomLabels("Satisfied", "Satisfied",question) , surveyLanguageCode, question)
  }
  else if(answer === 8 || answer === 9 || answer === 10){
    Color = "#2074e3";
    Name = NPSName("Extremelysatisfied", CustomLabels("Extremelysatisfied", "Extremely Satisfied",question) , surveyLanguageCode, question)
  }

  return {
    color:Color,
    name:Name
  }
}



function NPSColor2(question){



  let Color = "#a3a3a3";
  let Name = NPSName("Ratehere", "Rate here", surveyLanguageCode, question)
  let answer = parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer);
  if(showValue2 || showValue2===0){
    answer = showValue2;
  }

  
  if(answer === 0 || answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6 ){
    Color = "#2074e3";
    if(answer === 0 || answer === 1 || answer === 2){
      Name = NPSName("Extremelydissatisfied", CustomLabels("Extremelydissatisfied", "Extremely dissatisfied",question), surveyLanguageCode, question)
    }
    else if(answer === 3 || answer === 4){
      Name = NPSName("Dissatisfied", CustomLabels("Dissatisfied", "Dissatisfied",question), surveyLanguageCode, question)
      console.log('Dissatisfied Name: ',Name)
    }
    else if(answer === 5 || answer === 6){
      Name = NPSName("Neither", CustomLabels("Neither", "Neither",question), surveyLanguageCode, question)
    }
  }
  else if(answer === 7){
    Color = "#2074e3";
    Name = NPSName("Satisfied", CustomLabels("Satisfied", "Satisfied",question), surveyLanguageCode, question)
  }
  else if(answer === 8 || answer === 9 || answer === 10){
    Color = "#2074e3";
    Name = NPSName("Extremelysatisfied", CustomLabels("Extremelysatisfied", "Extremely Satisfied",question), surveyLanguageCode, question)
  }

  return {
    color:Color,
    name:Name
  }
}





return(
    <>
        {/* <span className="italic text-gray-500 text-sm px-4">10 = great place to work, 1 = not so great place to work *</span> */}

          <div className="grid grid-cols-11 gap-4 py-6  lg:text-lg text-sm mx-2">
            {/* 1 */}
            {[...Array(11).keys()].map((value,index)=>
            <>
            {((value).toString()===answersList[answersList.findIndex(item => item.questionId === question.id)].answer)?

              <span onMouseEnter={()=>handleValue2((value))}  onClick={()=>ansNPSInput((value).toString(),question)} key={index} 
              style={{background:NPSColor(question,surveyLanguageCode).color}} 
              className="bg-gray-100 rounded-md md:p-4 md:py-4 p-4 py-2 text-white border-2 border-gray-500  flex items-center justify-center  hover:bg-blue-400  font-medium  cursor-pointer   px-3">
              {value}
              </span>

              :
            <>
            {(value) <= (showValue || parseInt(answersList[answersList.findIndex(item => item.questionId === question.id)].answer))?
              <span onMouseEnter={()=>handleValue2((value))}  onClick={()=>ansNPSInput((value).toString(),question)} key={index} 
                style={{background:NPSColor(question,surveyLanguageCode).color}} 
                className="bg-gray-100 rounded-md md:p-4 md:py-4 p-4 py-2 text-white border-2 border-gray-100  flex items-center justify-center  hover:bg-blue-400  font-medium  cursor-pointer   px-3">
                {value}
              </span>
            :
            <span onMouseEnter={()=>handleValue2((value))}  onClick={()=>ansNPSInput((value).toString(),question)} key={index} 
             className="bg-gray-100 rounded-md md:p-4 md:py-4 p-4 py-2 flex items-center border-2 border-gray-300 justify-center  hover:bg-blue-500 hover:text-white font-medium  cursor-pointer  px-3">
            {value}
              </span>
            }
            </>
            }
            </>
            )}
            
          </div>
          

          <div  
          className="text-white font-semibold text-center mb-2 rounded-sm py-1 mx-2">{NPSColor2(question,surveyLanguageCode).name}</div>

          {/* <div className="flex justify-between">
            <span className="italic text-gray-500 text-sm px-4">{NPSName("Extremelydissatisfied", "1 = not so great", surveyLanguageCode, question)}</span>
            <span className="italic text-gray-500 text-sm px-4">{NPSName("Extremelysatisfied", "10 = great", surveyLanguageCode, question)}</span>
          </div> */}
          {/* <div style={{background:NPSColor(question,surveyLanguageCode).color}} 
          className="bg-red-500 text-white text-center mb-2 rounded-sm py-1 mx-2">{NPSColor(question,surveyLanguageCode).name}</div> */}
          
          
    </>
  );}