import React from 'react';

export default function Component(props) {

const { question, answersList,SurveyAttributes, ansTextBoxChange1, surveyScreenData,clickOK, chatIndex } = props;

let answerText = (answersList && answersList.length > 0 && answersList
  .findIndex(item => item.questionId === question.id) !== -1) ?
  answersList[answersList.findIndex(item => item.questionId === question.id)].answer1
  : '';

return(
    <>
    {/* <textarea cols={10} rows={4} placeholder="Text here.." className="p-4 w-full focus:outline-none text-[#3D405B]" defaultValue={""} /> */}
            <textarea 
            rows={4} cols={10} 
            value={answerText} 
            onChange={(event)=>ansTextBoxChange1(event,question)} 
            className="p-4 w-full focus:outline-none text-[#3D405B] resize-none" 
         
            placeholder={SurveyAttributes("WriteHereText")?SurveyAttributes("WriteHereText")+"...":"Write here..."} />


          <div className="flex justify-start w-full pt-1 pb-2 px-4">
            <button onClick={()=>clickOK(question,"multitext",chatIndex)}  
            className="py-2 px-6 rounded-full bg-blue-500 text-white font-medium text-sm capitalize ">{"OK"}</button>
          </div>




    {/* <div className="md:px-6 px-4 -mt-4 md:-mt-4 lg:-mt-4 xl:-mt-4">

        <button 
        style={{
          background:(surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 :  "#757575",
          color:(surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : "#757575",
        }} 
        onClick={() => clickOK()} className="mt-6 md:rounded-lg rounded-md p-2 px-4 mr-1 text-center text-xl">{SurveyAttributes("okButtonText")?SurveyAttributes("okButtonText"):"OK"}</button>
  
    </div> */}
    </>
  );}