import {
  userConstants
} from '../_constants';

export function user(state = {}, action) {

  switch (action.type) {

    //LOGIN
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        UserLoginEmailSuccess: true,
        UserLoginSuccess: false,
        UserLoginFailure: false,
        UserEmailToken: action.user.userinfoToken.token

      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        UserLoginErrorMSG: action.error,
        UserLoginFailure: true


      };


    case userConstants.USER_CREATE_CONTACTUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_CREATE_CONTACTUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isCreatedContactUs: true
      };
    case userConstants.USER_CREATE_CONTACTUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //ASSESSMENT LOGIN
    case userConstants.LOGIN_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_ASSESSMENT_SUCCESS:
      return {
        ...state,
        UserLoginEmailSuccess: true,
        UserLoginSuccess: false,
        UserLoginFailure: false,
        UserEmailToken: action.user.userinfoToken.token

      };
    case userConstants.LOGIN_ASSESSMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        UserLoginErrorMSG: action.error,
        UserLoginFailure: true


      };





    //LOGOUT
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        logoutSuccess: true,
        userinfo: action.user.userinfoToken


      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error

      };




    //LOGIN OTP
    case userConstants.LOGIN_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_OTP_SUCCESS:
      return {
        ...state,
        UserLoginSuccess: true,
        UserLoginEmailSuccess: false,
        UserLoginOTPFailure: false,
        userinfo: action.user.userinfo
      };
    case userConstants.LOGIN_OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        loginOtpErrorMSG: action.error,
        UserLoginOTPFailure: true


      };




    //ASSESSMENT LOGIN OTP
    case userConstants.LOGIN_ASSESSMENT_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_ASSESSMENT_OTP_SUCCESS:
      return {
        ...state,
        UserLoginSuccess: true,
        UserLoginEmailSuccess: false,
        UserLoginOTPFailure: false,
        userinfo: action.user.userinfo
      };
    case userConstants.LOGIN_ASSESSMENT_OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        loginOtpErrorMSG: action.error,
        UserLoginOTPFailure: true


      };



    //LOGIN OTP
    case userConstants.DARWIN_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DARWIN_OTP_SUCCESS:
      return {
        ...state,
        darwinboxVerifySuccess: true,
        darwinboxVerifyEmailSuccess: false,
        darwinboxVerifyOTPFailure: false,
        darwinboxVerify: action.user.darwinboxVerify
      };
    case userConstants.DARWIN_OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        darwinboxVerifyEmailMSG: action.error,
        darwinboxVerifyEmailFailure: true


      };


    case userConstants.USER_ASSESSMENT_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_ASSESSMENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        userAssessmentRegistrationSuccess: true,
        UserEmailToken: action.user.userAssessmentRegistrationData.token,
      };
    case userConstants.USER_ASSESSMENT_REGISTRATION_FAILURE:
      return {
        ...state,
        error: action.error,
        userAssessmentRegistrationFailure: true


      };




    case userConstants.USER_ASSESSMENT_VERIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_ASSESSMENT_VERIFY_SUCCESS:
      return {
        ...state,
        userAssessmentRegistrationVerifySuccess: true,
        userAssessmentRegistrationVerifyFailure: false,
        userAssessmentRegistrationSuccess: false,
        userAssessmentRegistrationData: action.user.userAssessmentRegistrationData
      };
    case userConstants.USER_ASSESSMENT_VERIFY_FAILURE:
      return {
        ...state,
        error: action.error,
        userAssessmentRegistrationVerifySuccess: false,
        userAssessmentRegistrationVerifyFailure: true,
        userAssessmentRegistrationSuccess: false


      };



    //LOGIN
    case userConstants.SIGNIN_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: true,
        userAssessmentSignInErrorMSG:'',
        userAssessmentSignInFailure: false
      };
    case userConstants.SIGNIN_ASSESSMENT_SUCCESS:
      return {
        ...state,
        userAssessmentSignInSuccess: true,
        userAssessmentSignInFailure: false,
        userAssessmentValidateOtpSuccess: false,
        userAssessmentValidateOtpFailure: false,
        userAssessmentSignInErrorMSG:'',
        UserAssessmentEmailToken: action.user.userAssessmentinfoToken.token

      };
    case userConstants.SIGNIN_ASSESSMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        userAssessmentSignInErrorMSG: action.error,
        userAssessmentSignInSuccess: false,
        userAssessmentSignInFailure: true,

        userAssessmentValidateOtpSuccess: false,
        userAssessmentValidateOtpFailure: false


      };




    // //LOGIN
    // case userConstants.SIGNIN_ASSESSMENT_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case userConstants.SIGNIN_ASSESSMENT_SUCCESS:
    //   return {
    //     ...state,
    //     userAssessmentValidateOtpSuccess: true,
    //     userAssessmentValidateOtpFailure: false,
    //     userAssessmentSignInSuccess:false,
    //     UserAssessmentEmailToken: action.user.userAssessmentinfoToken.token

    //   };
    // case userConstants.SIGNIN_ASSESSMENT_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //     userAssessmentValidateOtpErrorMSG: action.error,
    //     userAssessmentSignInSuccess:false,
    //     userAssessmentValidateOtpFailure: true


    //   };

          //LOGIN
    case userConstants.SIGNIN_ASSESSMENT_VALIDATE_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        userAssessmentValidateOtpSuccess: false,
        userAssessmentValidateOtpFailure: false,
        userAssessmentSignInSuccess:false
      };
    case userConstants.SIGNIN_ASSESSMENT_VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        userAssessmentValidateOtpSuccess: true,
        userAssessmentValidateOtpFailure: false,
        userAssessmentSignInSuccess:false
      };
    case userConstants.SIGNIN_ASSESSMENT_VALIDATE_OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        userAssessmentValidateOtpSuccess: false,
        userAssessmentValidateOtpErrorMSG: action.error,
        userAssessmentValidateOtpFailure: true


      };
      


    default:
      return state
  }
}