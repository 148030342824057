import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions"; 
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import Ready from "../../../components/Ready";
import { GiCheckMark } from "react-icons/gi";
import { GoOrganization } from "react-icons/go";
import { MdOutlineCalendarMonth, MdOutlineLocationOn } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import ImpackSwiper from "./impactSwiper"; 

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.section1Ref = React.createRef();
    this.section2Ref = React.createRef();
    this.section3Ref = React.createRef();
    this.section4Ref = React.createRef();
    this.section5Ref = React.createRef();
    this.section6Ref = React.createRef();
    this.section8Ref = React.createRef();
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,
      zoomed: false,
      saveSuccess: false,

      isScrolled: false,
      showButton1: false,
      showButton2: false,
      showButton3: false,
      showButton4: false,

      fieldslogin: {},
      errorslogin: {},
      isModalOpen: false,

      formData: {
        quantity: 1,
        productId: this.props.match.params.id2,
        licensee: this.props.match.params.id1,
      },
    };
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleImageClick = (value) => {
    this.setState({ currentImage: value });
  };

  componentDidMount() {
    // window.scrollTo(0, 0);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    // this.props.dispatch(assessmentActions.getAssessmentCartCount());

    // this.props.dispatch(
    //   assessmentActions.getAssessmentPackagesById({
    //     id: this.props.match.params.id2,
    //   })
    // );
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentSignInSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };
  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  changeQuantity = (type) => {
    let { formData } = this.state;
    if (type === "add") {
      formData["quantity"] += 1;
    } else if (type === "sub" && formData["quantity"] > 1) {
      formData["quantity"] -= 1;
    }
    this.setState({ formData });
  };





  handleAddToCart = () => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;
    setTimeout(() => {
      this.setState({ saveSuccess: true });
    }, 4000);
    if (
      !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
    ) {
      this.props.history.push(`/assessment/` + "sign-in");
    } else {
      let { formData } = this.state;
      if (formData && formData["productId"]) {
        let data = {
          quantity: formData["quantity"],
          productId: formData["productId"],
          licensee: formData["licensee"],
        };
        this.props.dispatch(
          assessmentActions.assessmentAddToCart(data, this.props)
        );
      }
      setTimeout(() => {
        this.setState({ saveSuccess: false });
      }, 4000);
    }
  };
  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/contact-us");
  };
  // Function to toggle the modal state
  toggleModal = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };
  render() {
    const { assessment } = this.props;
    // const { currentImage, formData, fieldslogin } = this.state;
    let {
      userAssessmentUserProfileData,
      getAssessmentPackagesByIdData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;

    // console.log(
    //   "getAssessmentPackagesByIdData--->",
    //   getAssessmentPackagesByIdData
    // );

    function getDetails(field) {
      return field &&
        getAssessmentPackagesByIdData &&
        getAssessmentPackagesByIdData[field]
        ? getAssessmentPackagesByIdData[field]
        : "NA";
    }
    function getDetailsList(field) {
      return field &&
        getAssessmentPackagesByIdData &&
        getAssessmentPackagesByIdData[field]
        ? getAssessmentPackagesByIdData[field]
        : [];
    }

    let imageSrcList = [];
    let code = getDetails("code");
    let productno = 1;
    if (code) {
      let listNo = [1, 2, 3];
      if (code === "Top5TalentplusStrengths") {
        productno = 1;
        listNo = [1, 2, 3];
      } else if (code === "TalentPlusStrengths21") {
        productno = 2;
        listNo = [1, 2, 3, 4, 5];
      } else if (code === "TalentplusManagerStrengths") {
        productno = 3;
        listNo = [1, 2, 3, 4];
      } else if (code === "TalentPlusHiringStrengths") {
        productno = 4;
        listNo = [1, 2, 3, 4];
      }
      if (listNo && listNo.length > 0) {
        listNo.forEach((element) => {
          let path = "/photos/" + code + "_" + element + ".webp";
          console.log("path--->", path);
          imageSrcList.push(path);
        });
      }
    }

    // const { zoomed } = this.state;


    return (
      <div className="overflow-hidden ">


        <section
          className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
          style={{
            backgroundImage: `url('/img/assessment/bg-gradient.webp')`,
          }}
        >
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            cartCount={
              getAssessmentCartCountData ? getAssessmentCartCountData : 0
            }
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />
          {/* Hero Section */}
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10  md:pt-10  md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto md:bg-gradient-to-r bg-gradient-to-b lg:from-transparent  lg:to-transparent  from-[#E9F5FE]/90 to-[#E9F5FE]/90 ">
              <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-8 text-center md:pb-10 md:pt-0 md:text-left ">
                {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">About Us</h1>
                <div data-aos="fade-right" className="space-y-3 text-base font-light md:text-lg">
                  <h2 className="text-[#212121] text-lg font-medium">A team of happiness experts, positive psychologists and researchers brought forth HappyPlus</h2>
                  <p className="text-[#212121]">Happy+ is created by happiness experts, positive psychologists and researchers whose quest is to add happiness to the world through different interventions across stakeholders.</p>
                </div>
                <div className="flex flex-row items-center justify-center gap-4 md:justify-start md:items-center">
                  {!this.state.showButton5 && (
                    <button data-aos="fade-right" onClick={() =>
                      window.scrollTo({
                        top: this.section6Ref.current.offsetTop,
                        behavior: "smooth",
                      })
                    } className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center">  Enroll Now</button>
                  )}
                </div>
              </div>

              <div className="flex items-end justify-center w-full lg:justify-end">
                <img data-aos="fade-left" src="/img/assessment/about-us.png" alt="impact-coach" className="z-0 w-full" />
              </div>
            </div>
          </div>

        </section>
        {/* Our Values section */}
        <div className="flex md:flex-row  flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <div className="w-full flex items-center">
            <img
              data-aos="fade-left"
              alt="Learning"
              width={790}
              height={790}
              src="/img/Home/Learning.webp"
            />
          </div>
          <div data-aos="fade-right" className="w-full space-y-8">
            <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
              Our Values
            </h1>
            <p className="text-lg text-[#212121]">What distinguishes impact coach training from others in the industry is our empirical approach to driving lasting change. </p>
            <div className="space-y-4 ">
              <div className="flex flex-row bg-[#F4F9FE] p-6 px-4 gap-4  items-start   cursor-pointer w-full  border-[#2196F3]/10 border  rounded-2xl  ">

                <div className="flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 136 136" fill="none">
                    <path d="M67.9186 90.3077C47.8694 90.3077 32.0479 70.1477 31.3833 69.28C31.1348 68.9572 31 68.5612 31 68.1538C31 67.7465 31.1348 67.3505 31.3833 67.0277C32.0479 66.16 47.8694 46 67.9186 46C87.9679 46 103.789 66.16 104.454 67.0277C104.703 67.3505 104.837 67.7465 104.837 68.1538C104.837 68.5612 104.703 68.9572 104.454 69.28C103.789 70.1477 87.9679 90.3077 67.9186 90.3077ZM35.2417 68.1538C38.8233 72.3262 52.2817 86.6154 67.9186 86.6154C83.5556 86.6154 97.014 72.3262 100.596 68.1538C97.014 63.9815 83.5556 49.6923 67.9186 49.6923C52.2817 49.6923 38.8233 63.9815 35.2417 68.1538ZM67.9186 55.2308C67.1502 55.2295 66.3834 55.3037 65.6294 55.4523C66.3494 56.1387 66.9234 56.9634 67.3167 57.8771C67.7101 58.7908 67.9148 59.7745 67.9186 60.7692C67.9186 62.7278 67.1406 64.6061 65.7557 65.9909C64.3709 67.3758 62.4925 68.1538 60.534 68.1538C59.5393 68.1501 58.5556 67.9453 57.6419 67.5519C56.7282 67.1586 55.9035 66.5846 55.2171 65.8646C55.0685 66.6186 54.9943 67.3854 54.9956 68.1538C54.9956 70.7098 55.7535 73.2083 57.1735 75.3335C58.5935 77.4587 60.6118 79.1151 62.9732 80.0932C65.3346 81.0713 67.933 81.3273 70.4398 80.8286C72.9466 80.33 75.2493 79.0992 77.0566 77.2918C78.864 75.4845 80.0948 73.1818 80.5934 70.675C81.0921 68.1682 80.8361 65.5698 79.858 63.2084C78.8799 60.847 77.2235 58.8287 75.0983 57.4087C72.9731 55.9887 70.4746 55.2308 67.9186 55.2308Z" fill="#212121" />
                    <path d="M116 31.0769V47.6923C116 48.1819 115.805 48.6515 115.459 48.9977C115.113 49.344 114.643 49.5385 114.154 49.5385C113.664 49.5385 113.195 49.344 112.848 48.9977C112.502 48.6515 112.308 48.1819 112.308 47.6923V31.0769C112.308 29.1184 111.53 27.2401 110.145 25.8552C108.76 24.4703 106.882 23.6923 104.923 23.6923H88.3077C87.8181 23.6923 87.3485 23.4978 87.0023 23.1516C86.656 22.8054 86.4615 22.3358 86.4615 21.8462C86.4615 21.3565 86.656 20.8869 87.0023 20.5407C87.3485 20.1945 87.8181 20 88.3077 20H104.923C107.861 20 110.678 21.167 112.756 23.2444C114.833 25.3217 116 28.1391 116 31.0769Z" fill="#2196F3" />
                    <path d="M23.6923 31.0769V47.6923C23.6923 48.1819 23.4978 48.6515 23.1516 48.9977C22.8054 49.344 22.3358 49.5385 21.8462 49.5385C21.3565 49.5385 20.8869 49.344 20.5407 48.9977C20.1945 48.6515 20 48.1819 20 47.6923V31.0769C20 28.1391 21.167 25.3217 23.2444 23.2444C25.3217 21.167 28.1391 20 31.0769 20H47.6923C48.1819 20 48.6515 20.1945 48.9977 20.5407C49.344 20.8869 49.5385 21.3565 49.5385 21.8462C49.5385 22.3358 49.344 22.8054 48.9977 23.1516C48.6515 23.4978 48.1819 23.6923 47.6923 23.6923H31.0769C29.1184 23.6923 27.2401 24.4703 25.8552 25.8552C24.4703 27.2401 23.6923 29.1184 23.6923 31.0769Z" fill="#2196F3" />
                    <path d="M116 88.3077V104.923C116 107.861 114.833 110.678 112.756 112.756C110.678 114.833 107.861 116 104.923 116H88.3077C87.8181 116 87.3485 115.805 87.0023 115.459C86.656 115.113 86.4615 114.643 86.4615 114.154C86.4615 113.664 86.656 113.195 87.0023 112.848C87.3485 112.502 87.8181 112.308 88.3077 112.308H104.923C106.882 112.308 108.76 111.53 110.145 110.145C111.53 108.76 112.308 106.882 112.308 104.923V88.3077C112.308 87.8181 112.502 87.3485 112.848 87.0023C113.195 86.656 113.664 86.4615 114.154 86.4615C114.643 86.4615 115.113 86.656 115.459 87.0023C115.805 87.3485 116 87.8181 116 88.3077Z" fill="#2196F3" />
                    <path d="M48.9977 112.848C49.344 113.195 49.5385 113.664 49.5385 114.154C49.5385 114.643 49.344 115.113 48.9977 115.459C48.6515 115.805 48.1819 116 47.6923 116H31.0769C28.1391 116 25.3217 114.833 23.2444 112.756C21.167 110.678 20 107.861 20 104.923V88.3077C20 87.8181 20.1945 87.3485 20.5407 87.0023C20.8869 86.656 21.3565 86.4615 21.8462 86.4615C22.3358 86.4615 22.8054 86.656 23.1516 87.0023C23.4978 87.3485 23.6923 87.8181 23.6923 88.3077V104.923C23.6923 106.882 24.4703 108.76 25.8552 110.145C27.2401 111.53 29.1184 112.308 31.0769 112.308H47.6923C48.1819 112.308 48.6515 112.502 48.9977 112.848Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className="text-[#212121] space-y-1.5 ">
                  <p className="text-xl font-semibold leading-6">Vision </p>
                  <p className="text-lg font-normal">Everything we do should add (+) Happiness in this world.
                  </p>
                </div>
              </div>
              <div className="flex flex-row bg-[#F4F9FE] p-6 px-4 gap-4  items-start   cursor-pointer w-full  border-[#2196F3]/10 border  rounded-2xl  ">

                <div className="flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 136 136" fill="none">
                    <path d="M89.4862 101.494C94.1699 90.1343 94.1202 77.1521 89.3702 65.8232L93.5518 58.5805C100.79 46.0444 102.711 31.4401 98.9658 17.4574L98.9006 17.2142C98.6236 16.1804 97.5642 15.5688 96.5305 15.8458L96.2872 15.911C82.3062 19.6572 70.6184 28.625 63.3806 41.1611L59.199 48.4039C47.0129 49.9547 35.7451 56.4028 28.2488 66.139L21.7785 74.5433C21.2142 75.2769 21.2468 76.3081 21.8581 77.003C22.0019 77.1642 22.1665 77.2972 22.3441 77.3998C22.9325 77.7395 23.6757 77.7529 24.2868 77.395L27.7347 75.383C32.7614 72.452 38.8813 72.1681 44.14 74.4869L43.2362 76.0524C42.702 76.9777 43.0193 78.1621 43.9446 78.6963L45.6208 79.6641L42.7175 84.6926C42.1833 85.6178 42.5007 86.8023 43.426 87.3365L51.8069 92.1752L47.9359 98.8799L51.2882 100.815L55.1592 94.1107L63.5401 98.9494C64.4653 99.4836 65.6498 99.1662 66.184 98.241L69.0872 93.2124L70.7634 94.1802C71.6886 94.7144 72.8731 94.397 73.4073 93.4717L74.3102 91.9079C78.9486 95.301 81.7617 100.745 81.7368 106.563L81.7183 110.555C81.7166 111.263 82.0981 111.899 82.6864 112.238C82.8641 112.341 83.0616 112.417 83.2741 112.459C84.1815 112.641 85.0899 112.156 85.444 111.298L89.4862 101.494ZM95.6426 20.1204C96.9907 25.9183 97.2867 31.8083 96.5852 37.5716L80.0581 28.0297C84.6986 24.5405 89.9474 21.8518 95.6426 20.1204ZM30.0638 70.1257L31.3155 68.4996C37.5639 60.3828 46.6793 54.7906 56.679 52.7687L46.0881 71.1127C41.0414 68.7712 35.3015 68.4318 30.0638 70.1257ZM63.7994 94.6293L47.0376 84.9519L48.9731 81.5995L65.7349 91.2769L63.7994 94.6293ZM71.0227 89.8601L47.5562 76.3117L66.733 43.0966C69.4659 38.3631 72.8887 34.1843 76.8538 30.6495L95.9203 41.6575C94.8399 46.8579 92.9323 51.9115 90.1994 56.645L71.0227 89.8601ZM76.2583 88.5337L86.8502 70.188C90.098 79.8607 89.811 90.5499 85.9086 100.019L85.1261 101.916C83.9742 96.5331 80.8104 91.7318 76.2583 88.5337Z" fill="black" />
                    <path d="M80.5785 46.2103C75.0321 43.008 67.9173 44.9144 64.715 50.4609C61.5128 56.0073 63.4192 63.1221 68.9657 66.3244C74.5121 69.5266 81.6269 67.6202 84.8291 62.0737C88.0314 56.5273 86.125 49.4125 80.5785 46.2103ZM70.9011 62.972C67.2035 60.8372 65.9326 56.094 68.0674 52.3963C70.2022 48.6987 74.9454 47.4278 78.6431 49.5626C82.3407 51.6974 83.6116 56.4406 81.4768 60.1383C79.342 63.8359 74.5988 65.1068 70.9011 62.972Z" fill="#2196F3" />
                    <path d="M60.1841 100.241L56.3217 100.49L56.5599 104.192C56.6724 105.944 56.2686 107.673 55.3908 109.194C54.2073 111.244 52.2965 112.711 50.0095 113.323L40.3355 115.916L37.7433 106.242C37.1305 103.955 37.4455 101.566 38.6291 99.5164C39.5068 97.9961 40.8024 96.7818 42.3761 96.0031L45.7014 94.3583L43.9855 90.8891L40.6602 92.5339C38.3993 93.6512 36.5377 95.3968 35.2767 97.5809C33.5764 100.526 33.1232 103.959 34.0048 107.243L37.0978 118.787C37.236 119.302 37.5702 119.714 37.9993 119.962C38.4284 120.21 38.9524 120.293 39.4679 120.155L51.0113 117.062C54.2967 116.183 57.0429 114.074 58.7432 111.129C60.0042 108.945 60.5851 106.46 60.4223 103.944L60.1841 100.241Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className="text-[#212121] space-y-1.5 ">
                  <p className="text-lg font-semibold leading-6">Mission </p>
                  <p className="text-lg font-normal">To make Happiness a habit for everyone with the help of technology & experts.
                  </p>
                </div>
              </div>
              <div className="flex flex-row bg-[#F4F9FE] p-6 px-4 gap-4  items-start   cursor-pointer w-full  border-[#2196F3]/10 border  rounded-2xl  ">

                <div className="flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 136 136" fill="none">
                    <path d="M67.9677 51.0004C70.1349 51.0004 72.2431 51.4024 74.2319 52.194C75.4087 52.6639 76.7316 52.0905 77.2 50.9214C77.6674 49.7512 77.0974 48.4251 75.9251 47.9586C73.3953 46.951 70.7183 46.4399 67.9677 46.4399C56.0862 46.4399 46.4199 56.1043 46.4199 67.9832C46.4199 79.8811 56.0862 89.5599 67.9677 89.5599C71.9888 89.5599 75.7299 88.5144 79.0159 86.4925C82.0477 84.7722 84.641 82.2125 86.5105 79.0983C88.5205 75.7826 89.5823 71.9391 89.5823 67.9832C89.5823 65.3534 89.1138 62.7903 88.1892 60.362C87.7419 59.1862 86.4234 58.5939 85.2422 59.0415C84.0632 59.4879 83.4709 60.8051 83.9193 61.9831C84.6455 63.8903 85.0135 65.9089 85.0135 67.9832C85.0135 71.152 84.2015 74.0969 82.5964 76.7457C81.1263 79.1952 79.1063 81.1949 76.6891 82.5677C74.0646 84.181 71.1299 84.9994 67.9677 84.9994C58.6059 84.9994 50.9887 77.3659 50.9887 67.9832C50.9887 58.6184 58.6059 51.0004 67.9677 51.0004Z" fill="black" />
                    <path d="M82.0744 38.3356C82.534 37.1632 81.954 35.8404 80.7794 35.3817C76.6668 33.7751 72.3535 32.9612 67.9576 32.9612C48.6597 32.9612 32.959 48.6702 32.959 67.9789C32.959 87.312 48.6597 103.04 67.9576 103.04C74.4048 103.04 80.6433 101.293 85.9371 98.0241C90.8929 95.1693 95.0925 91.0108 98.0763 86.0049C101.37 80.5403 103.041 74.4756 103.041 67.9789C103.041 63.7157 102.268 59.5226 100.745 55.5165C100.297 54.3397 98.9753 53.7518 97.7985 54.1938C96.6195 54.6414 96.0261 55.9575 96.4734 57.1354C97.7996 60.6226 98.4722 64.2712 98.4722 67.9789C98.4722 73.635 97.0222 78.9092 94.1555 83.6634C91.5655 88.009 87.9337 91.6087 83.5947 94.1094C78.8966 97.0087 73.6351 98.4795 67.9576 98.4795C51.1783 98.4795 37.5277 84.7969 37.5277 67.9789C37.5277 51.1843 51.1783 37.5217 67.9576 37.5217C71.7813 37.5217 75.5347 38.2309 79.1152 39.6283C80.2919 40.087 81.616 39.508 82.0744 38.3356Z" fill="black" />
                    <path d="M85.4575 23.2979C79.8358 21.1101 73.943 20 67.942 20C41.5065 20 20 41.5199 20 67.9711C20 94.4545 41.5065 116 67.942 116C76.7717 116 85.3125 113.608 92.5828 109.119C99.3657 105.214 105.115 99.5205 109.2 92.6619C113.712 85.1776 116 76.8705 116 67.9711C116 62.1145 114.935 56.3538 112.836 50.848C112.387 49.67 111.064 49.0844 109.887 49.5286C108.708 49.9762 108.117 51.2945 108.566 52.4713C110.468 57.4561 111.431 62.6712 111.431 67.9711C111.431 76.031 109.363 83.5465 105.28 90.3204C101.587 96.5188 96.4065 101.654 90.2404 105.204C83.5379 109.342 76.0355 111.439 67.942 111.439C44.0262 111.439 24.5688 91.9393 24.5688 67.9711C24.5688 44.034 44.0262 24.5605 67.942 24.5605C73.3719 24.5605 78.7069 25.5648 83.7977 27.5467C84.9756 28.002 86.2974 27.4231 86.7558 26.2507C87.2143 25.0771 86.6332 23.7555 85.4575 23.2979Z" fill="black" />
                    <path d="M66.374 69.6099C66.8213 70.0619 67.4113 70.2891 68.0014 70.2891C68.5803 70.2891 69.1603 70.0708 69.6054 69.6333L95.0772 44.5713L101.074 45.3543C101.173 45.3666 101.272 45.3733 101.37 45.3733C101.973 45.3733 102.555 45.1361 102.986 44.7052L111.894 35.8125C112.51 35.199 112.722 34.2893 112.442 33.4665C112.162 32.6448 111.438 32.0525 110.576 31.9389L105.214 31.2386L104.513 25.8842C104.399 25.0236 103.805 24.301 102.982 24.0215C102.156 23.7432 101.247 23.9525 100.632 24.5682L91.7231 33.4632C91.2212 33.9642 90.9813 34.669 91.0728 35.3716L91.8547 41.3369L66.3974 66.3843C65.4995 67.2684 65.4884 68.7125 66.374 69.6099Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className="text-[#212121] space-y-1.5 ">
                  <p className="text-xl font-semibold leading-6">Purposeful </p>
                  <p className="text-lg font-normal">Change the way people at look at Happiness at a workplace and in life generally.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Meet Our Leadership section */}
        <section className="bg-[#EEF7FE] w-full">
          <div className="w-full px-4 py-20 mx-auto space-y-10 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <div className="flex md:flex-row flex-col md:items-center md:gap-10 gap-4">
              <h2 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem] flex-shrink-0 capitalize font-bold text-[#212121]">Our Leadership</h2>
              <p className="text-lg">What distinguishes impact coach training from others in the industry is our empirical approach to driving lasting change. </p>
            </div>

            <div className="grid grid-cols-1 gap-6 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2">
              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/dr._ashish_ambasta.png" alt="dr._ashish_ambasta.png" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Dr. Ashish Ambasta</h2>
                  <p className="tracking-wide  ">Founder/CEO</p>
                  <button onClick={this.toggleModal} className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>
              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/basant.jpg" alt="basant.jpg" className="z-0 w-full bg-gray-100 rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Basant Kumar</h2>
                  <p className="tracking-wide  ">Co-Founder/CTO</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>

              </div>
              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/shyamasree_chakrabarty.png" alt="shyamasree_chakrabarty.png" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Shyamasree Chakrabarty</h2>
                  <p className="tracking-wide  ">Senior Director</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>

              </div>
              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/Vineet-Singh.webp" alt="Vineet-Singh.webp" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Vineet Singh</h2>
                  <p className="tracking-wide  ">Head of Strategy and International Operations</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>

              </div>
              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/vimal-kumar.jpg" alt="vimal-kumar.jpg" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Vimal Kumar</h2>
                  <p className="tracking-wide  ">Director</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>

              </div>


            </div>
          </div>
        </section>

        {/* Our Advisors section */}
        <section className="bg-[#FFF] w-full">
          <div className="w-full px-4 py-20 mx-auto space-y-10 2xl:w-9/12 xl:w-11/12 xl:px-0">
          <div className="flex md:flex-row flex-col md:items-center md:gap-10 gap-4">
              <h2 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  flex-shrink-0  capitalize font-bold text-[#212121]">Our Advisors</h2>
              <p className="text-lg">What distinguishes impact coach training from others in the industry is our empirical approach to driving lasting change. </p>
            </div>

            <div className="grid grid-cols-1 gap-6 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/dr._r_sridhar.png" alt="dr._r_sridhar.png" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Dr. R Sridhar</h2>
                  <p className="tracking-wide  ">Former Director of Xavier Institute of Management & Entrepreneurship</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>

              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/p.v-ramana.png" alt="p.v-ramana.png" className="z-0 w-full bg-gray-100 rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">P. V. Ramana Murthy</h2>
                  <p className="tracking-wide  ">Executive Vice President and Global Head, Human Resources at IHCL</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>

              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/Dr.-Srinivasan-R-Iyengar.png" alt="Dr.-Srinivasan-R-Iyengar.png" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Dr. Srinivasan .R Iyengar</h2>
                  <p className="tracking-wide  ">Director & Professor at JBIMS, University of Mumbai and visiting professor at IIM Indore</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>


              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/srinivas.png" alt="Dr.-Srinivasan-R-Iyengar.png" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Dr. Srinivas Chunduru</h2>
                  <p className="tracking-wide  ">Founder - VANS Group. MoB - OBP, AF Capital, Crowdera, Snackamor</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>

              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/dur_khan.png" alt="dur_khan.png" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Dr. Dur Khan</h2>
                  <p className="tracking-wide  ">Core Faculty in HR at Jamnalal Bajaj Institute of Management Studies, Mumbai</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>


              <div data-aos="fade-up" className="">
                <img src="/img/aboutus/Malvika Ambasta.png" alt="Malvika Ambasta.png" className="z-0 w-full rounded-t h-72" />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Malvika Ambasta</h2>
                  <p className="tracking-wide  ">Founder QuestX | Life Coach | REBT Therapist | Social Works Counsellor</p>
                  <button className="flex mt-3 space-x-2 text-base text-[#2196F3] uppercase">
                    <span className=" font-medium text-left ">know more</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 40 40" fill="none">
                      <path d="M28.3327 11.6667L11.666 28.3334M28.3327 11.6667H13.3327M28.3327 11.6667V26.6667" stroke="#2196F3" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="bg-[#F9FAFB] w-full">
          <div className="w-full px-4 py-20 mx-auto space-y-10 2xl:w-9/12 xl:w-11/12 xl:px-0">
          <div className="flex md:flex-row flex-col md:items-center md:gap-10 gap-4">
              <h2 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]   flex-shrink-0 capitalize font-bold text-[#212121]">Our CSR</h2>
              <p className="text-lg">What distinguishes impact coach training from others in the industry is our empirical approach to driving lasting change. </p>
            </div>

            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 md:grid-cols-2">

              <div className=" flex w-full flex-col ">
                <div className=" ">
                  <img src="/img/aboutus/seetalks.jpg" alt="seetalks.jpg" className="w-full" />
                </div>
                <div className="py-4">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Seetalks</h2>
                  <p className="block text-base antialiased leading-relaxed text-gray-700">School of Entrepreneurial Exploration (SEE) is an NGO that was established in 2016 with a mission of getting one million Indians to explore Entrepreneurship.</p>
                </div>
              </div>

              <div className=" flex w-full flex-col ">
                <div className=" ">
                  <img src="/img/aboutus/loveheals_cancer.jpg" alt="loveheals_cancer.jpg" className="w-full" />
                </div>
                <div className="py-4">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Love Heals Cancer</h2>
                  <p className="block text-base antialiased leading-relaxed text-gray-700">The mission of Love Heals Cancer is Guiding cancer patients in their journey and spreading awareness to see a world free from cancer.</p>
                </div>
              </div>

              <div className=" flex w-full flex-col ">
                <div className=" ">
                  <img src="/img/aboutus/kindness_unlimited.jpg" alt="kindness_unlimited.jpg" className="w-full" />
                </div>
                <div className="py-4">
                  <h2 className="mb-1 font-semibold   capitalize text-lg ">Kindness Unlimited</h2>
                  <p className="block text-base antialiased leading-relaxed text-gray-700">Kindness Unlimited (KU) helps bridge the huge nutritional gap in children especially for the adivasi communities in Maharashtra.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
        {/* modal */}
        {this.state.isModalOpen && (
          <div className="fixed top-0 bg-black/60 w-full h-screen flex items-center justify-center z-40">
            <div className="w-5/12 bg-white mx-auto rounded-2xl bg-clip-border px-6 space-y-6">

              <div className="flex items-center justify-between px-6 py-4 border-b   -mx-6"><h2 className="text-lg font-semibold md:text-2xl test-[#212121] ">About  </h2>
                <button onClick={this.toggleModal} className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "><svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path></svg></button></div>

              <div className="flex flex-row space-x-6 items-center">
                <img src="/img/aboutus/dr._ashish_ambasta.png" alt="dr._ashish_ambasta.png" className="h-40 flex-shrink-0 " />
                <div className="py-4 text-[#212121]">
                  <h2 className="mb-1 font-semibold   capitalize xl:text-2xl text-lg ">Dr. Ashish Ambasta</h2>
                  <p className="tracking-wide xl:text-lg text-base  ">Founder/CEO</p>
                </div>
              </div>
              <p className="text-lg pb-6">
                After working in different firms including Indian conglomerates & World-renowned consulting firms he has decided to devote his life to improving happiness around the world through Happy+

                It’s a technology-enabled happiness habit formation organisation that intends to change the way people look at happiness. He is ably backed by a team of talented people who are experts in their respective spheres.

                He is also the founder of SEEtalks which tells the untold stories of changemakers to inspire the next generation to do good.

                Ashish is also on board with Love Heals Cancer along with Kindness Unlimited and is trying to make the world a happy place.
              </p>

            </div>


          </div>
        )}
      </div>
    );
  }
}
SignIn.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(SignIn);
