import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { user } from './user.reducer';
import { alert } from './alert.reducer';
import { questions } from './questions.reducer';
import { assessment } from './assessment.reducer';


const rootReducer = combineReducers({
  authentication,
  user,
  alert,
  questions,
  assessment
});

export default rootReducer;
