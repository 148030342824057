import React from 'react';
export default function QuestionHeader(props) {

const { questionName, surveyLanguageCode , QuesNo} = props;

let [scrollPosition, HandlescrollPosition] = React.useState(0)

let lastKnownScrollPosition = 0
document.addEventListener('scroll', () =>{
    lastKnownScrollPosition = window.scrollY;
    HandlescrollPosition(lastKnownScrollPosition)
});


// console.log('scrollPosition: ',scrollPosition)
  return (
<>

{scrollPosition>50?

    <div className="fixed top-0 left-0 bg-gray-200 bg-opacity-80 w-full  z-10  ">
    <div className="md:w-4/6   mx-auto ">
        <h1 className="md:text-xl text-lg py-6 text-center  font-semibold px-4">
        {QuesNo?QuesNo:""}{questionName}
        </h1>
        </div>
    </div>

:null}
</>
  );}