export const assessmentConstants = {

    USER_ASSESSMENT_USER_PROFILE_REQUEST: 'USER_ASSESSMENT_USER_PROFILE_REQUEST',
    USER_ASSESSMENT_USER_PROFILE_SUCCESS: 'USER_ASSESSMENT_USER_PROFILE_SUCCESS',
    USER_ASSESSMENT_USER_PROFILE_FAILURE: 'USER_ASSESSMENT_USER_PROFILE_FAILURE',

    USER_ASSESSMENT_PACKAGE_BUY_REQUEST: 'USER_ASSESSMENT_PACKAGE_BUY_REQUEST',
    USER_ASSESSMENT_PACKAGE_BUY_SUCCESS: 'USER_ASSESSMENT_PACKAGE_BUY_SUCCESS',
    USER_ASSESSMENT_PACKAGE_BUY_FAILURE: 'USER_ASSESSMENT_PACKAGE_BUY_FAILURE',

    USER_ASSESSMENT_PACKAGE_ORDERS_REQUEST: 'USER_ASSESSMENT_PACKAGE_ORDERS_REQUEST',
    USER_ASSESSMENT_PACKAGE_ORDERS_SUCCESS: 'USER_ASSESSMENT_PACKAGE_ORDERS_SUCCESS',
    USER_ASSESSMENT_PACKAGE_ORDERS_FAILURE: 'USER_ASSESSMENT_PACKAGE_ORDERS_FAILURE',

    USER_ASSESSMENT_SURVEY_LOGIN_REQUEST: 'USER_ASSESSMENT_SURVEY_LOGIN_REQUEST',
    USER_ASSESSMENT_SURVEY_LOGIN_SUCCESS: 'USER_ASSESSMENT_SURVEY_LOGIN_SUCCESS',
    USER_ASSESSMENT_SURVEY_LOGIN_FAILURE: 'USER_ASSESSMENT_SURVEY_LOGIN_FAILURE',

    USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_REQUEST: 'USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_REQUEST',
    USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_SUCCESS: 'USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_SUCCESS',
    USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_FAILURE: 'USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_FAILURE',

    GET_ASSESSMENT_PACKAGE_LIST_REQUEST: 'GET_ASSESSMENT_PACKAGE_LIST_REQUEST',
    GET_ASSESSMENT_PACKAGE_LIST_SUCCESS: 'GET_ASSESSMENT_PACKAGE_LIST_SUCCESS',
    GET_ASSESSMENT_PACKAGE_LIST_FAILURE: 'GET_ASSESSMENT_PACKAGE_LIST_FAILURE',

    GET_FILAMENT_SCORE_REQUEST: 'GET_FILAMENT_SCORE_REQUEST',
    GET_FILAMENT_SCORE_SUCCESS: 'GET_FILAMENT_SCORE_SUCCESS',
    GET_FILAMENT_SCORE_FAILURE: 'GET_FILAMENT_SCORE_FAILURE',

    GET_EXPERT_DETAILS_LIST_REQUEST: 'GET_EXPERT_DETAILS_LIST_REQUEST',
    GET_EXPERT_DETAILS_LIST_SUCCESS: 'GET_EXPERT_DETAILS_LIST_SUCCESS',
    GET_EXPERT_DETAILS_LIST_FAILURE: 'GET_EXPERT_DETAILS_LIST_FAILURE',

    GET_ASSESSMENT_PACKAGE_BY_ID_REQUEST: 'GET_ASSESSMENT_PACKAGE_BY_ID_REQUEST',
    GET_ASSESSMENT_PACKAGE_BY_ID_SUCCESS: 'GET_ASSESSMENT_PACKAGE_BY_ID_SUCCESS',
    GET_ASSESSMENT_PACKAGE_BY_ID_FAILURE: 'GET_ASSESSMENT_PACKAGE_BY_ID_FAILURE',


    ASSESSMENT_ADD_TO_CART_REQUEST: 'ASSESSMENT_ADD_TO_CART_REQUEST',
    ASSESSMENT_ADD_TO_CART_SUCCESS: 'ASSESSMENT_ADD_TO_CART_SUCCESS',
    ASSESSMENT_ADD_TO_CART_FAILURE: 'ASSESSMENT_ADD_TO_CART_FAILURE',


    GET_ASSESSMENT_CART_ITEMS_REQUEST: 'GET_ASSESSMENT_CART_ITEMS_REQUEST',
    GET_ASSESSMENT_CART_ITEMS_SUCCESS: 'GET_ASSESSMENT_CART_ITEMS_SUCCESS',
    GET_ASSESSMENT_CART_ITEMS_FAILURE: 'GET_ASSESSMENT_CART_ITEMS_FAILURE',

    UPDATE_ASSESSMENT_CART_ITEMS_REQUEST: 'UPDATE_ASSESSMENT_CART_ITEMS_REQUEST',
    UPDATE_ASSESSMENT_CART_ITEMS_SUCCESS: 'UPDATE_ASSESSMENT_CART_ITEMS_SUCCESS',
    UPDATE_ASSESSMENT_CART_ITEMS_FAILURE: 'UPDATE_ASSESSMENT_CART_ITEMS_FAILURE',


    GET_ASSESSMENT_CART_PRICE_TOTAL_REQUEST: 'GET_ASSESSMENT_CART_PRICE_TOTAL_REQUEST',
    GET_ASSESSMENT_CART_PRICE_TOTAL_SUCCESS: 'GET_ASSESSMENT_CART_PRICE_TOTAL_SUCCESS',
    GET_ASSESSMENT_CART_PRICE_TOTAL_FAILURE: 'GET_ASSESSMENT_CART_PRICE_TOTAL_FAILURE',

    DELETE_ASSESSMENT_CART_ITEM_REQUEST: 'DELETE_ASSESSMENT_CART_ITEM_REQUEST',
    DELETE_ASSESSMENT_CART_ITEM_SUCCESS: 'DELETE_ASSESSMENT_CART_ITEM_SUCCESS',
    DELETE_ASSESSMENT_CART_ITEM_FAILURE: 'DELETE_ASSESSMENT_CART_ITEM_FAILURE',


    GET_ORDER_BOOK_LIST_REQUEST: 'GET_ORDER_BOOK_LIST_REQUEST',
    GET_ORDER_BOOK_LIST_SUCCESS: 'GET_ORDER_BOOK_LIST_SUCCESS',
    GET_ORDER_BOOK_LIST_FAILURE: 'GET_ORDER_BOOK_LIST_FAILURE',

    USER_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST: 'USER_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST',
    USER_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS: 'USER_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS',
    USER_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE: 'USER_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE',

    USER_ASSESSMENT_ORDER_NAME_UPDATE_REQUEST: 'USER_ASSESSMENT_ORDER_NAME_UPDATE_REQUEST',
    USER_ASSESSMENT_ORDER_NAME_UPDATE_SUCCESS: 'USER_ASSESSMENT_ORDER_NAME_UPDATE_SUCCESS',
    USER_ASSESSMENT_ORDER_NAME_UPDATE_FAILURE: 'USER_ASSESSMENT_ORDER_NAME_UPDATE_FAILURE',

    GET_ASSESSMENT_CART_COUNT_REQUEST: 'GET_ASSESSMENT_CART_COUNT_REQUEST',
    GET_ASSESSMENT_CART_COUNT_SUCCESS: 'GET_ASSESSMENT_CART_COUNT_SUCCESS',
    GET_ASSESSMENT_CART_COUNT_FAILURE: 'GET_ASSESSMENT_CART_COUNT_FAILURE',

    SAVE_ASSESSMENT_CONTACT_US_REQUEST: 'SAVE_ASSESSMENT_CONTACT_US_REQUEST',
    SAVE_ASSESSMENT_CONTACT_US_SUCCESS: 'SAVE_ASSESSMENT_CONTACT_US_SUCCESS',
    SAVE_ASSESSMENT_CONTACT_US_FAILURE: 'SAVE_ASSESSMENT_CONTACT_US_FAILURE',

    UPDATE_ASSESSMENT_USER_PROFILE_REQUEST: 'UPDATE_ASSESSMENT_USER_PROFILE_REQUEST',
    UPDATE_ASSESSMENT_USER_PROFILE_SUCCESS: 'UPDATE_ASSESSMENT_USER_PROFILE_SUCCESS',
    UPDATE_ASSESSMENT_USER_PROFILE_FAILURE: 'UPDATE_ASSESSMENT_USER_PROFILE_FAILURE',


    ASSESSMENT_DOWNLOAD_STATUS_REQUEST: 'ASSESSMENT_DOWNLOAD_STATUS_REQUEST',
    ASSESSMENT_DOWNLOAD_STATUS_SUCCESS: 'ASSESSMENT_DOWNLOAD_STATUS_SUCCESS',
    ASSESSMENT_DOWNLOAD_STATUS_FAILURE: 'ASSESSMENT_DOWNLOAD_STATUS_FAILURE',


    ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_REQUEST: 'ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_REQUEST',
    ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_SUCCESS: 'ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_SUCCESS',
    ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_FAILURE: 'ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_FAILURE',


    USER_ASSESSMENT_LOGOUT_REQUEST: 'USER_ASSESSMENT_LOGOUT_REQUEST',
    USER_ASSESSMENT_LOGOUT_SUCCESS: 'USER_ASSESSMENT_LOGOUT_SUCCESS',
    USER_ASSESSMENT_LOGOUT_FAILURE: 'USER_ASSESSMENT_LOGOUT_FAILURE',

    USER_PLACE_PAYMENT_ORDER_REQUEST: 'USER_PLACE_PAYMENT_ORDER_REQUEST',
    USER_PLACE_PAYMENT_ORDER_SUCCESS: 'USER_PLACE_PAYMENT_ORDER_SUCCESS',
    USER_PLACE_PAYMENT_ORDER_FAILURE: 'USER_PLACE_PAYMENT_ORDER_FAILURE',

    USER_ASSESSMENT_PACKAGE_ORDER_PLACE_REQUEST: 'USER_ASSESSMENT_PACKAGE_ORDER_PLACE_REQUEST',
    USER_ASSESSMENT_PACKAGE_ORDER_PLACE_SUCCESS: 'USER_ASSESSMENT_PACKAGE_ORDER_PLACE_SUCCESS',
    USER_ASSESSMENT_PACKAGE_ORDER_PLACE_FAILURE: 'USER_ASSESSMENT_PACKAGE_ORDER_PLACE_FAILURE',

    ASSESSMENT_CART_BY_PRE_ORDER_ID_REQUEST: 'ASSESSMENT_CART_BY_PRE_ORDER_ID_REQUEST',
    ASSESSMENT_CART_BY_PRE_ORDER_ID_SUCCESS: 'ASSESSMENT_CART_BY_PRE_ORDER_ID_SUCCESS',
    ASSESSMENT_CART_BY_PRE_ORDER_ID_FAILURE: 'ASSESSMENT_CART_BY_PRE_ORDER_ID_FAILURE',

    GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_REQUEST: 'GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_REQUEST',
    GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_SUCCESS: 'GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_SUCCESS',
    GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_FAILURE: 'GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_FAILURE',

    CREATE_SUCCESS_ORDER_BOOK_REQUEST: 'CREATE_SUCCESS_ORDER_BOOK_REQUEST',
    CREATE_SUCCESS_ORDER_BOOK_SUCCESS: 'CREATE_SUCCESS_ORDER_BOOK_SUCCESS',
    CREATE_SUCCESS_ORDER_BOOK_FAILURE: 'CREATE_SUCCESS_ORDER_BOOK_FAILURE',

    USER_EXPERT_ORDER_PLACE_REQUEST: 'USER_EXPERT_ORDER_PLACE_REQUEST',
    USER_EXPERT_ORDER_PLACE_SUCCESS: 'USER_EXPERT_ORDER_PLACE_SUCCESS',
    USER_EXPERT_ORDER_PLACE_FAILURE: 'USER_EXPERT_ORDER_PLACE_FAILURE',

    EXPERT_BOOKING_SUCCESS_REQUEST: 'EXPERT_BOOKING_SUCCESS_REQUEST',
    EXPERT_BOOKING_SUCCESS_SUCCESS: 'EXPERT_BOOKING_SUCCESS_SUCCESS',
    EXPERT_BOOKING_SUCCESS_FAILURE: 'EXPERT_BOOKING_SUCCESS_FAILURE',

    EXPERT_ORDER_BOOKID_REQUEST: 'EXPERT_ORDER_BOOKID_REQUEST',
    EXPERT_ORDER_BOOKID_SUCCESS: 'EXPERT_ORDER_BOOKID_SUCCESS',
    EXPERT_ORDER_BOOKID_FAILURE: 'EXPERT_ORDER_BOOKID_FAILURE',

    EXPERT_ORDER_BOOK_REQUEST: 'EXPERT_ORDER_BOOK_REQUEST',
    EXPERT_ORDER_BOOK_SUCCESS: 'EXPERT_ORDER_BOOK_SUCCESS',
    EXPERT_ORDER_BOOK_FAILURE: 'EXPERT_ORDER_BOOK_FAILURE',


    SUBMIT_RATING_EXPERT_REQUEST: 'SUBMIT_RATING_EXPERT_REQUEST',
    SUBMIT_RATING_EXPERT_SUCCESS: 'SUBMIT_RATING_EXPERT_SUCCESS',
    SUBMIT_RATING_EXPERT_FAILURE: 'SUBMIT_RATING_EXPERT_FAILURE',

    GET_TALENT_RESOURCES_REQUEST: 'GET_TALENT_RESOURCES_REQUEST',
    GET_TALENT_RESOURCES_SUCCESS: 'GET_TALENT_RESOURCES_SUCCESS',
    GET_TALENT_RESOURCES_FAILURE: 'GET_TALENT_RESOURCES_FAILURE',

    GET_TALENT_BLOG_REQUEST: 'GET_TALENT_BLOG_REQUEST',
    GET_TALENT_BLOG_SUCCESS: 'GET_TALENT_BLOG_SUCCESS',
    GET_TALENT_BLOG_FAILURE: 'GET_TALENT_BLOG_FAILURE',

    GET_TEST_MODULE_REQUEST: 'GET_TEST_MODULE_REQUEST',
    GET_TEST_MODULE_SUCCESS: 'GET_TEST_MODULE_SUCCESS',
    GET_TEST_MODULE_FAILURE: 'GET_TEST_MODULE_FAILURE',

    GET_CAREER_SURVEY_LIST_REQUEST: 'GET_CAREER_SURVEY_LIST_REQUEST',
    GET_CAREER_SURVEY_LIST_SUCCESS: 'GET_CAREER_SURVEY_LIST_SUCCESS',
    GET_CAREER_SURVEY_LIST_FAILURE: 'GET_CAREER_SURVEY_LIST_FAILURE',

    GET_PERSONALITY_INDEX_REQUEST: 'GET_PERSONALITY_INDEX_REQUEST',
    GET_PERSONALITY_INDEX_SUCCESS: 'GET_PERSONALITY_INDEX_SUCCESS',
    GET_PERSONALITY_INDEX_FAILURE: 'GET_PERSONALITY_INDEX_FAILURE',

    GET_WORKER_VALUE_DSCORE_REQUEST: 'GET_WORKER_VALUE_DSCORE_REQUEST',
    GET_WORKER_VALUE_DSCORE_SUCCESS: 'GET_WORKER_VALUE_DSCORE_SUCCESS',
    GET_WORKER_VALUE_DSCORE_FAILURE: 'GET_WORKER_VALUE_DSCORE_FAILURE',

    GET_INTEREST_DSCORE_REQUEST: 'GET_INTEREST_DSCORE_REQUEST',
    GET_INTEREST_DSCORE_SUCCESS: 'GET_INTEREST_DSCORE_SUCCESS',
    GET_INTEREST_DSCORE_FAILURE: 'GET_INTEREST_DSCORE_FAILURE',

    GET_TEST_SURVEY_QUESTION_REQUEST: 'GET_TEST_SURVEY_QUESTION_REQUEST',
    GET_TEST_SURVEY_QUESTION_SUCCESS: 'GET_TEST_SURVEY_QUESTION_SUCCESS',
    GET_TEST_SURVEY_QUESTION_FAILURE: 'GET_TEST_SURVEY_QUESTION_FAILURE',
};


