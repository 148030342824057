import React from 'react';

export default function SurveySubmitModel(props) {

  let { onClose, onSubmitConfirm, categoryQuestions, crrTestDetails } = props;

  return (
    <>
      <div className="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-screen p-6 bg-black/60 lg:p-0">
        <div className="bg-white md:w-[50rem] w-full rounded-lg">
          <div className="flex items-center justify-between px-6 py-4 border-b">
            <h2 className="text-xl font-semibold text-[#212121]">Assessment Summary </h2>
            <button onClick={() => onClose()} className='p-2 rounded-full bg-[#F1F1F1]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
            </button>
          </div>

          <div className="p-6 space-y-4 overflow-y-auto h-80">
            <h2 className="text-lg font-semibold text-[#212121]">{crrTestDetails && crrTestDetails.name} </h2>
            <div>
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="border-b-2 border-white">
                    <th className="p-3 font-medium bg-[#DBEEFD] text-[#212121] border-r-2 border-white">Section Name</th>
                    <th className="p-3 font-medium bg-[#DBEEFD] text-[#212121] border-r-2 border-white">No. of Questions</th>
                    <th className="p-3 font-medium bg-[#DBEEFD] text-[#212121] border-r-2 border-white">Answered</th>
                    <th className="p-3 font-medium bg-[#DBEEFD] text-[#212121]">Not Answered</th>
                  </tr>
                </thead>
                <tbody className="divide-y-2 divide-white ">
                  {categoryQuestions && categoryQuestions.length > 0 ?
                    categoryQuestions.map((ele,indexx) =>
                      <tr>
                        <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">{crrTestDetails && crrTestDetails.name} Part {indexx + 1}</th>
                        <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">{ele.list.length}</th>
                        <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">{ele.list.filter(prev => prev.isComplete === true).length}</th>
                        <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121]">{ele.list.filter(prev => prev.isComplete === false).length}</th>
                      </tr>
                    ) : null}
                  {/* <tr>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">Interest Part 1</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">15</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">13</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121]">2</th>
                  </tr>
                  <tr>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">Interest Part 2</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">15</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">13</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121]">2</th>
                  </tr>
                  <tr>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">Interest Part 3</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">15</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">13</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121]">2</th>
                  </tr>
                  <tr>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">Interest Part 4</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">15</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121] border-r-2 border-white">13</th>
                    <th className="p-3 font-normal bg-[#f5f5f5] text-[#212121]">2</th>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>


          <div className='flex justify-end px-6 py-4 space-x-4 border-t itmes-center '>
            <button onClick={() => onSubmitConfirm()} className="px-10 uppercase py-3  text-[#fff] bg-[#2196F3] font-medium rounded-md">Final Submit</button>
          </div>

        </div>
      </div>
    </>
  );
};

