import React from 'react';

export default function Component(props) {

const { question, answersList,surveyLanguageCode, handleRadio, clicked, surveyScreenData } = props;



return(
    <>
<div className="divide-y text-center text-blue-500 capitalize text-sm">
  {question && question.optionsList && question.optionsList.length>0?
    question.optionsList.map((option, optionindex)=>
<>

     <span onClick={() => handleRadio(optionindex, question, option)} style={{
              color:(answersList.some(item => option.id === item.questionOptionId)) ?(surveyScreenData && surveyScreenData.OptionColor3) ? surveyScreenData.OptionColor3 :null:null
            }} className="p-2 w-full block cursor-pointer hover:bg-blue-50"> {option.name}</span>      
</>

    ):null}
      </div>



    </>
  );}