import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assessmentActions } from "../../../../_actions";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PageTemplate from './PageTemplate';
import AssessmentScore from './Components/AssessmentScore';

class AssessmentReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      crrSelectedEmp: ""
    }
  }

  componentDidMount() {


  }

  exportPDFWithComponent = () => {
    let { handleDownloadStatus } = this.props;
    handleDownloadStatus("Summary Report");
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };


  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { onAssessmentByEmpID } = this.props;
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      onAssessmentByEmpID(emp_id)

    })
  }

  render() {

    let { } = this.state;
    let { loading, EditDetailsFn, EditButtonFn, createdAt, getAssessmenrEmployeeDetailsData,
      getCareerFilamentScoreData, getCareerSurveyListData, getPersonalityIndexData,
      getWorkValueDimensionScoreData, getInterestDimensionScoreData
    } = this.props;

    // console.log('getCareerSurveyListData111111', getCareerSurveyListData);
    // console.log('getPersonalityIndexData222222222222', getPersonalityIndexData);

    // getAssessmenrEmployeeDetailsData["name"] = "Temp001"

    console.log(" Teen Career Guidance---->")

    return (


      // <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-black bg-opacity-60" >
      //   <div className="flex items-start justify-center h-screen p-4 overflow-hidden">

      //     <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-3/6 xl:w-4/6 lg:w-5/6 customscroll5" style={{
      //       height:
      //         getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ? 'calc(100vh - 4rem)' : '300px'
      //     }} >

      //       <div className="sticky top-0 z-20 flex flex-col items-center justify-between p-4 px-6 bg-white border-b md:flex-row"  >
      //         <h1 className="font-semibold md:text-xl text-lg line-clamp-1 text-[#3D405B]">
      //           Top 5 TalentPlusStrengths11111111
      //         </h1>


      <div className="flex items-start justify-center mt-6 overflow-hidden">

        <div className="w-full mx-auto  bg-white rounded-md ">

          <div className="sticky top-0 z-10 flex flex-col items-center justify-between gap-4 p-4 px-6 bg-white border-b md:flex-row">
            <h1 className="font-semibold md:text-xl text-lg line-clamp-1 text-[#3D405B]">Teen Career Guidance</h1>

            <div className='flex items-center space-x-4'>

              {/* {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ? */}
              <button
                onClick={() => this.exportPDFWithComponent()}
                className="w-full  uppercase text-sm font-medium rounded-md  inline-flex  items-center justify-center px-2 py-2 cursor-pointer  
                    bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden  "
              >
                <span className="material-symbols-outlined mr-2 text-[1.5rem]">
                  file_download
                </span>
                <span className="transition-all duration-300 ease-out bg-white rounded-full opacity-10"></span>
                {EditButtonFn('DownloadReport', "Download Report")}
              </button>
              {/* : null} */}


              {/* <div onClick={() => closeReportModal(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div> */}

            </div>
          </div>
          {true ?
            <div>
              <PDFExport ref={this.pdfExportComponent}
                pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} />}
                scale={0.6}
                forcePageBreak=".page-break"
                paperSize="A4"
                margin={0}
                font='Poppins'
                fileName={'Assessment Report' + (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name ?
                  ' - ' + getAssessmenrEmployeeDetailsData.name : "")}
                author="Happyplus Team"
                landscape={false}
              >


                <AssessmentScore
                  crrSelectedEmp={getAssessmenrEmployeeDetailsData}

                  createdAt={createdAt}
                  EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}

                  getCareerSurveyListData={getCareerSurveyListData}
                  getPersonalityIndexData={getPersonalityIndexData}
                  getWorkValueDimensionScoreData={getWorkValueDimensionScoreData}
                  getInterestDimensionScoreData={getInterestDimensionScoreData}
                  getCareerFilamentScoreData={getCareerFilamentScoreData}
                />


              </PDFExport>
            </div>
            :
            <>
              {loading ?
                <div className='py-24 text-xl font-semibold text-center text-gray-500'>{"Please wait while report is generating..."}</div>
                :
                <div className='py-24 text-xl font-semibold text-center text-gray-500'>{"Report is not generated yet"}</div>
              }
            </>}

        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, assessment } = state;
  return {
    loggingIn,
    users,
    dashboard,
    assessment
  };
}
export default connect(mapStateToProps)(AssessmentReport);
