// import React from 'react'
// import  { Redirect } from 'react-router-dom'
// return <Redirect to='/login'  />
import CryptoJS from 'crypto-js';

export function authHeader() {
    // return authorization header with jwt token


    let localData = localStorage.getItem('adminuser');
    let cryptedText = localData?JSON.parse(localData):"";

    let bytes = cryptedText?CryptoJS.AES.decrypt(cryptedText, "secretkey1345100237401"):"";
    let user = "";

    try {
        user = bytes?JSON.parse(bytes.toString(CryptoJS.enc.Utf8)):"";
      } catch(error) {
        user = ""
      }
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}



export function authHeaderUser() {
    // return authorization header with jwt token

    let localData = localStorage.getItem('surveyuser');
    let cryptedText = localData?JSON.parse(localData):"";

    let bytes = cryptedText?CryptoJS.AES.decrypt(cryptedText, "secretkey1345100237401"):"";
    let user = "";

    try {
        user = bytes?JSON.parse(bytes.toString(CryptoJS.enc.Utf8)):"";
      } catch(error) {
        user = ""
      }
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}