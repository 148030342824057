// import { dataTool } from "echarts";
import React from "react";
export default function CompleteModal(props) {
  let { closeModal, question, handleFeedbackInput, answersList,submitFeedback, surveyScreenData, afterSubmitData } = props;
  
  function matchFeedback(answersList1,question1){
    let value = "";
    if(answersList1 && answersList1.length>0){
      let getIndex1 = answersList1.findIndex(item => item.questionId === question1.id);
      if(getIndex1!==-1){
        value = answersList1 && answersList1[getIndex1] && answersList1[getIndex1].feedback?answersList1[getIndex1].feedback:"";
      }
    }
    return value
  }

  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
          <div onClick={()=>closeModal(false)} className="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          <div className="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8">
            <h2 className="font-bold text-xl  mb-2 text-gray-800  text-center pb-4">Give Feedback</h2>
            {/* <p className=" text-base mb-6 text-gray-700 border-b pb-2 text-center"></p> */}

            {/* <label>Answer Feedback:-</label> */}
            <div className="mb-2">
              <textarea rows={5} type="text" name="remark" value={matchFeedback(answersList,question)} 
               onChange={(event)=>handleFeedbackInput(event,question)} 
               placeholder="Write Feedback.." className="w-full bg-gray-100 rounded-md mt-4 p-3 text-sm  placeholder-gray-500 outline-none" >
              </textarea>
            </div>

            <div className="mt-8 text-center">
              <button onClick={()=>submitFeedback()} type="button" 
              className="bg-white shadow-lg text-green-700 font-bold text-lg py-2 px-16 tracking-wider rounded-lg text-green-1 hover:bg-gray-100"
              style={{
                background:(surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 : (afterSubmitData.buttonColor1)?afterSubmitData.buttonColor1: "#757575",
                color:(surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : (afterSubmitData.buttonColor2)?afterSubmitData.buttonColor2: "#757575",
              }} 
               >
                Submit Feedback
              </button> 
            </div>
          </div>
        </div>
        </div>
   </>
  );
}
