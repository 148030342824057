import React from 'react';

export default function Component(props) {

const { question, answersList, handleDropdownSelect, handleRadio } = props;

let [showDropdownQues, openDropdownQuestion] = React.useState(false);

return(
    <>
      <input 
        type="text"
        value={
          (answersList && answersList.length > 0 && answersList.findIndex(item => item.questionId === question.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question.id)].answer?answersList[answersList.findIndex(item => item.questionId === question.id)].answer:"Select Item"
            : "Select Item"
          } 
        onChange={()=>{}} 
        onClick={()=>openDropdownQuestion(!showDropdownQues)}
        className="w-full bg-gray-100 rounded-md mt-4 p-3 text-sm  placeholder-gray-500 cursor-pointer"  />

      {showDropdownQues?
        <div className="absolute justify-center items-center bg-white shadow-lg  font-normal font-xs w-96">
          {question && question.optionsList && question.optionsList.length>0?
          question.optionsList.map((option, optionindex) =>
          <div onClick={()=>{
            handleDropdownSelect(option.name);
            openDropdownQuestion(!showDropdownQues);
            handleRadio(optionindex, question, option); 
          }} className="py-2 px-2 flex items-center text-xs hover:bg-blue-800 hover:text-white cursor-pointer">
            {option.name}
          </div>
          ):null}
        </div>
      :null}
    </>
  );}