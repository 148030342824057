import React from 'react';
export default function LanguageComponent(props) {

const { surveyLanguageName, handleLanguage, langList, surveyLanguageRTL , template} = props;

let [showDrop, openLangModal ] = React.useState(false);

return(<>
{surveyLanguageRTL?
  <div dir="rtl" lang="ar" className="flex justify-between">

<div/>

<div className="lg:flex  border-r pr-3 m-4 items-center "><h1 onClick={() => openLangModal(!showDrop)} 
className="text-sm cursor-pointer">{surveyLanguageName}</h1>
<span onClick={() => openLangModal(!showDrop)} 
className="cursor-pointer material-icons">arrow_drop_down</span>

{showDrop ?
  <div className={"absolute "+(template==='v4' || 'v2' ?" top-10 ":" top-10 ")+"  z-10 left-10 bg-white shadow-xl w-auto  py-1.5  lang"} style={{ maxHeight: "150px", overflowY: 'auto' }}>
    {langList && langList.length > 0 ?
      langList.map((item) =>
      (surveyLanguageName !==item.name)?
        <span onClick={() => {
          handleLanguage(item)
          openLangModal(!showDrop)
        }} 
        className="cursor-pointer block text-xs w-full hover:bg-gray-100 py-1.5 px-4">{item.name}</span>
      :null
      ): null}
  </div>
  : null}

</div>


</div>
:
<div className="flex justify-between">

<div/>

<div className={template==="v2"?"lg:flex  border-r pr-3 m-4 items-center w-24 p-2 border border-gray-500 rounded-lg":
"lg:flex  border-r pr-3 m-4 items-center w-24  rounded-lg" }><h1 onClick={() => openLangModal(!showDrop)} 
className="text-sm cursor-pointer">{surveyLanguageName}</h1>
<span onClick={() => openLangModal(!showDrop)} 
className="cursor-pointer material-icons">arrow_drop_down</span>


{showDrop ?
<div className={"absolute "+(template==='v4' || 'v2' ?" top-28 ":" top-12 ")+" right-2 bg-white text-xs w-24 py-2 shadow-lg rounded-sm text-gray-700"} >
{langList && langList.length > 0 ?
      langList.map((item) =>
      (surveyLanguageName !==item.name)?
      <span onClick={() => {
        handleLanguage(item)
        openLangModal(!showDrop)
      }} className="block px-4 py-1 hover:bg-gray-100">{item.name}</span>
      :null
      ): null}
    </div>
: null}


</div>


</div>

}

</>
  );}