import React from 'react';

export default function Component(props) {

const { question, answersList,surveyLanguageCode, handleRadio, clicked, surveyScreenData  } = props;

return(
    <>
          <div className="mt-4 flex items-center">
            {question && question.optionsList && question.optionsList.length>0?
              question.optionsList.map((option, optionindex) =>
                <div className="flex items-center text-gray-700 font-medium mx-0"> 
                  <input type="radio" 
                  onClick={() => this.handleRadio(optionindex, question, option)} 
                  onChange={()=>{}}
                  className="rounded-full w-4 h-5 rounded-full mx-4 cursor-pointer" 
                  checked={(answersList.some(item => option.id === item.questionOptionId)) || (clicked === option.id) ?true:false}
                  /> 
                  <label>{(surveyLanguageCode!=='')?
                    (question && question.transl && question.transl[surveyLanguageCode] && question.transl[surveyLanguageCode].optionsList && question.transl[surveyLanguageCode].optionsList[option.id])?
                        question.transl[surveyLanguageCode].optionsList[option.id]:option.name
                    
                    :option.name} 
                  </label>
                </div>
            ):null}
          </div>
    </>
  );}