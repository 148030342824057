import React from "react";
import PieChart from "../Chart/PieChart";
import SpeedMeter from "../Chart/SpeedMeter";
import PieChartWorkValue from "../Chart/PieChartWorkValue";
import Top5Content from "./Top5Content";
import PersonalitySection from "./PersonalitySection";

// import moment from "moment";
// import { CONST } from "../../../../../_config";

export default function AssessmentScore(props) {
  let {
    crrSelectedEmp,
    EditButtonFn,
    createdAt,
    getPersonalityIndexData,
    getWorkValueDimensionScoreData,
    getInterestDimensionScoreData,
    getCareerFilamentScoreData
  } = props;

  // console.log('getCareerSurveyListDatagetCareerSurveyListDatagetCareerSurveyListDatagetCareerSurveyListData111111', getCareerSurveyListData);
  // console.log('getPersonalityIndexDatagetPersonalityIndexDatagetPersonalityIndexData222222222222', getPersonalityIndexData);

  const personalityTypes = [
    {
      label: "R - REALISTIC",
      title: "Realistic",
      description: "Realistic types prefer hands-on activities that often involve physical work, machinery, or working outdoors. They are practical, physically active, and enjoy building or repairing things.",
      imgSrc: "./assessment_images/realistic.png"
    },
    {
      label: "I - INVESTIGATIVE",
      title: "Investigative",
      description: "Investigative types enjoy working with ideas and solving complex problems. They often involve research, analysis, and understanding systems. They are curious, analytical, and like to work independently.",
      imgSrc: "/assessment_images/Investigative.png"
    },
    {
      label: "A - ARTISTIC",
      title: "Artistic",
      description: "Artistic types are creative and prefer work that allows for self-expression and originality. They enjoy creating visual or performing arts and generally value aesthetics and innovation.",
      imgSrc: "/assessment_images/Artistic.png"
    },
    {
      label: "S - SOCIAL",
      title: "Social",
      description: "Social types are people-oriented and enjoy helping, teaching, or providing services to others. They have strong communication skills and often work in collaborative environments.",
      imgSrc: "/assessment_images/Social.png"
    },
    {
      label: "E - ENTERPRISING",
      title: "Enterprising",
      description: "Enterprising types enjoy leadership, influencing others, and taking risks. They are often drawn to business, management, and roles that involve persuasion and negotiation.",
      imgSrc: "/assessment_images/Enterprising.png"
    },
    {
      label: "C - CONVENTIONAL",
      title: "Conventional",
      description: "Conventional types prefer structured, organized, and detail-oriented work. They enjoy following established procedures and often work with data, administration, or clerical tasks.",
      imgSrc: "/assessment_images/Conventional.png"
    }
  ];


  // const workValueProfile = [
  //   {
  //     title: "R Realistic ",
  //     description: "The Realistic type prefers hands-on activities, working with tools or machines, and engaging in physical tasks. Teens with Realistic traits might be interested in careers like engineering, mechanics, construction, or agriculture. They often enjoy practical tasks and are comfortable with manual labor, machinery, and outdoor work.",
  //     imgSrc: "./assessment_images/realistic.png"
  //   },
  //   {
  //     title: "I Investigative ",
  //     description: "The Investigative type is marked by a curiosity for exploring, researching, and solving complex problems. Teens with Investigative traits enjoy analytical thinking, science, and technology. They might be drawn to careers in research, medicine, engineering, or computer science, where they can investigate and analyze data or solve intricate problems.",
  //     imgSrc: "./assessment_images/investigative.png"
  //   },
  //   {
  //     title: "E Enterprising ",
  //     description: "The Enterprising type is associated with leadership, entrepreneurship, and persuasion. Teens with Enterprising traits typically enjoy roles that involve leading teams, making decisions, or selling products or ideas. They might be interested in careers in business, marketing, management, or politics, where they can use their communication skills to influence and motivate others.",
  //     imgSrc: "./assessment_images/enterprising.png"
  //   }
  // ];

  // const YourWorkValueProfile = [
  //   {
  //     title: "Support",
  //     description: "Reflects the level of mentorship, guidance, and assistance teens value in a work environment.",
  //     imgSrc: "./assessment_images/support.png"
  //   },
  //   {
  //     title: "Autonomy",
  //     description: "The degree of independence and flexibility teens desire in their job roles to make their decisions and take ownership of their work.",
  //     imgSrc: "./assessment_images/autonomy.png"
  //   },
  //   {
  //     title: "Security",
  //     description: "Relates to the preference for job stability and long-term employment prospects.",
  //     imgSrc: "./assessment_images/security.png"
  //   }
  // ];

  const workValue = [
    {
      imgSrc: "./assessment_images/Support.png",
      title: "Support",
      description: "Reflects the level of mentorship, guidance, and assistance teens value in a work environment."
    },
    {
      imgSrc: "./assessment_images/relationship.png",
      title: "Relationships",
      description: "Represents the importance of teamwork, camaraderie, and positive interactions in the workplace."
    },
    {
      imgSrc: "./assessment_images/Autonomy.png",
      title: "Autonomy",
      description: "The degree of independence and flexibility teens desire in their job roles to make their decisions and take ownership of their work."
    },
    {
      imgSrc: "./assessment_images/Recognition.png",
      title: "Recognition",
      description: "Captures the value placed on acknowledgment and appreciation for accomplishments at work."
    },
    {
      imgSrc: "./assessment_images/work-life-balance.png",
      title: "Work-Life Balance",
      description: "Signifies the importance of maintaining a healthy balance between work and personal life."
    },
    {
      imgSrc: "./assessment_images/compensation-and-benefits.png",
      title: "Compensation and Benefits",
      description: "Reflects the emphasis on competitive pay and comprehensive benefits packages."
    },
    {
      imgSrc: "./assessment_images/Security.png",
      title: "Security",
      description: "Relates to the preference for job stability and long-term employment prospects."
    },
    {
      imgSrc: "./assessment_images/creativity-and-innovation.png",
      title: "Creativity and Innovation",
      description: "Represents the value of creativity, innovation, and new ideas in a career."
    },
    {
      imgSrc: "./assessment_images/contribution-and-impact.png",
      title: "Contribution and Impact",
      description: "Indicates the importance of work that makes a positive societal impact."
    },
    {
      imgSrc: "./assessment_images/work-environment.png",
      title: "Work Environment",
      description: "Refers to the desired workplace atmosphere, including diversity, inclusivity, and organizational culture."
    }
  ];

  const personalityTest = [
    {
      imgSrc: "./assessment_images/learning-beliefs.png",
      title: "Learning Beliefs",
      description: "Focuses on teens' attitudes toward learning and education, impacting their openness to acquiring new skills and knowledge in their career journey."
    },
    {
      imgSrc: "./assessment_images/interpersonal-beliefs.png",
      title: "Interpersonal Beliefs",
      description: "Relates to teens' perceptions of their social interactions and relationships, influencing their ability to work collaboratively and build networks in their careers."
    },
    {
      imgSrc: "./assessment_images/emotional-beliefs.png",
      title: "Emotional Beliefs",
      description: "Involves teens' understanding of emotions and stress management, guiding how they handle workplace challenges and maintain emotional well-being."
    },
    {
      imgSrc: "./assessment_images/attitudinal-beliefs.png",
      title: "Attitudinal Beliefs",
      description: "Concerns teens' attitudes and mindset towards work, impacting their motivation, resilience, and approach to career opportunities"
    },
    {
      imgSrc: "./assessment_images/conscientious-beliefs.png",
      title: "Conscientious Beliefs",
      description: "Addresses teens' sense of responsibility, discipline, and work ethic, affecting their reliability and commitment to career goals."
    }
  ];

  const worldOfCareers = [
    { name: "Agricultural & Food Sciences" },
    { name: "Medical Sciences" },
    { name: "Humanities, Liberal Arts & Social Sciences" },
    { name: "Allied & Para Medical Sciences" },
    { name: "Education & Teaching" },
    { name: "Fitness & Well-Being" },
    { name: "Mass Communication" },
    { name: "Engineering & Technology" },
    { name: "Journalism" },
    { name: "Architecture & Planning" },
    { name: "Performing Arts" },
    { name: "Science & Mathematics" },
    { name: "Art & Design" },
    { name: "Information Technology & Computer Science" },
    { name: "Animation, Graphics & Visual Communication" },
    { name: "Legal Services" },
    { name: "Hospitality, Tourism & Transport Services" },
    { name: "Business Management" },
    { name: "Government & Defence Services" },
    { name: "Sales & Marketing" },
  ];

  // const careerFitment = [
  //   { category: "Hospitality, Tourism & Transport Services", percentage: "52%" },
  //   { category: "Architecture & Planning", percentage: "52%" },
  //   { category: "Fitness & Well-Being", percentage: "52%" },
  //   { category: "Science & Mathematics", percentage: "52%" },
  //   { category: "Engineering & Technology", percentage: "52%" },
  //   { category: "Medical Sciences", percentage: "52%" },
  //   { category: "Allied & Para Medical Sciences", percentage: "52%" },
  //   { category: "Journalism", percentage: "52%" },
  //   { category: "Government & Defence Services", percentage: "52%" }
  // ];

  let careerFitment = []
  if (getCareerFilamentScoreData && getCareerFilamentScoreData.length > 0) {
    careerFitment = getCareerFilamentScoreData.map((item) => ({
      category: item.career,
      percentage: item.overallFitScore.toFixed(2) + "%",
      score: item.overallFitScore
    }))
    careerFitment.sort((b, a) => (a.score - b.score))
  }

  const personalityAptitude = [
    { category: "Hospitality, Tourism & Transport Services", value1: "70%", value2: "40%", },
    { category: "Architecture & Planning", value1: "60%", value2: "20%", },
    { category: "Fitness & Well-Being", value1: "48%", value2: "36%", },
    { category: "Science & Mathematics", value1: "30%", value2: "65%", },
  ];


  function getDetails(item, field) {
    let value = "";
    if (field === "name") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
    } else if (field === "label") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
    } else if (field === "rank") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
    } else if (field === "score") {
      let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;
      value = score.toFixed(1);
    } else if (field === "scorePerc") {
      let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;
      value = (parseInt((parseFloat(score) * 85) / 6)).toString();
      console.log('score--///--value----------->', score, " - ", value)
    }
    return value
  }


  function getXList(list, field) {

    let value = []
    if (field === "reorder") {
      if (list && list.length > 0) {
        value = list.sort((a, b) => getDetails(a, "rank") - getDetails(b, "rank"))
      }
    }

    return value
  }




  function getWorkValueDetails(item, field) {
    let value = "";
    if (field === "name") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
    } else if (field === "label") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
    } else if (field === "desc") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["desc"] ? item["parameter"][0]["desc"] : "";
    } else if (field === "rank") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
    } else if (field === "score") {
      let score = item && item["avg"] && item["avg"] ? item["avg"] : 0;

      value = parseFloat(score.toFixed(1));

    }
    return value
  }

  function getInterestDetails(item, field) {
    let value = "";
    if (field === "name") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["name"] ? item["parameter"][0]["name"] : "";
    } else if (field === "label") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["label"] ? item["parameter"][0]["label"] : "";
    } else if (field === "desc") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["desc"] ? item["parameter"][0]["desc"] : "";
    } else if (field === "rank") {
      value = item && item["parameter"] && item["parameter"][0] && item["parameter"][0]["rank"] ? item["parameter"][0]["rank"] : 0;
    } else if (field === "score") {
      let activeCount = item && item["dimension"] && item["dimension"][0] && item["dimension"][0]["activeCount"] && item["dimension"][0]["activeCount"][0] && item["dimension"][0]["activeCount"][0]["total"] ? item["dimension"][0]["activeCount"][0]["total"] : 0;
      let totalCount = item && item["dimension"] && item["dimension"][0] && item["dimension"][0]["totalCount"] && item["dimension"][0]["totalCount"][0] && item["dimension"][0]["totalCount"][0]["total"] ? item["dimension"][0]["totalCount"][0]["total"] : 0;

      // let score = (activeCount*100)/totalCount;
      let score = activeCount;

      value = parseFloat(score.toFixed(1));

    }
    return value
  }




  function sortX(item, field) {
    let ele = item && item["children"] && item["children"][0] && item["children"][0]["children"] && item["children"][0]["children"][0] && item["children"][0]["children"][0][field] ? item["children"][0]["children"][0][field] : 0
    return ele
  }




  // console.log('getWorkValueDimensionScoreData--///------------->', getWorkValueDimensionScoreData)


  // if(getWorkValueDimensionScoreData && getWorkValueDimensionScoreData.length>0){
  //   getWorkValueDimensionScoreData.forEach((ele)=>{
  //     let getIndex = workValue.findIndex(prev=>prev.title === ele["parameter"][0]["name"]);
  //     if(getIndex!==-1){
  //       workValue[getIndex]["description"] = ele["parameter"][0]["desc"]
  //     }
  //   })
  // }


  return (
    <>

      <main className="py-10 space-y-10 bg-white">
        <section className="flex flex-col items-center justify-between space-y-10 ">
          <div className="space-y-8 ">
            <div className="flex justify-center pt-2">
              <img src="/img/assessment/TalentPlusStrengths_logo.svg" className="w-56" />
            </div>
            <div className="w-full py-2 space-y-4 text-center">
              <h1 className="text-5xl text-[#212121] text-center">{EditButtonFn("page_1_title_1", "CAREER GUIDANCE PROGRAM")}
              </h1>
              <p className="text-2xl font-medium">STUDENT ASSESSMENT REPORT</p>
              <h1 className="text-lg font-bold text-[#212121]/85 italic">
                {crrSelectedEmp && crrSelectedEmp.name
                  ? crrSelectedEmp.name
                  : ""}{" "}
                | {createdAt}
              </h1>
            </div>
            <div className="flex justify-center pt-6">
              <img src="/assessment_images/TeenCareerTalentplusStrengths.png" className="w-full h-full mx-auto" />
            </div>
            <div className="space-y-2 text-center">
              <p className="text-xl text-[#212121] font-medium">
                {EditButtonFn(
                  "page_1_footer_text_1",
                  "Teen Career Assessments Copyright 2024"
                )}
              </p>
              <p className="text-base font-medium text-[#212121]/70 italic">
                {EditButtonFn(
                  "page_1_confidential_text_1",
                  "This report is confidential and should not be distributed without permission"
                )}
              </p>
            </div>
          </div>
        </section>
      </main>

      {/* WELCOME TO Teen TalentPlusStrengths section */}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-3xl font-bold uppercase">WELCOME TO Teen TalentPlusStrengths</p>
            <p className="text-lg text-[#212121]">YOUR PERSONAL CAREER GUIDE!</p>

          </div>
          <div className="space-y-4">
            <p className="text-[#212121]">


              Thank you for participating in the Teen Career Guidance Program
              <br />   <br />

              This report provides an in-depth analysis of your Aptitude, Interests, and Personality profile. We've used our Career Fitment Analytics system to identify the career paths best suited to you based on these results.
              <br />   <br />

              We recommend that you and your parents review the report before your one-on-one session with our experts. This session will help guide you through the educational and career planning process, ensuring you make informed decisions about your academic and career journey.
              <br />   <br />

              Thank you once again for being a part of our program, and we look forward to supporting you in achieving your career goals.
              <br />
              <br />
              <b> We wish you the very best,</b>
              <br />
              <b>Team TalentPlusStrengths.</b>
            </p>



          </div>

        </div>
      </main>

      {/* ABOUT THE REPORT section
       */}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-3xl font-bold uppercase">ABOUT THE REPORT</p>
          </div>
          <p className="text-[#212121]">This report provides a comprehensive analysis of your responses to the Career Guidance Program Assessment. Its purpose is to help you better understand your potential, personality, and career interests, thereby assisting you in aligning these attributes with your academic and career aspirations. The report also offers a variety of career recommendations tailored to your personal profile, opening a wide range of possibilities for you to explore.</p>
          <div>
            <p className="lg:text-lg text-base font-bold text-[#212121] uppercase ">Important Notes / Disclaimer:</p>
            <p className="font-bold">As you go through this report, keep the following in mind:</p>
          </div>
          <div>
            <ul className="px-6 pl-8 py-4 bg-[#f5f5f5] list-disc  ">
              <li>
                The information and insights provided are based solely on your responses to the Career Guidance
                Program Assessment.
              </li>
            </ul>
            <ul className="px-6 pl-8 py-4 bg-[#dfdfdf] list-disc  ">
              <li>
                The career recommendations in this report are intended to broaden your options, not limit them. Don't
                focus on finding the "perfect" career, but rather consider a variety of factors when narrowing down your
                choices.

              </li>
            </ul>
            <ul className="px-6 pl-8 py-4 bg-[#f5f5f5] list-disc  ">
              <li>This report represents a single source of evidence. Therefore, it is advisable to supplement it with
                additional data, such as academic grades, extracurricular achievements, and real-world experiences.
                The insights derived from this report should be used with caution.
              </li>
            </ul>
          </div>

          <div>
            <p className="lg:text-lg text-base font-bold text-[#212121] uppercase ">Key benefits of this report:</p>
          </div>
          <div>
            <div className="flex items-center space-x-4 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 13 14" fill="none">
                <path d="M0 6.86672C1.95098 8.97766 3.84262 10.878 5.66098 13.2816C7.63793 9.34953 9.66137 5.40383 13 1.13082L12.1004 0.71875C9.28129 3.70852 7.09105 6.53859 5.18793 9.90188C3.86449 8.70969 1.72566 7.02258 0.419727 6.15578L0 6.86672Z" fill="#4CAF50" />
              </svg>
              <div className="w-full px-6  py-4 bg-[#f5f5f5] ">
                It provides a better understanding of your abilities, personal characteristics, interests, and
                preferences.  </div>
            </div>
            <div className="flex items-center space-x-4 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 13 14" fill="none">
                <path d="M0 6.86672C1.95098 8.97766 3.84262 10.878 5.66098 13.2816C7.63793 9.34953 9.66137 5.40383 13 1.13082L12.1004 0.71875C9.28129 3.70852 7.09105 6.53859 5.18793 9.90188C3.86449 8.70969 1.72566 7.02258 0.419727 6.15578L0 6.86672Z" fill="#4CAF50" />
              </svg>
              <div className="w-full px-6  py-4 bg-[#dfdfdf] ">
                It identifies career options that align with your personal profile.  </div>
            </div>
            <div className="flex items-center space-x-4 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 13 14" fill="none">
                <path d="M0 6.86672C1.95098 8.97766 3.84262 10.878 5.66098 13.2816C7.63793 9.34953 9.66137 5.40383 13 1.13082L12.1004 0.71875C9.28129 3.70852 7.09105 6.53859 5.18793 9.90188C3.86449 8.70969 1.72566 7.02258 0.419727 6.15578L0 6.86672Z" fill="#4CAF50" />
              </svg>
              <div className="w-full px-6  py-4 bg-[#f5f5f5] ">
                It helps you chart a plan of action to advance your career goals.
              </div>
            </div>
            <div className="flex items-center space-x-4 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 13 14" fill="none">
                <path d="M0 6.86672C1.95098 8.97766 3.84262 10.878 5.66098 13.2816C7.63793 9.34953 9.66137 5.40383 13 1.13082L12.1004 0.71875C9.28129 3.70852 7.09105 6.53859 5.18793 9.90188C3.86449 8.70969 1.72566 7.02258 0.419727 6.15578L0 6.86672Z" fill="#4CAF50" />
              </svg>
              <div className="w-full px-6  py-4 bg-[#dfdfdf] ">
                It offers extensive insights into a variety of careers to support your decision-making process.  </div>
            </div>
          </div>

          <div>
            <p className="lg:text-lg text-base font-bold text-[#212121] uppercase ">HOW YOUR REPORT IS ORGANIZED?</p>
          </div>
          <div>
            <ul className="px-6 pl-8 py-4 bg-[#f5f5f5] list-disc">
              <li>
                Section - I - Psychometric Analysis: Personality, Interest and Work Value </li>
            </ul>
            <ul className="px-6 pl-8 py-4 bg-[#dfdfdf] list-disc">
              <li>
                Section - II - Career Fitment Analysis  </li>
            </ul>
            {/* <ul className="px-6 pl-8 py-4 bg-[#f5f5f5] list-disc">
              <li>Section - III - Summary and Recommendations </li>
            </ul> */}
          </div>

        </div>
      </main>

      {/* INTRODUCTION TO THE "WORLD OF CAREERS section */}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-3xl font-bold uppercase">INTRODUCTION TO THE "WORLD OF CAREERS"</p>
          </div>
          <div className="space-y-10">
            <ul className="text-[#212121] list-disc grid grid-cols-2 gap-4 text-lg pl-6">
              {worldOfCareers.map((items, index) => (
                <>
                  <li>{items.name}</li>
                </>
              ))}
            </ul>
            <img src="./assessment_images/world-of-careers.png" alt='world-of-careers' className="w-full" />
          </div>
        </div>
      </main>

      {/* Personality Test section */}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            {/* <p className="text-lg text-[#212121]/80">PART 1 - Personality Test</p> */}
            <p className="text-3xl font-bold uppercase">1. Personality Test</p>
          </div>
          <div className="space-y-4">
            <p className="text-[#212121]">The Personality test for teens helps young people understand their unique traits and work environments that
              align with their personalities. </p>

            <div className="space-y-1 ">
              {personalityTest.map((items, index) => (

                <div className="inline-flex items-stretch justify-start w-full">
                  <div className="flex-shrink-0 bg-[#f5f5f5f]">
                    {/* <img alt="img" src="./assessment_images/learning-beliefs.png" className="object-cover w-36 h-36" /> */}
                    <img alt={items.name} src={items.imgSrc} className="object-cover w-36 h-36" />
                  </div>
                  <div className="px-6 py-6 bg-[#f5f5f5] w-full">
                    <p className="lg:text-lg text-base font-bold text-[#212121] uppercase">{items.title}</p>
                    <p className="grow shrink basis-0">{items.description}</p>
                  </div>
                </div>

              ))}

            </div>

          </div>

        </div>
      </main>

      {/* YOUR PERSONALITY PROFILE  section*/}
      <PersonalitySection
        crrSelectedEmp={crrSelectedEmp}
        createdAt={createdAt}
        getXList={getXList}
        getDetails={getDetails}
        listX={getPersonalityIndexData}
      />

      {/* INTEREST section  map */}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-3xl font-bold uppercase ">2. INTEREST</p>
          </div>
          <div className="space-y-4">
            <p className="text-[#212121]">The Holland's Interest Test helps teens find career paths and industries that align with their personal interests and preferences</p>

            {/* <div className="bg-[#2196F3] p-4 font-medium">
              <p className="text-2xl text-white">RIASEC - Interest Types</p>
            </div> */}

            <div className="space-y-0.5 ">

              {personalityTypes.map((items, index) => (
                <div className="inline-flex items-stretch justify-start w-full bg-[#f5f5f5] ">
                  <div className="flex-shrink-0  ">
                    <img alt={items.name} src={items.imgSrc} className="object-cover w-36 h-36" />
                  </div>
                  <div className="px-6 py-5 ">
                    <p className="lg:text-lg text-base font-bold text-[#212121] uppercase ">{items.label}</p>
                    <p className="grow shrink basis-0">{items.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      {/* YOUR INTEREST PROFILE section */}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-3xl font-bold uppercase ">YOUR INTEREST PROFILE</p>
          </div>
          <div className="space-y-4 ">
            {/* <p className="text-[#212121]">
              Work value survey for teens helps young people identify what they value most in a work environment, that align with their personal values and ethics.
            </p> */}
            <PieChart listX={getInterestDimensionScoreData} getInterestDetails={getInterestDetails} sortX={sortX} />


          </div>
        </div>
      </main>

      {/* YOUR INTEREST PROFILE section map */}

      <Top5Content
        title={"YOUR INTEREST PROFILE"}
        listX={getInterestDimensionScoreData}
        getDetails={getInterestDetails}
        sortX={sortX}
        staticList={personalityTypes}

        crrSelectedEmp={crrSelectedEmp}
        createdAt={createdAt}

      />


      {/* <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium uppercase">
            <p className="text-3xl font-bold uppercase ">YOUR INTEREST PROFILE</p>
          </div>
          <div className="space-y-4">
            <div className="space-y-1">
              {workValueProfile.map((items, index) => (
                <div className="inline-flex items-center justify-start w-full bg-[#f5f5f5] p-0 space-x-0">
                  <div className="flex-shrink-0 bg-[#f5f5f5f]">
<<<<<<< HEAD
<<<<<<< HEAD
                    <img alt={items.name} src={items.imgSrc} className="flex-shrink-0 object-cover h-full w-44" />
=======
                    <img alt={items.name} src={items.imageSrc} className="flex-shrink-0 object-cover w-48 h-48" />
>>>>>>> 658ffe8b8184b9e1f23b527bd0193279b9610711
=======
                    <img alt={items.name} src={items.imgSrc} className="flex-shrink-0 object-cover h-full w-44" />
>>>>>>> fac5c90759996e39b579fedb97abb015182af0f4
                  </div>
                  <div className="p-6 ">
                    <p className="lg:text-lg text-base font-bold text-[#212121] uppercase">{items.title}</p>
                    <p className="grow shrink basis-0">{items.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main> */}

      {/* WORK VALUE section map*/}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">

            <p className="text-3xl font-bold uppercase ">3. Work Value</p>
          </div>
          <div className="space-y-4">
            <p className="text-[#212121]">
              Work value survey for teens helps young people identify what they value most in a work environment, that align with their personal values and ethics.
            </p>

            <div className="grid grid-cols-2 gap-6">
              {workValue.map((item, index) => (
                <div className="flex items-center justify-start w-full bg-[#f5f5f5] px-4 space-x-4" key={index}>

                  <img alt="img" src={item.imgSrc} className="object-cover h-auto w-36" />
                  <div className="py-5 ">
                    <p className="lg:text-lg text-base font-bold text-[#212121] uppercase">{item.title}</p>
                    <p className="grow shrink basis-0">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>

          </div>

        </div>
      </main>

      {/* YOUR INTEREST PROFILE section */}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-3xl font-bold uppercase ">YOUR WORK VALUE PROFILE</p>
          </div>
          <div className="space-y-4">
            <PieChartWorkValue listX={getWorkValueDimensionScoreData} getWorkValueDetails={getWorkValueDetails} sortX={sortX} />


          </div>
        </div>
      </main>

      {/* YOUR Work Value PROFILE section map */}
      <Top5Content
        title={"YOUR WORK VALUE PROFILE"}
        listX={getWorkValueDimensionScoreData}
        getDetails={getWorkValueDetails}
        sortX={sortX}
        staticList={workValue}

        crrSelectedEmp={crrSelectedEmp}
        createdAt={createdAt}

      />


      {/* Career Fitment analysis section map*/}
      <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-lg text-[#212121]">Part II</p>
            <p className="text-3xl font-bold uppercase ">Career Fitment analysis</p></div>

          <div className="space-y-4">
            <p className="text-[#212121]">
              This section highlights your overall fitment to broad career fields, in terms of Personality, Interest & Work Values. This facilitates the comparison of the findings, with respect to the career clusters determined to be the most appropriate for you.
            </p>

            <div className="flex items-center justify-between space-y-2 ">
              <h1 className="text-xl font-bold text-[#212121]">
                Career Cluster Fitmet
              </h1>
            </div>

            <div className="space-y-4 bg-[#f5f5f5] p-6  pr-12">
              {careerFitment.map((item, index) => (
                <div key={index} className="flex items-center space-x-2 text-[#212121] ">
                  <div className="text-right flex-shrink-0 text-[#3D405B] w-5/12">{item.category}</div>
                  <div className="flex items-center w-7/12 space-x-1">
                    <div className="w-full bg-[#64B5F6] h-6 flex flex-row justify-start" style={{ width: item.percentage }}>
                      <div className="flex items-center justify-start h-6" />
                    </div>
                    <span className="flex items-center text-center text-[#212121]">{item.percentage}</span>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </div>
      </main>

      {/* SUMMARY & RECOMMENDATIONS section map*/}
      {/* <main className="p-10 space-y-6 bg-white">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
              Career Guidance Program
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 p-6 font-medium">
            <p className="text-lg text-[#212121]/80">Part III</p>
            <p className="text-3xl ">SUMMARY & RECOMMENDATIONS</p></div>

          <div className="space-y-4">
            <div className="flex items-center justify-between space-y-2 ">
              <h1 className="text-xl font-bold text-[#212121]">
                Personality Vs Aptitude
              </h1>
              <div className="flex items-center space-x-4 ">
                <div className="text-[#212121] flex items-center space-x-1">
                  <div className="bg-[#64B5F6] w-4 h-3"></div>
                  <p>Personality</p>
                </div>
                <div className="text-[#212121] flex items-center space-x-1">
                  <div className="bg-[#EA80FC] w-4 h-3"></div>
                  <p>Aptitude</p>
                </div>
              </div>
            </div>

            <div className="space-y-4 bg-[#f5f5f5] p-6 ">
              {personalityAptitude.map((item, index) => (
                <div key={index} className="flex items-center space-x-2 text-[#212121]">
                  <div className="text-right flex-shrink-0 text-[#3D405B] w-5/12">{item.category}</div>
                  <div className="w-7/12 space-y-0.5">
                    <div className="flex items-center w-full space-x-1">
                      <div className="w-full bg-[#64B5F6] h-6 flex flex-row justify-start">
                        <div className="flex items-center justify-start h-6" />
                      </div>
                      <span className="flex items-center text-center text-[#212121]">{item.value1}</span>
                    </div>
                    <div className="flex items-center w-full space-x-1">
                      <div className="w-full bg-[#FF8A65] h-6 flex flex-row justify-start">
                        <div className="flex items-center justify-start h-6" />
                      </div>
                      <span className="flex items-center text-center text-[#212121]">{item.value2}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between space-y-2 ">
              <h1 className="text-xl font-bold text-[#212121]">
                Interest Vs Personality
              </h1>
              <div className="flex items-center space-x-4 ">
                <div className="text-[#212121] flex items-center space-x-1">
                  <div className="bg-[#FF8A65] w-4 h-3"></div>
                  <p>  Interest</p>
                </div>
                <div className="text-[#212121] flex items-center space-x-1">
                  <div className="bg-[#EA80FC] w-4 h-3"></div>
                  <p>  Aptitude</p>
                </div>
              </div>
            </div>

            <div className="space-y-4 bg-[#f5f5f5] p-6 ">
              {personalityAptitude.map((item, index) => (
                <div key={index} className="flex items-center space-x-2 text-[#212121]">
                  <div className="text-right flex-shrink-0 text-[#3D405B] w-5/12">{item.category}</div>
                  <div className="w-7/12 space-y-0.5">
                    <div className="flex items-center w-full space-x-1">
                      <div className="w-full bg-[#64B5F6] h-6 flex flex-row justify-start">
                        <div className="flex items-center justify-start h-6" />
                      </div>
                      <span className="flex items-center text-center text-[#212121]">{item.value1}</span>
                    </div>
                    <div className="flex items-center w-full space-x-1">
                      <div className="w-full bg-[#FF8A65] h-6 flex flex-row justify-start">
                        <div className="flex items-center justify-start h-6" />
                      </div>
                      <span className="flex items-center text-center text-[#212121]">{item.value2}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <div className="space-y-2 ">
              <h1 className="text-2xl font-medium text-[#212121]">
                Moderate Strengths
              </h1>
              <p className="text-[#212121]">
                Medium potential areas are your skill areas with respect to your aptitude which can be developed further with effort and guidance.
              </p>
            </div>

            <div className="space-y-1 ">
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#AED581] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col bg-[#AED581]/10"  >
                  <p className="text-base font-medium text-[#212121] ">Numerical Aptitude</p>
                  <p className="grow shrink basis-0"> You seem to understand mathematical concepts and ideas related to numbers. You are likely to apply these concepts in solving problems. However, you may face difficulty in compiling & solving complex numerical data.</p>
                </div></div>
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#AED581] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col bg-[#AED581]/10"  >
                  <p className="text-base font-medium text-[#212121] ">Spatial Aptitude</p>
                  <p className="grow shrink basis-0"> You seem to understand visual patterns and images. You are likely to apply and use imagery to visualize and conceptualize a problem. However, it may be difficult for you to compile complex visual information and use it to derive abstract relations.</p>
                </div></div>
            </div>

          </div>
          <div className="space-y-4">
            <div className="space-y-2 ">
              <h1 className="text-2xl font-medium text-[#212121]">
                Bottom Strengths
              </h1>
              <p className="text-[#212121]">
                Medium potential areas are your skill areas with respect to your aptitude which can be developed further with effort and guidance.
              </p>
            </div>

            <div className="space-y-1 ">
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#DCE775] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col bg-[#DCE775]/10" >
                  <p className="text-base font-medium text-[#212121] ">Numerical Aptitude</p>
                  <p className="grow shrink basis-0">Low potential areas are your skill areas with respect to your aptitude which demonstrate low ability.</p>
                </div></div>

            </div>

          </div>
        </div>
      </main> */}

      {/* YOUR APTITUDE PROFILE section */}
      {/* <main className="p-10 px-6 space-y-6 bg-white">
        <div className="px-12 space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-bold">
            </h1>
            <p className="text-lg font-bold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Sample"} | {createdAt}
            </p>
          </div>
          <div className="bg-[#2196F3]/10 text-3xl p-6 font-medium"> YOUR APTITUDE PROFILE</div>

          <div className="space-y-4">
            <div className="space-y-2 ">
              <h1 className="text-2xl font-medium text-[#212121]">
                Top Strengths
              </h1>
              <p className="text-[#212121]">
                High Potential areas are your strength areas with respect to your aptitude.
              </p>
            </div>

            <div className="space-y-1 ">
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#81C784] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col" style={{ background: 'rgba(76, 175, 80, 0.08)' }}>
                  <p className="text-base font-medium text-[#212121] ">Verbal Aptitude</p>
                  <p className="grow shrink basis-0"> Since you love enhancing other’s abilities, look for areas of work where you are directly working with others to help them grow.</p>
                </div></div>
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#81C784] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col" style={{ background: 'rgba(76, 175, 80, 0.08)' }}>
                  <p className="text-base font-medium text-[#212121] ">Reasoning Aptitude</p>
                  <p className="grow shrink basis-0"> Since you love enhancing other’s abilities, look for areas of work where you are directly working with others to help them grow.</p>
                </div></div>
            </div>

          </div>
          <div className="space-y-4">
            <div className="space-y-2 ">
              <h1 className="text-2xl font-medium text-[#212121]">
                Moderate Strengths
              </h1>
              <p className="text-[#212121]">
                Medium potential areas are your skill areas with respect to your aptitude which can be developed further with effort and guidance.
              </p>
            </div>

            <div className="space-y-1 ">
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#AED581] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col bg-[#AED581]/10"  >
                  <p className="text-base font-medium text-[#212121] ">Numerical Aptitude</p>
                  <p className="grow shrink basis-0"> You seem to understand mathematical concepts and ideas related to numbers. You are likely to apply these concepts in solving problems. However, you may face difficulty in compiling & solving complex numerical data.</p>
                </div></div>
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#AED581] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col bg-[#AED581]/10"  >
                  <p className="text-base font-medium text-[#212121] ">Spatial Aptitude</p>
                  <p className="grow shrink basis-0"> You seem to understand visual patterns and images. You are likely to apply and use imagery to visualize and conceptualize a problem. However, it may be difficult for you to compile complex visual information and use it to derive abstract relations.</p>
                </div></div>
            </div>

          </div>
          <div className="space-y-4">
            <div className="space-y-2 ">
              <h1 className="text-2xl font-medium text-[#212121]">
                Bottom Strengths
              </h1>
              <p className="text-[#212121]">
                Medium potential areas are your skill areas with respect to your aptitude which can be developed further with effort and guidance.
              </p>
            </div>

            <div className="space-y-1 ">
              <div className="inline-flex items-start justify-start w-full gap-1">
                <div className="self-stretch px-3 py-2 flex justify-center items-center gap-2.5  mb-2 bg-[#DCE775] w-16">
                  <h3 className="relative text-xl font-medium text-[#212121]">5.5</h3>
                </div>
                <div className="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] center gap-1 flex flex-col bg-[#DCE775]/10" >
                  <p className="text-base font-medium text-[#212121] ">Numerical Aptitude</p>
                  <p className="grow shrink basis-0">Low potential areas are your skill areas with respect to your aptitude which demonstrate low ability.</p>
                </div></div>

            </div>

          </div>
        </div>
      </main> */}


    </>
  );
}