import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assessmentActions } from "../../../../../../_actions";
import { CONST } from '../../../../../../_config';
import moment from 'moment';
import { MdClose } from "react-icons/md";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { TbPointFilled } from 'react-icons/tb';

class BackSummary extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Get the date in "YYYY-MM-DD" format
    this.state = {
      rowModalData: {},
      showMainPage: true,
      showFilter: false,
      showValue: '',
      showTabView: 2,
      activeTab: 1,
      isTest: true,
      crrExpertId: false,
      rating: 0,
      menu: "BookExpert",
      showGrid: true,
      isOpenTop5Model: false,
      currentDate: formattedDate,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(assessmentActions.getExpertDetailsList());
  }


  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value })
  }


  handleStrength = (params) => {
    let { handleThemeStrenght } = this.props;
    let { FilterValues } = this.state;
    let name = ''
    if (params && params.name) {
      name = params.name;
    }
    handleThemeStrenght(name, FilterValues);
    this.setState({ showMainPage: false })
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {

    let { handlAssessmentDashboard } = this.props;
    this.setState({
      FilterValues: finalFilter,
      ListName: name,
      showMainPage: true
    });

    handlAssessmentDashboard(finalFilter, true);

  }

  handleGOTOTheme = () => {
    this.setState({ showMainPage: true })
  }

  handleTabView = (value) => {
    this.setState({ showTabView: value })
  }

  setActiveTab = (tabNumber) => {
    this.setState({
      activeTab: tabNumber,
    });
  };

  handleOpenModalRating = (expertId) => {
    this.setState({ crrExpertId: expertId });
  };

  handleCloseModalRating = () => {
    this.setState({ crrExpertId: false });
  };

  handleReviewInput = (star) => {
    this.setState({ rating: star });
  }
  handleReviewSubmit = () => {
    let input = {
      "rating": this.state.rating.toString(),
      "expertId": this.state.crrExpertId
    }
    console.log("input--->", input)
    this.props.dispatch(assessmentActions.submitExpertRating(input));
    this.setState({ crrExpertId: false });
  }

  handleGridView = (check) => {
    this.setState({ showGrid: check })
  }


  handleOpenTop5Modal = (data) => {

    console.log('open model on click to same page');
    this.setState({ isOpenTop5Model: true, rowModalData: data });
  };

  handleCloseTop5Modal = () => {
    this.setState({ isOpenTop5Model: false });
  };

  handleMenuClick = (selectedMenu) => {
    this.setState({
      menu: selectedMenu,
      menuTab: selectedMenu
    });
  };

  render() {
    const { menu } = this.state;
    // console.log('rowModalDatarowModalDatarowModalData______', this.state.rowModalData);

    let { getTalentResourcesData, getExpertOrderBookListData, showMyExpertList, handleTestPayment, userAssessmentExpertOrderPlaceData, errorsCrrCodeUserDetails, handleInputExpertForm, bookExpert, crrCodeUserDetails, getAssessmenrEmployeeDetailsData, handleShowMainSummaryModal, EditDetailsFn, getAssessmentReportByEmpIDData, crrDetail, loading, getAssessmentDimensionsData, EditButtonFn, handleShowMainSummaryModalBack, contactExpertSection, handleExpertContactModal, topFiveTalent, handleExpertContactModalBack, assessment } = this.props;
    let { showMainPage, showTabView, crrExpertId, showGrid, isOpenTop5Model } = this.state;

    let { getExpertDetailsListData } = assessment;

    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }

    let AssessmentList = []
    if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
      getAssessmentDimensionsData.forEach((element) => {
        AssessmentList.push({
          ...element,
          finalSum: (Number(element.count) * Number(element.rankSum))
        })
      })
      AssessmentList.sort((a, b) => (a.finalSum - b.finalSumSum))
    }

    let newList = []
    if (AssessmentList && AssessmentList.length > 0) {
      AssessmentList.forEach((element, index) => {

        let rank = (AssessmentList.length - index);

        let check = 0;
        if (showTabView === 1) {
          check = 1
        } else if (showTabView === 2 && (rank <= 5)) {
          check = 2
        } else if (showTabView === 3 && (rank <= 7)) {
          check = 3
        } else if (showTabView === 4 && (rank <= 10)) {
          check = 4
        }
        console.log("check--1->", check)

        let getIndex = newList.findIndex(prev => prev.bucket === element.bucket)
        if (getIndex === -1) {
          let temp = {
            "showCount": 0,
            "bucket": element.bucket,
            "colorCode": element.colorCode,
            "dimensions": [{
              "rank": rank,
              "check": check,
              "name": element.dimension,
              "count": element.count,
              "finalSum": element.finalSum,
              "colorCode": element.colorCode,
            }],
          }
          newList.push(temp)
        } else {
          newList[getIndex]["showCount"] += 1

          newList[getIndex]["dimensions"].push({
            "rank": rank,
            "check": check,
            "name": element.dimension,
            "count": element.count,
            "finalSum": element.finalSum,
            "colorCode": element.colorCode,
          })
        }



      });
    }

    let newV2List = [];
    if (newList && newList.length > 0) {
      for (var ix = 0; ix < newList.length; ix++) {
        let details = newList[ix];
        let temp = details;
        if (details && details.dimensions && details.dimensions.length > 0) {
          temp["dimensions"] = details.dimensions.sort((a, b) => (a.rank - b.rank));
        }
        newV2List.push(temp)
      }
    }

    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }

    function getTitle(element, type) {
      let itemName =
        type === 1
          ? "How to use - Talent every day?"
          : "How to deal with a person who has - Talent?";
      let final = itemName.replace("-", element.dimension.trim());
      //console.log("final--->", final.toString());
      return final.toString();
    }

    function getModifiedContent(text) {
      let text2 = "";
      if (text) {
        text2 = text
          .replace(/<\/?li[^>]*>/g, "@")
          .replace(/<\/?ul[^>]*>/g, "")
          .replace(/<\/?span[^>]*>/g, "")
          .replace(/<\/?p[^>]*>/g, "")
          .replace(/&nbsp;/g, "")
          .replace(/<\/?br[^>]*>/g, "")
          .replace(/<\/?b[^>]*>/g, "");
      }
      return text2 ? text2 : text;
    }

    function getBulletPoints(element, field) {
      let descX =
        element && element.details && element.details[field]
          ? element.details[field]
          : "";
      let descNew = "";
      if (descX) {
        let temp = getModifiedContent(descX);
        descNew = temp.split("@").filter((prev) => prev !== "");
      }
      return descNew;
    }

    function chunkList(array) {
      const chunkSize = 10;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      return list;
    }


    console.log("crrCodeUserDetails--------.", crrCodeUserDetails)


    let getBookedExpertList = [
      {
        "name": "01",
        "name": "Dr. Ashish Ambasta",
        "name": "",
        "name": "",
      }
    ]

    function getExpertDetails(ele, type, a, b) {
      let value = "";
      if (type === 1) {
        if (ele && ele[a]) {
          value = ele[a]
        }
      } else if (type === 2) {
        if (ele && ele[a] && ele[a][b]) {
          value = ele[a][b]
        }
      }
      return a === "rating" ? (value ? value : "NA") : value
    }


    if (getExpertOrderBookListData && Array.isArray(getExpertOrderBookListData)) {
      // Sort the array
      let sortedExpertOrderBookListData = getExpertOrderBookListData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      // Rest of your code...
    } else {
      console.error("getExpertOrderBookListData is not defined or is not an array");
    }



    return (
      <div className="w-full mb-6" >

        <div className="">

          {
            topFiveTalent ?
              <>
                <div className="space-y-6 ">

                  {/* {!showMyExpertList ?
                    <>
                      <Top5Talents
                        getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                        EditDetailsFn={EditDetailsFn}
                        EditButtonFn={EditButtonFn}
                        crrDetail={crrDetail}
                        handleOpenTop5Modal={this.handleOpenTop5Modal}
                        handleCloseTop5Modal={this.handleCloseTop5Modal}
                        handleShowMainSummaryModal={handleShowMainSummaryModal}
                        handleShowMainSummaryModalBack={handleShowMainSummaryModalBack}
                      />
                      <Resources getTalentResourcesData={getTalentResourcesData} />
                    </>
                    : null} */}

                  {true ?
                    <>

                      <div className="overflow-x-auto border-b border-gray-300 whitespace-nowrap">
                        <div className="flex flex-row  lg:text-base text-sm font-medium text-center text-[#212121]/70 space-x-6 ">

                          <button className={menu === 'BookExpert' ? 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-[#2196F3] text-[#212121] ' : 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-transparent'}
                            onClick={() => this.handleMenuClick("BookExpert")}>Book an Expert</button>

                          <button className={menu === 'MyAppointment' ? 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-[#2196F3] text-[#212121] ' : 'cursor-pointer inline-flex items-center justify-center p-4 px-1 border-b-[3px] border-transparent'}
                            onClick={() => this.handleMenuClick("MyAppointment")}>My Appointment</button>

                        </div>
                      </div>

                      {menu === "MyAppointment" ? <div>
                        {getExpertOrderBookListData && getExpertOrderBookListData.length > 0 ?
                          <section className="!w-full mb-6 lg:space-y-6 ">
                            <table className='hidden w-full lg:inline-table '>
                              <thead>
                                <tr className='bg-white'>
                                  <th className='p-4 text-base font-medium text-left'>Name</th>
                                  <th className='p-4 text-base font-medium text-left'>Booking Id</th>
                                  <th className='p-4 text-base font-medium text-left'>Appointment Date</th>
                                  <th className='p-4 text-base font-medium text-center'>Status</th>
                                  <th className='p-4 text-base font-medium text-center'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getExpertOrderBookListData && getExpertOrderBookListData.length > 0
                                  ? getExpertOrderBookListData.map((ele) => (
                                    <tr className='bg-white border-t-8  border-[#FAFAFA]  '>
                                      <td className='p-4 py-3'>
                                        <div className='flex items-center flex-shrink-0 space-x-2'>
                                          <div className='relative flex-shrink-0 w-16 h-16'>
                                            <img src={ele.expertId && ele.expertId.imageLinkV2 ? ele.expertId.imageLinkV2 : '/img/assessment/summary/dummy-image.jpg'}
                                              className="object-cover rounded-full "
                                            />
                                            <svg className='absolute bottom-0 right-0' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48" fill="none">
                                              <rect x="12" y="12" width="20" height="20" fill="white" />
                                              <path d="M45.9982 24L41.119 18.42L41.7982 11.04L34.579 9.4008L30.799 3L23.9998 5.9208L17.1982 3L13.4182 9.3792L6.19902 10.9992L6.87822 18.3984L1.99902 24L6.87822 29.58L6.19902 36.9792L13.4182 38.6184L17.1982 45L23.9974 42.06L30.799 44.9808L34.579 38.6016L41.7982 36.9624L41.119 29.58L45.9982 24ZM20.179 33.4392L12.5782 25.8192L15.5398 22.86L20.179 27.5208L31.879 15.78L34.8382 18.7392L20.179 33.4392Z" fill="#00CD7E" />
                                            </svg>
                                          </div>
                                          <div className=''>
                                            <h1 className="text-base font-semibold md:text-lg">{getExpertDetails(ele, 2, "expertId", "name")}</h1>
                                            <div className="flex items-center space-x-2">
                                              <p className="text-[#212121]/80 capitalize text-base">{getExpertDetails(ele, 2, "expertId", "type")}</p>
                                              <TbPointFilled className='text-[#212121]/30 text-[1rem]' />
                                              <div className='flex items-center space-x-1.5'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='-mt-1.5' width="20" height="19" viewBox="0 0 12 11" fill="none">
                                                  <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFA000" />
                                                </svg>
                                                <p className="text-base font-medium text-[#212121]">{ele.rating} (5)</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>

                                      <td className='p-4 py-3'>
                                        <h1 className="text-base font-semibold md:text-lg "> {getExpertDetails(ele, 1, "orderNumber")}  </h1>
                                        <p className="text-[#212121]/80 md:text-base text-sm font-medium">

                                          {moment(new Date(parseInt(getExpertDetails(ele, 1, "createdAt"))))
                                            .utcOffset("+05:30")
                                            .format("ddd, Do MMM, YYYY")}
                                        </p>
                                      </td>

                                      <td className='p-4 py-3'>
                                        <div className='flex space-x-4 text-[#212121] font-semibold'>
                                          <span>{moment(new Date(parseInt(getExpertDetails(ele, 1, "createdAt"))))
                                            .utcOffset("+05:30")
                                            .format("ddd, Do MMM, YYYY")}</span>
                                          {/* <span>10:30 am</span> */}
                                        </div>
                                      </td>
                                      <td className='p-4 py-3 text-center'>
                                        <div className='flex items-center justify-center space-x-0 text-[#673AB7]'>
                                          <TbPointFilled className='text-[1.4rem]' />
                                          <span className='text-sm font-medium '>Upcoming</span>
                                        </div>
                                      </td>
                                      <td className='p-4 py-3 text-center'>

                                        <button onClick={() => this.handleOpenModalRating(getExpertDetails(ele, 2, "expertId", "id"))} className='px-4 py-2  border border-[#ffb300] rounded-md text-[#ffb300]'>Rate this expert</button>
                                      </td>
                                    </tr>
                                  ))
                                  :
                                  <section className="flex items-center justify-center w-full col-span-2 gap-10 px-4 mx-auto overflow-hidden">
                                    <div className="flex flex-col items-center justify-center py-8 space-y-4 text-center md:py-10">
                                      <img src="/img/newicons/empty_cart2.svg" alt="emptycart.svg" className="w-3/6 2xl:w-2/4" />
                                      <h2 className="capitalize md:text-3xl text-2xl font-semibold text-[#3D405B] pt-4">No Order Yet!</h2>
                                      <p className="text-lg text-gray-500">Looks like you haven’t made your choice yet.</p>
                                      <div className="mt-0 md:mt-8">
                                        <button onClick={() => this.props.history.push(`/assessment/` + "survey")} className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-10 rounded uppercase mt-10"> shop Now </button>
                                      </div>
                                    </div>
                                  </section>
                                }
                              </tbody>
                            </table>

                            <div className='block space-y-4 lg:hidden'>
                              {getExpertOrderBookListData && getExpertOrderBookListData.length > 0
                                ? getExpertOrderBookListData.map((ele) => (
                                  <div className='p-4 bg-white border rounded-2xl'>
                                    <div className='flex items-center flex-shrink-0 space-x-4'>
                                      <div className='relative flex-shrink-0 w-20 h-20'>
                                        <img src={ele.expertId && ele.expertId.imageLinkV2 ? ele.expertId.imageLinkV2 : '/img/assessment/summary/dummy-image.jpg'}
                                          className="object-cover rounded-xl "
                                        />
                                      </div>
                                      <div className='space-y-1 '>
                                        <div className='flex items-center space-x-1.5'>
                                          <svg className='' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" fill="none">
                                            <rect x="12" y="12" width="20" height="20" fill="white" />
                                            <path d="M45.9982 24L41.119 18.42L41.7982 11.04L34.579 9.4008L30.799 3L23.9998 5.9208L17.1982 3L13.4182 9.3792L6.19902 10.9992L6.87822 18.3984L1.99902 24L6.87822 29.58L6.19902 36.9792L13.4182 38.6184L17.1982 45L23.9974 42.06L30.799 44.9808L34.579 38.6016L41.7982 36.9624L41.119 29.58L45.9982 24ZM20.179 33.4392L12.5782 25.8192L15.5398 22.86L20.179 27.5208L31.879 15.78L34.8382 18.7392L20.179 33.4392Z" fill="#00CD7E" />
                                          </svg>
                                          <h1 className="md:text-lg text-base font-medium  ">{getExpertDetails(ele, 2, "expertId", "name")}</h1>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                          <p className="text-[#212121]/70 capitalize text-base">{getExpertDetails(ele, 2, "expertId", "type")}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='flex items-center justify-between border-b py-4'>
                                      <div className='flex items-center space-x-2.5 '>
                                        <div className='flex items-center space-x-0'>
                                          <svg xmlns="http://www.w3.org/2000/svg" className='-mt-1.5 mr-1' width="22" height="21" viewBox="0 0 12 11" fill="none">
                                            <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFA000" />
                                          </svg>
                                          <p className='text-base font-medium text-[#212121]'>{ele.rating}</p>
                                        </div>
                                        <div className='flex items-center space-x-0.5 '>
                                          <p className="text-base  text-[#212121]/70">5</p>
                                          <p className='text-[#212121]/70 capitalize text-base   '>Ratings</p>
                                        </div>
                                      </div>

                                      <button onClick={() => this.handleOpenModalRating(getExpertDetails(ele, 2, "expertId", "id"))} className='px-6 py-1.5  border border-[#FFA000] rounded-full text-[#FFA000]'>Rate Expert</button>
                                    </div>
                                    <div className='flex items-center justify-between pt-4'>

                                      <div>
                                        <h1 className="text-sm text-[#212121]/70 ">Booking Id:</h1>
                                        <p className="text-base text-[#212121]"> {getExpertDetails(ele, 1, "orderNumber")}  </p>

                                      </div>
                                      <div>
                                        <h1 className="text-sm text-[#212121]/70 ">Appointment</h1>
                                        <p className="text-base  text-[#212121]">

                                          {moment(new Date(parseInt(getExpertDetails(ele, 1, "createdAt"))))
                                            .utcOffset("+05:30")
                                            .format("ddd, DMMM, YYYY")}
                                        </p>
                                      </div>
                                      <div>
                                        <h1 className="text-sm text-[#212121]/70 ">Status</h1>
                                        <span className='text-sm  text-[#FFA000] '>Upcoming</span>
                                      </div>

                                    </div>

                                  </div>
                                ))
                                : null}

                            </div>


                            {/*Expert Booking List */}
                            {/* <div className="grid grid-cols-1 gap-4 text-[#3D405B]">
                                {getExpertOrderBookListData && getExpertOrderBookListData.length > 0
                                  ? getExpertOrderBookListData.map((ele) => (

                                    <div className="p-6 space-y-2 bg-white border rounded-md ">
                                      <div className="flex md:flex-row flex-col justify-between gap-4 md:items-center -m-6 -mb-0 p-6 py-4 bg-[#F9FAFB] border-b">
                                        <div className="space-y-0 text-[#3D405B] ">
                                          <h1 className="text-lg font-semibold md:text-xl">Booking No: {getExpertDetails(ele, 1, "orderNumber")}  </h1>
                                          <p className="text-[#3D405B]/70 md:text-base text-sm">
                                            Booking Date On: {""}
                                            {moment(new Date(parseInt(getExpertDetails(ele, 1, "createdAt"))))
                                              .utcOffset("+05:30")
                                              .format("ddd, Do MMM, YYYY")}
                                          </p>
                                        </div>
                                      </div>
                                      {JSON.stringify(ele.imageLinkV2)}
                                      <div className="divide-y">
                                        <div className="pt-6 space-y-4 ">
                                          <div className="flex flex-row items-start gap-4 md:gap-6 md:items-center ">
                                            <div className="flex items-center w-20 h-20 cursor-pointer md:w-32 md:h-32">
                                              <img src={ele.expertId && ele.expertId.imageLinkV2 ? ele.expertId.imageLinkV2 : '/img/assessment/summary/dummy-image.jpg'}
                                                className="w-20 h-20 md:w-44 md:h-36 overflow-cover rounded-xl"
                                              />{" "}
                                            </div>

                                            <div className="flex-1 w-full ">
                                              <div className="text-[#3D405B] flex 2xl:flex-row flex-col justify-between w-full ">
                                                <h1 className="md:text-xl text-normal">{getExpertDetails(ele, 2, "expertId", "name")}</h1>
                                                <h1 className="text-lg font-semibold md:text-2xl ">
                                                  <span className="font-[roboto]"> ₹</span>{getExpertDetails(ele, 1, "total")}
                                                </h1>
                                              </div>
                                              <div className="items-center space-y-2">
                                                <p className="text-[#3D405B]/70 capitalize">{getExpertDetails(ele, 2, "expertId", "type")}</p>
                                                <div className="flex items-center">
                                                  <div className="flex items-center">
                                                    <p className="text-[#3D405B]/80 text-base tracking-wide">Rating: </p>

                                                    {[1, 2, 3, 4, 5].map((star, index) =>
                                                      (index < parseFloat(getExpertDetails(ele, 1, "rating"))) ?
                                                        <svg className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                          <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                                          </path>
                                                        </svg>
                                                        :
                                                        <svg className="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20"
                                                          xmlns="http://www.w3.org/2000/svg">
                                                          <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                                          </path>
                                                        </svg>
                                                    )}
                                                  </div>
                                                  <p className="text-sm font-medium text-gray-500 ms-1 dark:text-gray-400">{getExpertDetails(ele, 1, "rating")}</p>
                                                  <p className="text-sm font-medium text-gray-500 ms-1 dark:text-gray-400">out of</p>
                                                  <p className="text-sm font-medium text-gray-500 ms-1 dark:text-gray-400">5</p>
                                                </div>
                                                <button onClick={() => this.handleOpenModalRating(getExpertDetails(ele, 2, "expertId", "id"))}
                                                  className="inline-flex items-center justify-center w-full px-2 py-2 overflow-hidden text-base font-medium text-yellow-500 border border-yellow-500 rounded-lg cursor-pointer lg:w-44">
                                                  Give Feedback</button>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>

                                      {this.state.drowdown ? (
                                        <div className="py-4 pb-0 space-y-4 text-base border-t md:text-lg">
                                          <div className="flex items-center justify-between py-2 capitalize ">
                                            <p className="text-xl font-semibold ">
                                              Total Paid
                                            </p>
                                            <p className="text-lg font-semibold md:text-2xl">
                                              <span className="font-[roboto]"> ₹ </span> {getExpertDetails(ele, 1, "total")}
                                            </p>
                                          </div>
                                          <div className="flex items-center p-4 py-2 space-x-3 bg-gray-100">
                                            <img src="/img/assessment/UPI logo.png" />
                                    
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>

                                  ))
                                  :
                                  <section className="flex items-center justify-center w-full col-span-2 gap-10 px-4 mx-auto overflow-hidden">
                                    <div className="flex flex-col items-center justify-center py-8 space-y-4 text-center md:py-10">
                                      <img src="/img/newicons/empty_cart2.svg" alt="emptycart.svg" className="w-3/6 2xl:w-2/4" />
                                      <h2 className="capitalize md:text-3xl text-2xl font-semibold text-[#3D405B] pt-4">No Order Yet!</h2>
                                      <p className="text-lg text-gray-500">Looks like you haven’t made your choice yet.</p>
                                      <div className="mt-0 md:mt-8">
                                        <button onClick={() => this.props.history.push(`/assessment/` + "survey")} className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-10 rounded uppercase mt-10"> shop Now </button>
                                      </div>
                                    </div>
                                  </section>
                                }

                              </div> */}

                          </section>
                          : null}
                      </div> : null}

                    </>
                    : null}


                  {/* Experts Book section */}
                  {menu === "BookExpert" && (
                    <>
                      <section className="w-full pb-6 space-y-6">
                        <div className='grid grid-cols-1 gap-4 2xl:grid-cols-4 md:grid-cols-3 2xl:gap-6 '>

                          {getExpertDetailsListData && getExpertDetailsListData.length > 0 ?
                            getExpertDetailsListData.map((ele) =>
                              <div className="p-6 px-4 py-10 space-y-4 overflow-hidden transition-all duration-150 bg-white border rounded-md cursor-pointer hover:shadow-xl" onClick={() => handleExpertContactModal(ele)}>

                                <div className='relative mx-auto h-36 w-36 '>
                                  <img src={ele.imageLinkV2 ? ele.imageLinkV2 : '/img/assessment/summary/dummy-image.jpg'}
                                    className="object-cover rounded-full " />

                                </div>
                                <div className="flex flex-col items-center justify-center space-y-1 ">
                                  <div className='flex items-center flex-row gap-2'>
                                    <svg className='' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48" fill="none">
                                      <rect x="12" y="12" width="24" height="24" fill="#fff" />
                                      <path d="M45.9982 24L41.119 18.42L41.7982 11.04L34.579 9.4008L30.799 3L23.9998 5.9208L17.1982 3L13.4182 9.3792L6.19902 10.9992L6.87822 18.3984L1.99902 24L6.87822 29.58L6.19902 36.9792L13.4182 38.6184L17.1982 45L23.9974 42.06L30.799 44.9808L34.579 38.6016L41.7982 36.9624L41.119 29.58L45.9982 24ZM20.179 33.4392L12.5782 25.8192L15.5398 22.86L20.179 27.5208L31.879 15.78L34.8382 18.7392L20.179 33.4392Z" fill="#4CAF50" />
                                    </svg>
                                    <h1 className="text-[#212121] font-semibold text-xl  capitalize">  {ele.name}</h1>
                                  </div>
                                  <p className="  text-[#212121]/70 font-medium">{ele.type}</p>
                                  {/* <p className="text-[#3D405B] tracking-wide">Price: {ele.price}</p> */}

                                  <div className='flex items-center space-x-2 pt-2'>
                                    <div className="flex items-center space-x-2 bg-[#4CAF50] p-3 py-1 rounded-md ">
                                      <p className="text-base font-medium text-[#fff]">{ele.rating} </p>
                                      <svg xmlns="http://www.w3.org/2000/svg" class="-mt-[2px]" width="19" height="18" viewBox="0 0 12 11" fill="none"><path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#fff"></path></svg>
                                    </div>
                                    <p className='text-[#212121]/70 font-medium'>
                                      5 Ratings
                                    </p>

                                  </div>

                                  {/* <div className="flex items-center">
                                    <div className="flex items-center">
                                      <p className="text-[#3D405B]/80 text-base tracking-wide">Rating: </p>
                                      {[1, 2, 3, 4, 5].map((star, index) =>
                                        (index < parseFloat(getExpertDetails(ele, 1, "rating"))) ?
                                          <svg className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                            </path>
                                          </svg>
                                          :
                                          <svg className="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                            </path>
                                          </svg>
                                      )}
                                    </div>
                                    <p className="text-sm font-medium text-gray-500 ms-1 dark:text-gray-400">{getExpertDetails(ele, 1, "rating")}</p>
                                    <p className="text-sm font-medium text-gray-500 ms-1 dark:text-gray-400">out of</p>
                                    <p className="text-sm font-medium text-gray-500 ms-1 dark:text-gray-400">5</p>
                                  </div> */}



                                </div>

                                <h1 className="text-[#212121] tracking-wide text-xl text-center">
                                  <span className=" font-semibold text-[#212121]">Coaching Fee:  <span className="font-[roboto]">₹</span>{ele.price}</span>
                                </h1>
                              </div>

                            )
                            : null}

                        </div>

                      </section>
                    </>
                  )}

                </div>
              </> : null
          }

          {/* Expert details page with form in below */}
          {
            contactExpertSection && contactExpertSection.id ?
              <section className='space-y-6 '>
                <section className=''>
                  <div className="flex items-center py-4 space-x-4 border-b">
                    <span className="p-2 text-white duration-150 bg-[#2196F3] rounded-full cursor-pointer " onClick={handleExpertContactModalBack}>    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 6l-6 6l6 6" /></svg></span>
                    <h1 className="py-2 text-lg font-medium md:text-xl text-[#3D405B]">Back to Talent Details</h1>
                  </div>
                </section>

                <section className="md:py-6">
                  <div className="grid w-full grid-cols-1 gap-10 mx-auto text-gray-100 md:gap-20 xl:px-0 lg:grid-cols-2">

                    <div className="space-y-4 ">
                      <div className="space-y-0">
                        <div className="flex flex-col items-center w-full gap-6 pt-4 md:flex-row">
                          <img src={contactExpertSection && contactExpertSection.imageLinkV2 ? contactExpertSection.imageLinkV2 : '/img/assessment/summary/dummy-image.jpg'}
                            alt='expert' className="md:w-64 w-full  flex-shrink-0 object-center object-cover" />
                          {/* <img src={"https://demoplus.happyplus.in/images/emptyexpert.png"} alt='expert' className='' /> */}
                          <div className="md:pl-6 space-y-3 text-[#3D405B] flex-1  md:text-left text-center">
                            <div className='space-y-2  '>
                              <div className='flex items-start flex-row gap-2'>
                                <svg className='' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48" fill="none">
                                  <rect x="12" y="12" width="24" height="24" fill="#fff" />
                                  <path d="M45.9982 24L41.119 18.42L41.7982 11.04L34.579 9.4008L30.799 3L23.9998 5.9208L17.1982 3L13.4182 9.3792L6.19902 10.9992L6.87822 18.3984L1.99902 24L6.87822 29.58L6.19902 36.9792L13.4182 38.6184L17.1982 45L23.9974 42.06L30.799 44.9808L34.579 38.6016L41.7982 36.9624L41.119 29.58L45.9982 24ZM20.179 33.4392L12.5782 25.8192L15.5398 22.86L20.179 27.5208L31.879 15.78L34.8382 18.7392L20.179 33.4392Z" fill="#4CAF50" />
                                </svg>
                                <h1 className="text-[#212121] font-semibold text-2xl  capitalize">  {contactExpertSection && contactExpertSection.name}</h1>
                              </div>
                              <p className="text-base text-[#212121]/70 font-medium">{contactExpertSection && contactExpertSection.type}</p> 

                              <div className='flex items-center justify-center space-x-2 pt-2'>
                                <div className="flex items-center space-x-2 bg-[#4CAF50] p-3 py-1 rounded-md ">
                                  <p className="text-base font-medium text-[#fff]">{contactExpertSection && contactExpertSection.rating} </p>
                                  <svg xmlns="http://www.w3.org/2000/svg" class="-mt-[2px]" width="19" height="18" viewBox="0 0 12 11" fill="none"><path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#fff"></path></svg>
                                </div>
                                <p className='text-[#212121]/70 font-medium'>
                                  5 Ratings
                                </p>

                              </div>
                              <p className="text-xl font-semibold text-[#212121]">Coaching Fee:<span className='font-semibold'> <span className="font-[roboto]">₹</span>{contactExpertSection && contactExpertSection.price}</span></p>


                            </div>
                            <div className='flex items-center p-2 px-4 bg-[#4CAF50]/20 justify-between rounded-lg text-center w-fit space-x-2'>
                              <p className="text-base italic font-semibold">Impact Coach Certified</p>
                              <img src='/img/assessment/summary/Certified.png' alt='' className='w-6' />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row items-center gap-6 pt-16">
                          <h2 className="text-[#3D405B] text-3xl font-semibold  capitalize flex-shrink-0">About</h2>
                          <div className="w-full h-px bg-zinc-300"></div>
                        </div>

                        <div className='space-y-3 text-[#3D405B] pt-4 text-lg'>
                          {contactExpertSection && contactExpertSection.desc}
                          {/* <p>After working in different firms including Indian conglomerates & World-renowned consulting firms he has decided to devote his life to improving happiness around the world through Happy+.</p>
                            <p>It’s a technology-enabled happiness habit formation organisation that intends to change the way people look at happiness. He is ably backed by a team of talented people who are experts in their respective spheres.</p>
                            <p>He is also the founder of SEEtalks which tells the untold stories of changemakers to inspire the next generation to do good.</p>
                            <p>Ashish is also on board with Love Heals Cancer along with Kindness Unlimited and is trying to make the world a happy place.</p> */}
                        </div>
                      </div>

                    </div>

                    {/* Form section */}
                    <div className="p-4 space-y-6 bg-white border rounded-lg md:p-10">
                      <div className="space-y-2 ">
                        <h2 className=" md:text-3xl text-2xl  font-semibold text-[#3D405B] capitalize">
                          Submit Details
                        </h2>
                        <p className=" text-base text-[#3D405B]">
                          Fill in your details below & we will get back to you soon!
                        </p>
                      </div>

                      <div noValidate action className="grid grid-cols-1 gap-6 pt-2">
                        <div className="space-y-2">
                          <label htmlFor="name" className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678] capitalize">Full name</label>
                          <input className="w-full rounded-md border border-[#e0e0e0] bg-[#F9FAFB] py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none   placeholder-gray-500"
                            id="userName" name="userName" placeholder="Enter Full Name" type="text"
                            onChange={(e) => handleInputExpertForm(e.target.value, "name")}
                            value={crrCodeUserDetails && crrCodeUserDetails.name ? crrCodeUserDetails.name : ""} />
                          {errorsCrrCodeUserDetails && errorsCrrCodeUserDetails["name"] ?
                            <div className="text-xs text-red-500 invalid-feedback">
                              {errorsCrrCodeUserDetails["name"]}
                            </div>
                            : null}
                        </div>

                        <div className="space-y-2">
                          <label htmlFor="name" className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678] capitalize">Email</label>
                          <input className="w-full rounded-md border border-[#e0e0e0] bg-[#F9FAFB] py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none   placeholder-gray-500"
                            id="userName" name="userName" placeholder="Enter Email" type="text"
                            onChange={(e) => handleInputExpertForm(e.target.value, "email")}
                            value={crrCodeUserDetails && crrCodeUserDetails.email ? crrCodeUserDetails.email : ""} />
                          {errorsCrrCodeUserDetails && errorsCrrCodeUserDetails["email"] ?
                            <div className="text-xs text-red-500 invalid-feedback">
                              {errorsCrrCodeUserDetails["email"]}
                            </div>
                            : null}
                        </div>

                        <div className="space-y-2">
                          <label htmlFor="name" className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678] capitalize">Mobile</label>
                          <input className="w-full rounded-md border border-[#e0e0e0] bg-[#F9FAFB] py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none   placeholder-gray-500"
                            id="userName" name="userName" placeholder="Enter Mobile" type="text"
                            onChange={(e) => handleInputExpertForm(e.target.value, "mobile")}
                            value={crrCodeUserDetails && crrCodeUserDetails.mobile ? crrCodeUserDetails.mobile : ""} />
                          {errorsCrrCodeUserDetails && errorsCrrCodeUserDetails["mobile"] ?
                            <div className="text-xs text-red-500 invalid-feedback">
                              {errorsCrrCodeUserDetails["mobile"]}
                            </div>
                            : null}
                        </div>

                        <div className="space-y-2">
                          <label htmlFor="name" className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678] capitalize">Date</label>
                          <input
                            type="date"
                            id="dateInput"
                            name="dateInput"
                            value={this.state.currentDate}
                            onChange={(e) => this.setState({ currentDate: e.target.value })}
                            className="w-full rounded-md border border-[#e0e0e0] bg-[#F9FAFB] py-3 px-4 text-base font-base text-[#212121] font-medium !placeholder:font-normal outline-none !placeholder-gray-500 select-none " placeholder="dd/mm/yyyy" />
                        </div>

                        <div className="space-y-2">
                          <label htmlFor="message" className="text-base font-medium text-[#3D405B]/70 before:content-['*'] before:text-[#F14678]">Message (If Any)</label>
                          <textarea onChange={(e) => handleInputExpertForm(e.target.value, "message")}
                            value={crrCodeUserDetails && crrCodeUserDetails.message ? crrCodeUserDetails.message : ""} rows={3} className="w-full rounded-md border border-[#e0e0e0] bg-[#F9FAFB] py-3 px-4 text-base font-base text-[#212121] font-medium placeholder:font-normal outline-none   placeholder-gray-500" id="message" name="message" placeholder="Message" type="text" />
                          {errorsCrrCodeUserDetails && errorsCrrCodeUserDetails["message"] ?
                            <div className="text-xs text-red-500 invalid-feedback">
                              {errorsCrrCodeUserDetails["message"]}
                            </div>
                            : null}
                        </div>

                        {!(userAssessmentExpertOrderPlaceData && userAssessmentExpertOrderPlaceData.preOrderId) ?
                          <button onClick={() => bookExpert(crrCodeUserDetails, contactExpertSection)} type="button" className="w-full  py-4 px-4 text-base font-medium tracking-wide text-white uppercase rounded bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150">Book Approintment </button>

                          :
                          (this.state.isTest ?
                            <button
                              type="submit"
                              onClick={() => handleTestPayment(userAssessmentExpertOrderPlaceData && userAssessmentExpertOrderPlaceData.preOrderCode ? userAssessmentExpertOrderPlaceData.preOrderCode : 0)}
                              className="lg:relative fixed bottom-0 left-0   bg-[#4CAF50] text-[#fff] hover:bg-[#4CAF50]/90 w-full justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center font-normal  cursor-pointer text-lg"
                            >
                              <span>Continue To Pay</span>
                            </button>
                            :
                            <form method={"POST"} name={"customerData"} action={"/ccavRequestHandler"}>
                              <table width="40%" align="center" className="hidden">
                                <tbody>
                                  <tr>
                                    <td>Merchant Id</td>
                                    <td>
                                      <input className="border" type="text" name="merchant_id" id="merchant_id" value={CONST.MERCHANT_ID} />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Order Id</td>
                                    <td>
                                      <input className="border" type="text" name="order_id"
                                        value={userAssessmentExpertOrderPlaceData && userAssessmentExpertOrderPlaceData.orderNumber ? userAssessmentExpertOrderPlaceData.orderNumber : 0} />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Currency</td>
                                    <td>
                                      <input className="border" type="text" name="currency" value={"INR"} />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Amount</td>
                                    <td>
                                      <input className="border" type="text" name="amount" value={userAssessmentExpertOrderPlaceData && userAssessmentExpertOrderPlaceData.price ? userAssessmentExpertOrderPlaceData.price : 0} />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Redirect URL</td>
                                    <td>
                                      <input className="border" type="text" name="redirect_url"
                                        value={CONST.BACKEND_HOST + '/#/assessment/expert-booked/' + (userAssessmentExpertOrderPlaceData && userAssessmentExpertOrderPlaceData.preOrderCode ? userAssessmentExpertOrderPlaceData.preOrderCode : 0)} />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Cancel URL</td>
                                    <td>
                                      <input className="border" type="text" name="cancel_url"
                                        value={CONST.BACKEND_HOST + '/#/assessment/order-cancelled'} />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Language</td>
                                    <td>
                                      <input className="border" type="text" name="language" id="language" value={"EN"} />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Customer Id:</td>
                                    <td>
                                      <input className="border" type="text" name="customer_identifier" value={crrCodeUserDetails && crrCodeUserDetails.employeeId ? crrCodeUserDetails.employeeId : ""} />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>


                              <button
                                type="submit"
                                className="lg:relative fixed bottom-0 left-0   bg-[#4CAF50] text-[#fff] hover:bg-[#4CAF50]/90 w-full justify-center duration-150 transition-all py-3 px-8 md:rounded uppercase space-x-2 flex items-center font-normal  cursor-pointer text-lg"
                              >
                                <span>Continue To Pay</span>
                              </button>

                            </form>)
                        }


                      </div>
                    </div>
                  </div>
                </section>

              </section> : null
          }

          {/* Rating Model */}
          {crrExpertId && (
            <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full py-10 bg-black bg-opacity-50">
              <div className="w-full max-w-xl max-h-full overflow-y-auto bg-white sm:rounded-2xl">
                <div className="w-full">
                  <div className='flex items-end justify-end mt-5 mr-5'>
                    <MdClose className='w-8 h-8 text-[#3D405B] cursor-pointer bg-gray-50 hover:bg-blue-200 rounded-full p-1' onClick={this.handleCloseModalRating} />
                  </div>
                  <div className="m-4 max-w-[400px] mx-auto text-center">
                    <div className="space-y-4">
                      <h1 className="text-3xl font-bold text-[#3D405B]">What is your rating?</h1>
                      <p className="text-gray-600">Give rating to this expert based on your experience and interaction.</p>
                      <div className="flex items-center justify-center">
                        <p className="text-[#3D405B] tracking-wide text-normal">Rating: </p>

                        {[1, 2, 3, 4, 5].map((star, index) =>
                          (index < this.state.rating) ?
                            <svg onClick={() => this.handleReviewInput(star)} className="w-10 h-10 text-yellow-400 cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                              </path>
                            </svg>
                            :
                            <svg onClick={() => this.handleReviewInput(star)} className="w-10 h-10 text-gray-300 cursor-pointer dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                              </path>
                            </svg>
                        )}

                      </div>
                    </div>
                    <div className="pb-8 mt-6">
                      <button onClick={() => this.handleReviewSubmit()} type='submit' className="lg:w-44 w-full text-lg font-medium rounded-md inline-flex items-center justify-center px-2 py-3 cursor-pointer bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden capitalize">submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isOpenTop5Model && (
            <div className="fixed top-0 left-0 z-20 flex items-center justify-center w-full h-full py-10 bg-black bg-opacity-50">
              <div className="w-full max-w-lg max-h-full overflow-y-auto bg-white sm:rounded-2xl">
                <div className="w-full">
                  <div className='flex items-end justify-end mt-5 mr-5'>
                    <MdClose className='w-8 h-8 text-[#3D405B] cursor-pointer bg-gray-50 hover:bg-blue-200 rounded-full p-1' onClick={this.handleCloseTop5Modal} />
                  </div>
                  <div className="m-4 mb-10 max-w-[400px] mx-auto">
                    {/* <div className="mb-8"> */}
                    <p className="text-gray-600">{this.state.rowModalData ? this.state.rowModalData : "NA"}</p>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>

    );
  }
}


function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, assessment } = state;
  return {
    loggingIn,
    users,
    dashboard,
    assessment
  };
}
export default connect(mapStateToProps)(BackSummary);