import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions"; 
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import AOS from "aos";
import { Helmet } from "react-helmet";
import Ready from "../../../components/Ready";
class DomainTheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
    };
    this.section1Ref = React.createRef();
    this.section2Ref = React.createRef();
    this.section3Ref = React.createRef();
    this.section4Ref = React.createRef();
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 1,
      currentImage: null,

      isScrolled: false,
      showButton1: false,
      showButton2: false,
      showButton3: false,
      showButton4: false,
    };
  }
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = (tabIndex) => {
    this.setState({ activeTab: tabIndex });

    const section1Offset = this.section1Ref.current.offsetTop;
    const section2Offset = this.section2Ref.current.offsetTop;
    const section3Offset = this.section3Ref.current.offsetTop;
    const section4Offset = this.section3Ref.current.offsetTop;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop >= section1Offset) {
      this.setState({
        isScrolled: true,
        showButton1: true,
      });
    } else {
      this.setState({
        isScrolled: false,
        showButton1: false,
      });
    }
    if (scrollTop >= section2Offset) {
      this.setState({
        showButton2: true,
      });
    } else {
      this.setState({
        showButton2: false,
      });
    }
    if (scrollTop >= section3Offset) {
      this.setState({
        showButton3: true,
      });
    } else {
      this.setState({
        showButton3: false,
      });
    }
    if (scrollTop >= section4Offset) {
      this.setState({
        showButton4: true,
      });
    } else {
      this.setState({
        showButton4: false,
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentSignInSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleImageClick = (event) => {
    const imageSrc = event.target.src;
    this.setState({ currentImage: imageSrc });
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };


  handleRedirect = () => {
    this.props.history.push('/assessment/products');
  }

  render() {
    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    let { quantity } = this.state;
    console.log("quantity-->", quantity);

    return (
      <div className="overflow-x-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Talent strength empowerment through diversity of four domains and 22 talent themes</title>
          <meta name="description" content="Discovering Your Strengths by  Exploring the Four Domains and 22  Talent Themes" />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/the-4-domains-and-22-themes" />
        </Helmet>
        <div className="">


          <section
            className="bg-[#2196F3]/10 w-full h-auto  bg-cover bg-no-repeat bg-right "
            style={{
              backgroundImage: `url('/img/assessment/bg-gradient.webp')`,
            }}
          >
            <HeaderTransprent
              handleSignInPage={this.handleSignInPage}
              userDetails={userAssessmentUserProfileData}
              logout={this.logout}
              gotoPage={this.gotoPage}
              cartCount={
                getAssessmentCartCountData ? getAssessmentCartCountData : 0
              }
              props2={this.props}
              prodList={getAssessmentPackagesShortListData}
              gotoMyAccount={this.gotoMyAccount}
            />
            {/* Hero Section */}
            <div className="w-full h-full">
              <div className="flex flex-col w-full h-full gap-10  md:pt-10  md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 px-4 mx-auto md:bg-gradient-to-r bg-gradient-to-b lg:from-transparent  lg:to-transparent  from-[#E9F5FE]/90 to-[#E9F5FE]/90 ">
                <div className="flex flex-col justify-center w-full h-full pt-10 mx-auto my-auto space-y-8 text-center md:pb-10 md:pt-0 md:text-left ">
                  {/* <p data-aos="fade-right" className="text-base text-[#2196F3] font-medium uppercase tracking-widest ">Employer Solutions</p> */}
                  <h1 data-aos="fade-right" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]  capitalize">Discover and harness the power of your unique TalentPlusStrengths in everyday life. </h1>
                  <div data-aos="fade-right" className="text-base font-light md:text-lg">
                    <p className="text-[#212121]"> Explore the four domains and 22 diverse themes that shape your individual strengths profile.</p>
                    <p className="text-[#212121]">   Empower yourself to excel in team dynamics, creative endeavors, and leadership roles using this user-friendly framework.</p>
                  </div>
                  <div className="flex flex-row items-center justify-center gap-4 md:justify-start md:items-center">
                    <button data-aos="fade-right" onClick={() => this.gotoPage("products")} className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black  transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center">   BUY TALENTPLUSSTRENGTHS</button>
                  </div>
                </div>

                <div className="flex items-end justify-center w-full lg:justify-end">
                  <img data-aos="fade-left" src="/img/assessment/the-4-domains-and-22-themes.webp" alt="impact-coach" className="z-0 w-full" />
                </div>
              </div>
            </div>




          </section>

          <section className="w-full px-4 mx-auto bg-white bg-center bg-no-repeat bg-cover 2xl:w-9/12 xl:w-11/12 xl:px-0 xl:bg-contain">
            <div className="pt-20 mx-auto space-y-6 text-center ">
              <h1
                data-aos="fade-up"
                className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]"
              >4 Domains and 22 Themes of TalentPlusStrengths?</h1>
              <p data-aos="fade-up" className="md:text-lg text-base text-[#212121]">
                Your customized TalentPlusStrengths reports will help you to
                experience new ways to understand what makes you so unique.
              </p>
            </div>
          </section>

          <section className="flex flex-col items-center justify-center w-full gap-10 px-4 pt-16 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div
              data-aos="fade-up"
              className="flex flex-col items-center justify-start w-full gap-2 overflow-x-auto border-collapse md:flex-row md:justify-center whitespace-nowrap "
            >
              {!this.state.showButton1 && (
                <button
                  onClick={() =>
                    window.scrollTo({
                      top: this.section1Ref.current.offsetTop,
                      behavior: "smooth",
                    })
                  }
                  className={
                    !this.state.showButton1
                      ? "border-[#673AB7] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                      : "border-[#673AB7] border border-b-4  text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-[#ECE7F6] transition-all duration-150 md:w-56 w-full uppercase"
                  }
                >
                  Leading Talents
                </button>
              )}

              {!this.state.showButton2 && (
                <span
                  onClick={() =>
                    window.scrollTo({
                      top: this.section2Ref.current.offsetTop,
                      behavior: "smooth",
                    })
                  }
                  className="border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-gray-100 transition-all duration-150 md:w-56 w-full uppercase"
                >
                  thinking Talents
                </span>
              )}

              {!this.state.showButton3 && (
                <span
                  onClick={() =>
                    window.scrollTo({
                      top: this.section3Ref.current.offsetTop,
                      behavior: "smooth",
                    })
                  }
                  className="border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-gray-100 transition-all duration-150 md:w-56 w-full uppercase"
                >
                  Relating Talents
                </span>
              )}

              {!this.state.showButton4 && (
                <span
                  onClick={() =>
                    window.scrollTo({
                      top: this.section4Ref.current.offsetTop,
                      behavior: "smooth",
                    })
                  }
                  className="border text-[#212121] text-center py-4 px-4 cursor-pointer hover:bg-gray-100 transition-all duration-150 md:w-56 w-full uppercase"
                >
                  Action Talents
                </span>
              )}
            </div>
          </section>

          {/* Leading Talents Start 1*/}
          <section ref={this.section1Ref}>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  What Is the Leading Domain of TalentPlusStrengths?
                </h1>

                <p className="text-lg text-[#212121]">These are the talents, which defines our leadership. Leadership is not a position but it is a mindset,
                  through which people share their vision with others, get them aligned and motivated and ensure work
                  takes place towards that desired future. Leaders are the people responsible for a better  tomorrow, so
                  they not only have to keep looking at the things to come but also make that dream a reality with the
                  help of others.</p>
                <p className="text-lg text-[#212121]">Leaders are the people responsible for a better tomorrow, so
                  they not only have to keep looking at the things to come but also make that dream a reality with the
                  help of others</p>
              </div>
              <div className="w-full">
                <img
                  data-aos="fade-left"
                  alt="talentplusleadingdomain"
                  width={790}
                  height={790}
                  src="/img/Home/talentplusleadingdomain.webp"
                />
              </div>
            </div>
          </section>

          <section className="items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div className="flex items-center justify-between w-full bg-white rounded-xl">
              <div className="w-full space-y-4">
                <div className="pb-5">
                  <h2
                    data-aos="fade-up"
                    className=" text-4xl leading-[3rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#673AB7] pl-6 capitalize"
                  >
                    Learn More About Each Leading TalentPlusStrengths Theme:
                  </h2>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Confidence
                  </h1>
                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You are someone who knows where your life is going. You
                      are in control of things happening in your life. You feel
                      you have a compass which guides you in which direction you must go. People may suggest but it's you who decides
                      your next step.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="confident" src="/img/domaim/confident.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Enhancer
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      People with this theme would love to work on something
                      already good and try to make it the best. Not only with
                      people but with processes, products or anything which
                      comes in front of them. They believe in enhancing things
                      with their effort, talent, and determination.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="enhancer" src="/img/domaim/enhancer.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>

                <div data-aos="fade-up" className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Generalship
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You are a great problem solver, so much so that you do not
                      consider any problem as a problem. Not only will you solve
                      a problem but you can do it in multiple ways. This immense
                      ability can make others wonder, when they are still
                      thinking of any issue, you have already solved it. How? No
                      one knows it.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="ideation" src="/img/domaim/ideation.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Starter
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      When can we move is the question you constantly ask
                      yourself and others. Driven by this talent there is never
                      a dull moment in your life. In fact, you are always onto
                      something because you believe at the end of the day, it is
                      the action which trumps all the words.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="starter" src="/img/domaim/starter.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Speaker
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You love speaking. You love connecting with people. Words
                      are the glue which allows people to get attracted to you.
                      You love stories, anecdotes, and examples to make a point
                      and people are always in awe when you speak. You can
                      inspire people with your words
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="speaker" src="/img/domaim/speaker.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#673AB7]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Winner
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You play to win. You want to be numero uno. You love to
                      compete. You love to compare. Wherever you are, you will
                      ensure that your team is driven to aspire big. You
                      will love to bring your best and get others to do
                      their best, because you know to win, you need to be the
                      best
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="winner" src="/img/domaim/winner.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Leading Talents End */}

          {/* Thinking Talents Start 2*/}
          <section ref={this.section2Ref}>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div className="w-full">
                <img
                  alt="talentplusthinkingdomain"
                  data-aos="fade-right"
                  src="/img/Home/talentplusthinkingdomain.webp"
                  width={790}
                  height={790}
                />
              </div>
              <div data-aos="fade-left" className="w-full space-y-8">
                <h1 className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]">
                  What Is the Thinking Domain of TalentPlusStrengths?
                </h1>
                <p className="text-[#212121]">These are the talents which define your way of thinking and making sense of the world. Using these
                  talents, you deliberate on ideas, possibilities, and the next course of action. This theme deals with
                  intellectual abilities which is the hallmark of human progress. </p>
                <p className="text-[#212121]">Thinking about themes allows us to
                  explore our past and gaze into the future so that we can plan things in the present. Thinking talents
                  are the strategic pathway to our future.</p>
              </div>
            </div>
          </section>

          <section className="items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div className="flex items-center justify-between w-full bg-white rounded-xl">
              <div className="w-full space-y-4">
                <div className="pb-5">
                  <h2
                    data-aos="fade-up"
                    className=" text-4xl leading-[3rem] xl:leading-[4rem] font-medium text-[#212121] border-l-[0.4rem] border-[#FFC107] pl-6 capitalize"
                  >
                    Learn More About Each Thinking TalentPlusStrengths Theme:
                  </h2>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#FFC107]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Logical
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You love numbers. You love data. You love patterns. You
                      love solving problems. But you know before you solve a
                      problem you need to go to the root cause of the problem
                      and that is something which provides you with a talent to
                      ask probing questions.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="logical" src="/img/domaim/logical.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#FFC107]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Philosopher
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You have a mind which loves thinking. Frankly you can
                      think of anything. The sheer thrill of picking up a topic
                      and giving your own spin will excite you. You may speak
                      less but when you speak people will listen because they
                      know, unless you have anything valuable, you will not
                      speak.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="philosopher" src="/img/domaim/philosopher.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" className="items-center bg-[#FFC107]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#212121]">
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%] capitalize">solver</h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121]">People with this theme have a superior ability to solve any problem in life effortlessly, that too in multiple ways. Sovlers live their life with confidence and also brings calmness in difficult situations</p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="ideation" src="/img/domaim/solver.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#FFC107]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Disruptor
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      Ideas are fascinating. In fact, it is the ideas which
                      make  the world tick. Everything which is available in
                      front of us is someone's idea. Driven by these thoughts,
                      you always want to do things in different ways and look at
                      solving problems in a way in which it was never done.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="strategic" src="/img/domaim/strategic.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#FFC107]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Quencher
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      The power of curiosity drives you. You believe, life is
                      about learning and knowing new things. New ideas and
                      concepts excite you. Not only learning new things but
                      sharing the same with others is a great experience and you
                      do it abundantly.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="strategic" src="/img/domaim/quencher.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Thinking Talents End */}

          {/* Relating Talents Start 3*/}
          <section ref={this.section3Ref}>
            <div className="flex flex-col items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
              <div data-aos="fade-right" className="w-full space-y-8">
                <h1
                  data-aos="fade-up"
                  className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]"
                >
                  What Is the Relating Domain of TalentPlusStrengths?
                </h1>
                <p className="text-[#3D405B]">Human beings are social animals. We thrive with others. We need others in life to share our successes,
                  miseries, and our condition. The ability to connect with others is what defines the possibilities of our
                  success in life. </p>
                <p className="text-[#3D405B]">It is the bond between one person and another, which takes us higher and ensures we
                  come together to achieve the goals we set for ourselves, challenges we want to overcome and write
                  history together.</p>
              </div>
              <div className="w-full">
                <img
                  alt="talentplusrelatingdomain"
                  data-aos="fade-left"
                  src="/img/Home/talentplusrelatingdomain.webp"
                  width={790}
                  height={790}
                />
              </div>
            </div>
          </section>

          <section className="items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div className="flex items-center justify-between w-full bg-white rounded-xl">
              <div className="w-full space-y-4">
                <div className="pb-5">
                  <h2
                    data-aos="fade-up"
                    className=" text-4xl leading-[3rem] xl:leading-[4rem] font-semibold text-[#212121] border-l-[0.4rem] border-[#4CAF50] pl-6"
                  >
                    Learn More About Each Relating TalentPlusStrengths Theme:
                  </h2>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#4CAF50]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Binder
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      People like you because you are like a glue which binds
                      the relationship among them. Driven by the talent of
                      binder, you connect at a deep level with very few people
                      and these are the ones  who know that you are such a gem
                      of a person who is ready to do anything for them.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="binder" src="/img/domaim/binder.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#4CAF50]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Equaliser
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      People with this talent like to see areas of synergies in
                      every situation. They would love to know where we all
                      agree so that we can build a relationship  which allows us to
                      take on the differences. People like you because you would
                      love to solve things rather than confront them.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="empathiser" src="/img/domaim/empathiser.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#4CAF50]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Coach
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base ">
                      You are unique in the sense that you see potential in
                      everyone. You know, everyone can achieve greatness, only
                      if they put in the effort and of course with your support. You
                      want your pupils to go ahead of you and in that progress,
                      you see your own progress
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="coach" src="/img/domaim/coach.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>



                <div
                  data-aos="fade-up"
                  className="items-center bg-[#4CAF50]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Kindness
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You can sense the feeling; you can sense the emotions.
                      In fact, you can put yourself in the shoes of someone else
                      and feel the same way the other person is feeling. This
                      power of yours allows you to anticipate and operate with
                      people the way they want it to be.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="harmonise" src="/img/domaim/harmonise.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#4CAF50]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Optimistic
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      For you life is always beautiful. In good times and in bad
                      times, you never lose hope. You know things will be
                      all right. You have immense gratitude for whatever you have
                      and a mind which always tells you, nothing is impossible.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="positive" src="/img/domaim/positive.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Relating Talents End */}

          {/* Action Talents Start 4*/}

          <section ref={this.section4Ref} className="px-6 space-y-16 md:px-0">
            <div className="flex flex-col items-center w-full gap-10 py-16 mx-auto 2xl:w-9/12 xl:w-11/12 md:flex-row ">
              <div className="w-full">
                <img
                  alt="talentplusactingdomain"
                  data-aos="fade-right"
                  src="/img/Home/talentplusactingdomain.webp"
                  width={790}
                  height={790}
                />
              </div>
              <div data-aos="fade-left" className="w-full space-y-8">
                <h1
                  data-aos="fade-up"
                  className="2xl:text-5xl md:text-4xl text-3xl xl:leading-[3rem] 2xl:leading-[4rem]  capitalize font-bold text-[#212121]"
                >
                  What Is the Acting Domain of TalentPlusStrengths?
                </h1>
                <p className="md:text-lg text-[#3D405B]">These are action talents, which allow you to make things happen. Ideas are good but the real
                  progress takes place when we put these ideas to action. The acting talent allows us to put our
                  thoughts into action and see the magic happen.</p>
                <p className="md:text-lg text-[#3D405B]">It is these themes which make things happen and
                  lets everyone see the progress happening. Our acting talents ensure that we make progress and
                  finish tasks and achieve things consistently.</p>
              </div>
            </div>
          </section>

          <section className="items-center w-full gap-10 px-4 py-10 mx-auto md:py-16 2xl:w-9/12 xl:w-11/12 xl:px-0 md:flex-row ">
            <div className="flex items-center justify-between w-full bg-white rounded-xl">
              <div className="w-full space-y-4">
                <div className="pb-5">
                  <h2
                    data-aos="fade-up"
                    className=" text-4xl leading-[3rem] xl:leading-[4rem] font-semibold text-[#212121] border-l-[0.4rem] border-[#2196F3] pl-6 capitalize"
                  >
                    Learn More About Each Acting TalentPlusStrengths Theme:
                  </h2>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#2196F3]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Finisher
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You are someone who has a humongous capacity to work. People can't match your energy and enthusiasm. Once you start something, you would like to finish. You take pride in what you do and often you achieve quite a lot in your day.

                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="finisher" src="/img/domaim/finisher.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#2196F3]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Concentrator
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      Like a Zen master, once you have decided to do something,
                      you will not buzz from your path. Your mind is as sharp as
                      a razor and allows you to accomplish things, once you have
                      set sight on it. It is like a horse with a blinder, moving
                      forward till it reaches its destination.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="focus" src="/img/domaim/focus.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#2196F3]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Multitask
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      Multitaskers are like jugglers managing multiple balls in one go. In fact, the more balls in the hand and air, the more excitement they will experience. At one go they can be focused on many things and will finish everything with perfection.

                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="multitask" src="/img/domaim/multitask.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#2196F3]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Owner
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You take psychological ownership of everything you do. You take extreme care in delivering anything you have committed and expect the same from others. No one can match you for the perfection you deliver, because you yourself can criticise yourself before anyone else can.

                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="owner" src="/img/domaim/owner.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#2196F3]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Planner
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      You need structure in life. You can build sanity in chaos.
                      Driven by your talent of putting things in their place, you
                      are known for your no-nonsense attitude and get things
                      done, the way they should be done, that too in a perfect
                      manner.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="planner" src="/img/domaim/planner.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="items-center bg-[#2196F3]/10 justify-between p-4 space-y-4 rounded-2xl 2xl:px-8 md:py-4 md:flex md:space-x-6 md:space-y-0 text-[#3D405B]"
                >
                  <h1 className="text-xl font-medium 2xl:text-2xl  w-[20%]  ">
                    Value
                  </h1>

                  <div className="md:w-[80%] flex  space-x-6 md:items-center items-end ">
                    <p className="flex-1 text-sm font-normal md:text-base text-[#212121] ">
                      People with deep values lead their life with certain
                      non-negotiable principles. Whatever happens in life those
                      core principles will not change. In fact, that is the map
                      which guides their journey called life. They would be
                      known for the character and unmistakable morality.
                    </p>
                    <div className="w-16 xl:w-28 md:w-20">
                      <img alt="value" src="/img/domaim/value.svg" width={96} height={96} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Action Talents End */}

          <Ready />

          <Footer
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />
        </div>
      </div>
    );
  }
}
DomainTheme.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(DomainTheme);
